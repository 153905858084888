import React, { useState} from 'react'
import { connectField } from 'uniforms'
import  MuiPhoneNumber from '@cephalomax/material-ui-phone-number' 

const CustomPhoneField = ({
  className,
  onChange, 
  value, 
  name, 
  label, 
  errorMessage, 
  variant, 
  margin, 
  fullWidth, 
  disabled,
  id,
  showInlineError,
  required,
  initialValue = "",
  defaultCountry = "us",
  disableDropdown = true,
  disableAreaCodes = true,
  ...other
}) => {
  
  //const { t } = useTranslation();

  const [internalValue, setInternalValue] = useState("")

  return (
    <MuiPhoneNumber
      id={id}
      name={name}
      className={className}

      value={value || initialValue}  
      onChange={(e) => {
        onChange(e)
      }}
      label={label}
      helperText={showInlineError? errorMessage: ''}
      error={Boolean(errorMessage)}
      variant={variant}
      margin={margin}
      fullWidth={fullWidth}
      disabled={disabled}
      required={required}
      
      defaultCountry={defaultCountry}
      disableDropdown={disableDropdown}
    />
  );

}

export default connectField(CustomPhoneField)