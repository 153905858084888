import React from 'react';

import { AutoField } from 'uniforms-material'

import { alertRoutine } from '../../../redux/Alerts/routines'

import { store, i18n } from '../../../index'

import { capitalizeName } from '../../../utils/string-helpers.js' 

import { patientFormActionCreator } from './patientFormActionCreator'

import { putPatientRoutinePromiseCreator } from '../../../redux/Patients/routines'

import { history } from '../../../utils/configureStore'

import schema from './patientLinkFormSchema'

import CustomPatientSelectorField from '../../uniforms/CustomPatientLazySelectorField'

const errorActionCreator = () => (
  alertRoutine.show({
    type: 'snack',
    title: i18n.t('common:interface.an error occured'),
    severity: 'error'
  })
)

const LinkPatientForm = ({patient}) => {
  return (
    <div>
      <AutoField name="patient" initialSearch={`${patient.lastname} ${patient.firstname}`} component={CustomPatientSelectorField}/>
    </div>
  )
}

export const patientLinkFormActionCreator = (service, externalId, patient) => {

  if(patient) {
    patient.firstname = capitalizeName(patient.firstname)
    patient.lastname = patient.lastname.toUpperCase()
  }

  return (
    alertRoutine.show({
      id:'patientLinkForm', 
      type: 'form',
      maxWidth: 'xs',
      schema,
      title: i18n.t('form:patient.link.title'),
      content: <LinkPatientForm patient={patient} />,
      actionTitle: i18n.t('form:patient.link.link'),
      secondaryActions: [
        {
          title: i18n.t('form:patient.link.add'),
          action: async (parentAlertId) => {
            //display patientFormActionCreator
            console.log('secondaryActions', patient)
            store.dispatch(patientFormActionCreator(patient, parentAlertId))
          }
        }
      ],
      action: async (values, progress) => {      
        console.log(values)
        try {
          progress.handleTrigger()
          
          const  { result }  = await putPatientRoutinePromiseCreator({
            values: {
              external_ids: [
                {service, id: externalId},
                ...(values.patient.external_ids || []).filter(link => link.service !== service)
              ]
            }, 
            id: values.patient.id
          }, store.dispatch); 
          
          history.push(`/patient/${result}`)
          
          return true
        }
        catch (e) {
          //afficher l'erreur
          store.dispatch(errorActionCreator())
          return false
        }
        finally {
          progress.handleFulfill()
        }
     
      }
    })
  )
}