import React from 'react';
import AutocompleteMui, { createFilterOptions as createFilterOptionsMui} from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField'

import { connectField, filterDOMProps } from 'uniforms';

const Autocomplete = ({
  // disabled,
  error,
  errorMessage,
  helperText,
  // inputRef,
  // ref,
  label,
  // name,
  // onChange,
  placeholder,
  showInlineError,
  // type,
  value,
  // loading,
  // freeSolo,
  options,
  // getOptionLabel,
  // disableListWrap,
  // includeInputInList,
  // disableClearable,
  // clearOnEscape,
  // disableCloseOnSelect,
  autoComplete,
  // debug,
  // openOnFocus,
  // autoHighlight,
  // autoSelect,
  // blurOnSelect,
  // disablePortal,
  // renderOption,
  // style,
  onSelectOption,
  inputProps,
  InputProps,
  fullWidth,
  autoFocus,
  onFocus,
  variant,
  margin,
  disabled,
  getOptionLabel,
  onChange,
  multiple,
  uniqueItems,
  maxItems,
  minItems,
  ...props
}) => {
  //console.log('autoformField', props)
  const [inputValue, setInputValue] = React.useState(multiple? "" : value)
  getOptionLabel = getOptionLabel || ((x) => x)

  return(
  <AutocompleteMui
    multiple={multiple}
    options={options}
    disabled={!!disabled}
    getOptionLabel={getOptionLabel}
    autoComplete={autoComplete}
    /*style={style}
    disabled={!!disabled} 
    onChange={(event, value) => disabled || onChange(value)}
    freeSolo={freeSolo}
    ref={ref}
    loading={loading}
    getOptionLabel={getOptionLabel}
    disableListWrap={disableListWrap}
    includeInputInList={includeInputInList}
    disableClearable={disableClearable}
    clearOnEscape={clearOnEscape}
    debug={debug}
    disableCloseOnSelect={disableCloseOnSelect}
    autoHighlight={autoHighlight}
    openOnFocus={openOnFocus}
    autoSelect={autoSelect}
    blurOnSelect={blurOnSelect}
    disablePortal={disablePortal}
    renderOption={renderOption}*/
    //onFocus={() => inputRef && inputRef.current && inputRef.current.focus()}
    onChange={(event, value, reason) => {
      console.log('onChange', event, value, reason)
      if(typeof onSelectOption === 'function')
        onSelectOption(event, value, reason)
      return disabled || onChange(getOptionLabel(value))
    }}
    onInputChange={(event, value, reason) => {
      setInputValue(value)

      //onChange(getOptionLabel(value))
      //console.log('onInputChange', event, value, reason)
      //return disabled || onChange(getOptionLabel(value))
    }}
    value={value}
    inputValue={inputValue}
    renderInput={(params) => {
      return (
      <TextField
        {...params}
        InputProps={{
          ...params.InputProps,
          ...InputProps,
          endAdornment: (
            <React.Fragment>
              {InputProps && InputProps.endAdornment}
              {params.InputProps.endAdornment}
            </React.Fragment>
          ),
        }}
        inputProps={{
          ...params.inputProps,
          ...inputProps,
          className: `${inputProps && inputProps.className} ${params.inputProps && params.inputProps.className}`,
        }}
        fullWidth={fullWidth}
        autoFocus={autoFocus}
        onFocus={onFocus}
        variant={variant}
        margin={margin}
        onBlur={() => disabled || multiple || onChange(getOptionLabel(inputValue))}
        label={label}
        error={!!error}
        helperText={(error && showInlineError && errorMessage) || helperText}
        placeholder={placeholder}
        // type={type}
        // autoComplete={autoComplete}
      />
    )
    }}
    {...filterDOMProps(props)}
  />
)};

Autocomplete.defaultProps = {
  fullWidth: true,
  margin: 'dense',
  type: 'text',
};

export default connectField(Autocomplete);

export const createFilterOptions = createFilterOptionsMui
