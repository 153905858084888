import { createRoutineCreator } from 'redux-saga-routines';

const createDiagnosticRoutine = createRoutineCreator([
  'SAVE',
  'INIT',
  'LOAD',
  'SET',
  'AUTOSAVE_START',
  'AUTOSAVE_END',
])

export const diagnosticRoutine = createDiagnosticRoutine('DIAGNOSTIC')