import MuiTextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

export const TextField = withStyles((theme) => ({
  root: {
    "& .MuiSelect-root, & .MuiInputBase-root input": {
      paddingTop: 8.5,
      paddingBottom: 8.5,
    },

    "& .MuiSelect-root": {
      display: 'flex',
      alignItems: 'center',
      '& .MuiListItemText-root, & > span' : {
        margin: 0,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }
    },

    "&.MuiTextField-root label": {
      top: -2,
      '&[data-shrink="false"]' : {
        textOverflow: 'ellipsis',
        right: 36,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      }
    },
    
    "&.MuiTextField-root label[data-shrink='true']": {
      top: 0
    },
    
  }
}))(MuiTextField);

export default TextField;