import { takeEvery, takeLatest, put, call, all } from 'redux-saga/effects'
import { replace, push } from 'connected-react-router'
import jwtDecode from 'jwt-decode'
import { normalize } from 'normalizr';

import { REHYDRATE } from 'redux-persist'
import { LOCATION_CHANGE } from 'connected-react-router'

import { loginRoutine, logoutRoutine } from './routines'
import { getUserRoutine } from '../Users/routines'

import { user } from '../schemas'
import { auth } from '../../utils/api'

import { insufficientPermissionsAlertActionCreator } from '../../components/Alerts/insufficientPermissionsAlertActionCreator';
import { storageQuotaExceededAlertActionCreator } from '../../components/Alerts/storageQuotaExceededAlertActionCreator';
import { forbiddenIpAlertActionCreator } from '../../components/Alerts/forbiddenIpAlertActionCreator';

const delay = (ms) => new Promise(res => setTimeout(res, ms))

function* loginSaga(action) {
  if(action.payload.location.pathname !== '/callback')
    return;

  yield put(loginRoutine.trigger());
  
  yield put(loginRoutine.request());

  let redirect;
  try {
    const { data } = yield call(auth.login, action.payload.location.search);
    
    yield put(loginRoutine.success({token: data.jwt, userId: data.user.id}));

    if(data.user.redirectTo)
      redirect = data.user.redirectTo

    const normalizedData = yield call(normalize, data.user, user); 

    yield put(getUserRoutine.success(normalizedData))
    yield put(getUserRoutine.fulfill())

    
  }
  catch(error) {
    console.log(error)
    yield put(loginRoutine.failure(error));
  } 

  yield put(loginRoutine.fulfill());
 
  yield put(replace('/'))
  if(redirect)
    yield put(push(redirect.replace('\\', '/'), 'redirect_after_login')) 
}

function* watchLogin() {
  yield takeEvery(LOCATION_CHANGE, loginSaga)
}

function* logoutSaga(action) {
  if(action.payload.location.pathname !== '/logout')
    return;

  yield put(logoutRoutine.trigger());
  
  yield put(logoutRoutine.success());

  yield put(logoutRoutine.fulfill());

  yield put(replace('/'))

}

function* watchLogout() {
  yield takeEvery(LOCATION_CHANGE, logoutSaga)
}

function* tokenExpiration(action) {
  
  if(action.type === REHYDRATE && action.key !== 'auth')
    return;
  if(action.type === REHYDRATE && !action.payload)
    return;
  if(action.type === REHYDRATE && !action.payload.token)
    return;

  const decoded = jwtDecode(action.payload.token)

  const delayMs = decoded.exp*1000 - Date.now();

  yield call(delay, delayMs > 2147483647 ? 2147483647 : delayMs)
  
  console.log('token Expire')
  yield put(push('/logout'))

}

function* watchTokenExpiration() {
  yield takeLatest([loginRoutine.SUCCESS], tokenExpiration);
}

function* watchRehydrateExpiration() {
  yield takeEvery([REHYDRATE], tokenExpiration);
}

function* googleRefreshTokenExpiration(action) {
  //console.log(action.error, action.payload?.response?.data,  action.payload?.response?.status)
  //if(action.error && action.payload.message === "Request failed with status code 401")
  if(action.error)
    console.log(action.payload?.response?.status, action.payload?.response?.status === 403, action.payload?.response?.data)
  if(action.error && action.payload?.response?.status === 401)
    yield put(push('/logout'))
  if(action.error && action.payload?.response?.status === 403 && action.payload?.response?.data?.message === 'insufficientPermissions')
    yield put(insufficientPermissionsAlertActionCreator())
  if(action.error && action.payload?.response?.status === 403 && action.payload?.response?.data?.message === 'storageQuotaExceeded')
    yield put(storageQuotaExceededAlertActionCreator())
}

function* watchGoogleRefreshTokenExpiration() {
  yield takeEvery('*', googleRefreshTokenExpiration);
}

function* handleIpError(action) {
  if(action.error && action.payload?.response?.status === 403 && action.payload?.response?.data?.message === 'IP address not allowed'){
    const alert = yield forbiddenIpAlertActionCreator()
    yield put(alert)
  }
    
}

function* watchHandleIpError() {
  yield takeEvery('*', handleIpError);
}

export default function* watchAuth() {
  yield all([
    watchLogin(),
    watchLogout(),
    watchTokenExpiration(),
    watchRehydrateExpiration(),
    watchGoogleRefreshTokenExpiration(),
    watchHandleIpError(),
  ])
} 
