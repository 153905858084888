import { createSelector } from 'reselect'
import { childDocumentsByFolderSeletor } from '../Documents/selectors'
import  {isEqualWith, pickBy, reduce, find} from 'lodash'


export const filesSelector = state => state.entities.files

export const makeFilesSelector = () => createSelector(
  filesSelector,
  (_, props) => props,
  (files = {}, selectedFiles = []) => {

    return selectedFiles.map(file => file.id).reduce((acc, id) => ({...acc, [id] : files[id]}), {})
    //console.log('FilesSelector', files, selectedFiles)
    //const mappedSelectedFiles = selectedFiles.map(file => file.id)
    //return pickBy(files, (patient, key) => mappedSelectedFiles.includes(key))

    //return _.pickBy(files, (patient, key) => mappedSelectedFiles.includes(key))
  }
)

export const makeFileSelector = () => createSelector(
  filesSelector,
  (_, props) => props,
  (files = {}, fileId) => {
    //console.log('makeFileSelector')
    return files[fileId]
  }
)

export const candidatesFilesSelector = state => state.importsFiles.candidateFiles

export const makeCandidatesFilesSelector = () => createSelector(
  candidatesFilesSelector,
  (_, props) => props,
  (candidateFiles = {}, folderId) => {
    return candidateFiles[folderId]
  }
)

export const makeSelectedCandidatesFilesSelector = () => createSelector(
  candidatesFilesSelector,
  (_, props) => props,
  (candidateFiles = {}, folderId) => {
    const selected = (candidateFiles[folderId] || []).filter(candidate => candidate.selected === true);
    return selected.length === 0 ? candidateFiles[folderId] : selected;
  }
)

const chosenFilesSelector = state => state.importsFiles.chosenFiles

export const makeChosenFilesSelector = () => createSelector(
  chosenFilesSelector,
  (_, props) => props,
  (selectedFiles = {}, folderId ) => {
    return selectedFiles[folderId] || undefined
  }
)

export const makeChosenFileSelector = () => createSelector(
  chosenFilesSelector,
  (_, props) => props,
  (selectedFiles = {}, { folderId, photoId }) => {
    return selectedFiles[folderId] ? selectedFiles[folderId][photoId] : undefined
  }
)

export const makeChosenFilesSelectorCount = () => createSelector(
  chosenFilesSelector,
  (_, props) => props,
  (selectedFiles = {} , folderId) => {
    return Object.values(selectedFiles[folderId] || {}).filter(elt => !!elt).length
  }
)

export const uploadsSelector = state => state.importsFiles.uploads

export const makeUploadsSelector = () => createSelector(
  uploadsSelector,
  (_, props) => props,
  (uploads = {},  { filter = 'ALL', count = false} = {}) => {
    //ALL / ACTIVE / ENDED / ERROR
    let result;
    
    if(filter === 'ALL')
      result = uploads
    else if(filter === 'ACTIVE')
      result = pickBy(uploads, (value) => !value.error && !value.success)
    else if(filter === 'ENDED')
      result = pickBy(uploads, (value) => value.success)
    else if(filter === 'ERROR')
      result = pickBy(uploads, (value) => value.error)

    return count ? Object.keys(result).length : result
  }
)

const updateDocumentsSelector = (state) => state.importsFiles.updateDocuments

export const makeUpdateDocumentSelector = () => createSelector(
  updateDocumentsSelector,
  (_, props) => props,
  (updateDocuments = {}, { folderId, photoId }) => {
    return updateDocuments[folderId] ? updateDocuments[folderId][photoId] : undefined
  }
)

export const updateDocumentsByFolderSelector = createSelector(
  updateDocumentsSelector,
  (_, props) => props,
  (updateDocuments = {}, { folderId }) => {
    return updateDocuments[folderId] ? updateDocuments[folderId] : {}
  }
)

export const makeUpdateDocumentsByFolderSelector = () => updateDocumentsByFolderSelector

export const makeChosenUpdateDocumentsSelectorCount = () => createSelector(
  updateDocumentsByFolderSelector,
  childDocumentsByFolderSeletor,
  (_, props) => props,
  (updateDocuments = {}, documents = {}, { folderId }) => {
    return reduce(updateDocuments, (result, value, key) => {
      const doc = find(documents, doc => doc.id ===  value.id)
      if(!doc)
        return 0
      return result + (isEqualWith(doc.data.imageSettings, value.imageSettings) && isEqualWith(doc.data.cropData, value.cropData, (a, b) => {if(!isNaN(a) && !isNaN(b))return Math.round(a) === Math.round(b)}) ? 0 : 1);
    }, 0)
  }
)


const loadingSelector = state => state.importsFiles.loading
export const makeImportsLoadingSelector = () => createSelector(
  loadingSelector,
  (_, props) => props,
  (loading = {}, { folderId }) => {
    return loading[folderId]
  }
)

export const makeImportsLoadingGlobalSelector = () => createSelector(
  loadingSelector,
  (loading = {}) => {
    return Object.values(loading).reduce((count, loadingFolderCount) => {
      return count + loadingFolderCount
    }, 0)
  }
)