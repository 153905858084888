
import React, { useRef, useEffect, useState} from "react";
import { makeStyles } from "@material-ui/styles";
import { AppBar, Box, Button, Toolbar, Typography } from "@material-ui/core";

import cgvMap, { latest}  from './cgv/index'
import Markdown from "../Markdown";
import { useParams, useHistory} from "react-router";

import { useDispatch, useSelector } from "react-redux";
import { putUserSubscriptionAcceptCGVRoutinePromiseCreator } from "../../redux/Users/routines";

import { hasAcceptedCGV } from "../../redux/Auth/selectors";

const useStyle = makeStyles(theme => ({
  root: {
    flex: 1,
    overflow: 'auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}))

function CGV () {
  const classes = useStyle()
  const { version = latest } = useParams()
  const history = useHistory()
  const acceptMandatory = !useSelector(hasAcceptedCGV)
  const [isBottom, setIsBottom] = useState(!acceptMandatory)
  const dispatch = useDispatch()
  const [cgvMarkdown, setCgvMarkdown] = useState('');
  const scrollRef = useRef()

  // useEffect with an empty dependency array (`[]`) runs only once
  useEffect(() => {
    if(!cgvMap[version])
      return history.replace('/');
    fetch(cgvMap[version].url)
      .then((response) => response.text())
      .then((text) => {
        setCgvMarkdown(text);
      }).finally(() => {
        // if(!scrollRef.current)
        //   return
        // const bottom = scrollRef.current.scrollHeight - scrollRef.current.scrollTop <= scrollRef.current.clientHeight + 20;
        // if (bottom) { 
        //   setIsBottom(true)
        // }
      })
  }, []);

  useEffect(() => {
    if(!cgvMarkdown)
      return
    if(!scrollRef.current)
      return
    const bottom = scrollRef.current.scrollHeight - scrollRef.current.scrollTop <= scrollRef.current.clientHeight + 20;
    if (bottom) { 
      setIsBottom(true)
    }
  }, [cgvMarkdown])

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 20;
    if (bottom) { 
      setIsBottom(true)
    }
  }

  const handleAccept = async (e) => {
    await putUserSubscriptionAcceptCGVRoutinePromiseCreator({version}, dispatch)
  }

  return (
    <div className={classes.root}>
      <AppBar color="default" variant="outlined" position="static">
        <Box component={Toolbar} justifyContent="center">
          <Typography variant="h5">
            Conditions générales de vente
          </Typography>
        </Box>
      </AppBar>
      <Box flexGrow={1} display="flex" overflow="auto"> 
        {/* <Box component={List} flexGrow={0} width={300} borderRight={1} disablePadding overflow="auto">
          <ListItem>
            <ListItemText primary="15 avril 2022" secondary="Accepté le 5 mai 2022"/>
          </ListItem>
          <ListItem>
            <ListItemText primary="10 avril 2021" secondary="Accepté le 10 mai 2021"/>
          </ListItem>
          <ListItem>
            <ListItemText primary="10 avril 2021" secondary="Accepté le 10 mai 2021"/>
          </ListItem>
          <ListItem>
            <ListItemText primary="10 avril 2021" secondary="Accepté le 10 mai 2021"/>
          </ListItem>
          <ListItem>
            <ListItemText primary="10 avril 2021" secondary="Accepté le 10 mai 2021"/>
          </ListItem>
        </Box> */}
        <Box justifyContent="center" flexGrow={1} border={0} overflow="auto" padding={2} onScroll={handleScroll} ref={scrollRef}>
          <Box maxWidth={600} margin="auto" style={{textAlign: "justify"}}>
            <Markdown className="markdown" >
              {cgvMarkdown}
            </Markdown>
          </Box>
        </Box>
      </Box>
      
      <Box component={Toolbar} justifyContent="right" borderTop={1} borderColor="divider">
        {
          acceptMandatory ?
          <Button disabled={!isBottom} onClick={handleAccept}>
            Accepter
          </Button> :
          <Button onClick={() => {history.goBack()}}>
            Fermer
          </Button>
        }
      </Box>
    </div>
  )
}

export default CGV