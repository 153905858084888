import React, {useMemo} from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next"
import { makeImportsLoadingGlobalSelector } from "../redux/ImportsFiles/selectors";
import { useBeforeUnload } from "react-use";


function PromptIfUpload () {

  const { t } = useTranslation();


  const importsLoadingGlobalSelector = useMemo(makeImportsLoadingGlobalSelector,[])
  const importsLoadingGlobalCount = useSelector(importsLoadingGlobalSelector)
  useBeforeUnload(importsLoadingGlobalCount > 0, t('imports.You have uploads in progress, are you sure you want to quit??'));

  return false

}

export default PromptIfUpload