import React from 'react';
import ReactMarkdown from 'markdown-to-jsx';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

function MarkdownListItem(props) {
  return <Typography component="li" variant='body1' {...props} />;
}

const CustomLink = (props) => {
  if(props.href === 'https://app.cephalomax.com')
    return <span>{props.href}</span>
  
  return <Link {...props}/>
}

const options = {
  overrides: {
    h1: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: 'h4',
        component: 'h1',
        align: 'center'
      },
    },
    h2: {
      component: Typography,
      props: { gutterBottom: true, variant: 'h5', component: 'h2', color: 'primary'},
    },
    h3: {
      component: Typography,
      props: { gutterBottom: true, variant: 'h6', component: 'h3', color: 'secondary'},
    },
    h4: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: 'h6',
      },
    },
    p: {
      component: Typography,
      props: { paragraph: true },
    },
    a: { component: CustomLink },
    li: {
      component: MarkdownListItem,
    },
  },
};

export default function Markdown(props) {
  return <ReactMarkdown options={options} {...props} />;
}