import { alertRoutine } from '../../redux/Alerts/routines'

import { i18n } from '../../index';


export const storageQuotaExceededAlertActionCreator = () => (
  alertRoutine.show({
    type: 'dialog',
    maxWidth: 'xs',
    title:  i18n.t('alerts.storageQuotaExceeded.title'),
    content: i18n.t('alerts.storageQuotaExceeded.content'),
    action: () => {
      window.location.replace("https://one.google.com/storage#upgrade")
    },
    actionTitle: i18n.t('alerts.storageQuotaExceeded.actionTitle'),
  })
)
