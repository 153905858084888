import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { redirectingSelector } from '../redux/Users/selectors';

const useStyles = makeStyles(theme => ({
  root: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: theme.palette.background.default,
    zIndex: theme.zIndex.modal+1,
    "& p": {
      margin: theme.spacing(2)
    }
  },
}));

export default ({containerClassName ,...props}) => {
  
  const classes = useStyles()
  const redirecting = useSelector(redirectingSelector)

  if(!redirecting)
    return false

  return (
    <div className={`${classes.root} ${containerClassName}`} >
      <CircularProgress />
      <Typography>
        Redirection...
      </Typography>
    </div>
  )
}