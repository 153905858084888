import React, { useEffect, Fragment, useCallback, useMemo, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import Highlighter from "react-highlight-words";

import { useSelector, useDispatch } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { searchPatientsRoutine } from '../redux/Patients/routines'

import { makeSearchPatientsSelector, patientsIsLoadingSelector, patientsSearchCountSelector } from '../redux/Patients/selectors'

import { capitalizeName } from '../utils/string-helpers'

import Loading from './Loading'

import PatientAvatar from './PatientAvatar'

import { VariableSizeList, areEqual } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import AutoSizer from "react-virtualized-auto-sizer";


import { LIMIT_RESULT } from '../redux/Patients/sagas';
import { collectionKeysEqualityFn } from '../utils/equality';
import { Avatar } from '@material-ui/core';

function a11yProps(patient) {
  return {
    key: patient.id,
    to: `/patient/${patient.id}`,
    label: `${capitalizeName(patient.firstname)} ${patient.lastname.toUpperCase()}`,
    icon: //<PatientAvatar patient={patient} />,
      <Avatar >{`${patient.firstname[0].toUpperCase()}${patient.lastname[0].toUpperCase()}`}</Avatar>,
    tags: patient.tags || [],
  };
}

const useStyles = makeStyles(theme => ({
  // root2: {
  //   display: 'flex',
  //   width: '100%',
  //   maxHeight: '100%',
  //   //alignItems: 'center',
  //   justifyContent: 'center',
  //   //flex: "1 1 100%",
  // },
  // paper2: {
  //   width: theme.breakpoints.values.sm,
  //   margin: theme.spacing(3),
  //   height: 'auto',
  //   overflow: 'auto',
  //   [theme.breakpoints.down('sm')]: {
  //     margin: 0,
  //     border: 'none',
  //     borderRadius: 0,
  //     background: theme.palette.background.default,
  //     width: '100%',
  //   }
  // },
  root: {
    flexGrow: 1,
    display: 'flex',
    //overflow: 'auto',
  },
  paper: {
    width: theme.breakpoints.values.sm,
    margin: `${theme.spacing(3)}px auto`,
    //height: 'auto',
    //overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      border: 'none',
      borderRadius: 0,
      background: theme.palette.background.default,
      width: '100%',
    }
  },
  emptyListItemRoot: {
    textAlign: 'center'
  },
  infoContainer: {
    position: 'absolute',
    top:0,
    left:0,
    bottom:0,
    right:0,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    background: theme.palette.paper,
  },
  listItem: {
    flexWrap: 'wrap',
    '& .listTags': {
      '&::-webkit-scrollbar': {
        display: 'none'
      },
      display: 'flex',
      overflow: 'auto',
      flexBasis: "100%",
      '-ms-overflow-style': 'none',  /* IE and Edge */
      scrollbarWidth: 'none',  /* Firefox */
      '& > *': {
        marginRight: theme.spacing(0.5),
        marginTop: theme.spacing(1),
      },
    },
    '& .text-highlight': {
      color: theme.palette.secondary.main,
      background: 'inherit'
    },
  },
}));

/*
const PatientListItem = ({icon, label, query, divider, tags, ...rest}) => (
  <Fragment>
    <ListItem button component={Link} {...rest}>
      <ListItemIcon>
        {icon}
      </ListItemIcon>
      <ListItemText>
        <Highlighter
          highlightClassName="text-highlight"
          searchWords={query.split(' ')}
          autoEscape={true}
          textToHighlight={label}
        />
      </ListItemText>
      <div className="listTags">
        {
          tags.map((tag, index) => 
            <Chip 
              key={index} 
              label={
                <Highlighter
                  highlightClassName="text-highlight"
                  searchWords={query.split(' ')}
                  autoEscape={true}
                  textToHighlight={tag}
                />} 
              size="small"
              variant="outlined"
            />
          )
        }
      </div>
    </ListItem>
    {
      divider && <Divider variant="inset" />
    }
  </Fragment>
)
*/

const PatientListItem = React.memo(({index, style, data: { classes, isItemLoaded, patients, itemCount, query}, isScrolling, ...rest}) => {

  let content;
  if (!isItemLoaded(index)) {
    content = (
      <ListItem>
        <ListItemText className={classes.emptyListItemRoot}>
          <Typography gutterBottom component="div"><Loading size={30} /></Typography>
        </ListItemText>
      </ListItem>
    );
  } else {
    const {tags = [], ...patient} = patients[index];
    const label= `${capitalizeName(patient.firstname)} ${patient.lastname.toUpperCase()}`
    const divider= itemCount - 1 === index ? false : true
    content = (
      <Fragment>
        <ListItem 
          button 
          component={Link} 
          to={`/patient/${patient.id}`}
          {...rest}
          className={classes.listItem}
        >
          <ListItemIcon>
            <Avatar >{`${patient.firstname[0].toUpperCase()}${patient.lastname[0].toUpperCase()}`}</Avatar>
            {/* <PatientAvatar patient={patient} /> */}
          </ListItemIcon>
          <ListItemText>
            <Highlighter
              highlightClassName="text-highlight"
              searchWords={query.split(' ')}
              autoEscape={true}
              textToHighlight={label}
              sanitize={(text) => text.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase()}
            />
          </ListItemText>
          <div className="listTags">
            {
              tags.map((tag, index) => 
                <Chip 
                  key={index} 
                  label={
                    <Highlighter
                      highlightClassName="text-highlight"
                      searchWords={query.split(' ')}
                      autoEscape={true}
                      textToHighlight={tag}
                      sanitize={(text) => text.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase()}
                    />} 
                  size="small"
                  variant="outlined"
                />
              )
            }
          </div>
        </ListItem>
        {
          divider && <Divider variant="inset" />
        }
      </Fragment>
    );
  }

  return <div style={style}>{content}</div>;

}, areEqual);

export default () => {
  
  const searchPatientsSelector = useMemo(makeSearchPatientsSelector, [])

  const classes = useStyles()
  const { t } = useTranslation()
  //const [highLighted, setHighLighted] = useState(null);
  const patients = useSelector(searchPatientsSelector, collectionKeysEqualityFn(['id', 'updatedAt']))
  const loading = useSelector(patientsIsLoadingSelector)
  const count = useSelector(patientsSearchCountSelector)
  //const history = useHistory()
  const { search: query = '' } = useParams()
  const dispatch = useDispatch()
  const start = useRef(0)
  const listRef = useRef()

  useEffect(() => {
    if(listRef.current)
      listRef.current.resetAfterIndex(0)
  }, [patients])

  // useMemo(() => {
  //   setHighLighted(null)
  // }, [patients])

  // const handleUserKeyPress = useCallback(event => {
  //   const { key } = event;
  //   let next;
  //   if(key === 'ArrowDown') {
  //     if(highLighted === null)
  //       next = 0
  //     else if(highLighted + 1 > patients.length - 1)
  //       next = 0
  //     else 
  //       next = highLighted + 1
  //     setHighLighted(next)
  //     return 
  //   }
  //   if(key === 'ArrowUp') {
  //     if(highLighted === null)
  //       next = patients.length - 1
  //     else if(highLighted - 1 < 0)
  //       next = patients.length - 1
  //     else 
  //       next = highLighted - 1
  //     setHighLighted(next)
  //     return 
  //   }
  //   if(key === 'Enter' && highLighted !== null)
  //      history.push(`/patient/${patients[highLighted].id}`)
  // }, [patients, highLighted]);

  // useEffect(() => {
  //   window.addEventListener('keydown', handleUserKeyPress);
  //   return () => {
  //     window.removeEventListener('keydown', handleUserKeyPress);
  //   };
  // }, [handleUserKeyPress]);


  useEffect(() => {
    start.current = 0
  }, [query])

  const hasNextPatient = patients.length < count;

  const loadNextPatient = useCallback(() => {
    //console.log('loadNextPatient', start.current)
    //if(hasNextPatient) {
      start.current += LIMIT_RESULT
      dispatch(searchPatientsRoutine.trigger({
        search: query || ' ', 
        start: start.current
      }))
    //}
  }, [query])
  
  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const itemCount = hasNextPatient ? patients.length + 1 : patients.length;

  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreItems = useMemo(() => (loading ? () => {} : loadNextPatient), [loading, loadNextPatient]);

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = useCallback(index => !hasNextPatient || index < patients.length, [hasNextPatient, patients.length]);

  const getItemSize = useCallback(index => {
    if(index === itemCount -1){
      if(hasNextPatient)
        return 56
      else if(patients[index].tags?.length)
        return 88
      else
        return 56
    }
    else {
      if(patients[index].tags?.length)
        return 89
      else
        return 57
    }
  }, [hasNextPatient, itemCount, patients])

  const itemData = useMemo(() => ({
    isItemLoaded,
    classes,
    patients,
    itemCount,
    query,
  }), [patients, query])

  return(
    <div className={classes.root}>
        <Paper className={classes.paper} variant="outlined">
          <AutoSizer>
            {({width, height}) => (
              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={itemCount}
                loadMoreItems={loadMoreItems}
                threshold={5}
              > 
                {({ onItemsRendered, ref }) => (
                  <VariableSizeList
                    itemCount={itemCount}
                    onItemsRendered={onItemsRendered}
                    itemSize={getItemSize}
                    estimatedItemSize={56}
                    ref={(r) => {ref(r); listRef.current = r}}
                    height={height-2}
                    width={width}
                    itemData={itemData}
                    useIsScrolling={false}
                    //{...props}
                  >
                    {PatientListItem}
                  </VariableSizeList>
                )}
              </InfiniteLoader>
            )}
          </AutoSizer>
          {/* <List disablePadding>
            {
              !loading &&
              patients.map((patient, index, t) => (
                <PatientListItem 
                  {...a11yProps(patient)} 
                  query={query} 
                  className={classes.listItem} 
                  divider={t.length - 1 === index ? false : true}
                  selected={index === highLighted}
                />
              ))
            }
            {
              !patients.length && !loading &&
                <ListItem>
                  <ListItemText className={classes.emptyListItemRoot}>
                    {t('search.empty')}
                  </ListItemText>
                </ListItem>
            }
            {
              loading && 
                <ListItem>
                  <ListItemText className={classes.emptyListItemRoot}>
                    <Typography gutterBottom component="div"><Loading size={30} /></Typography>
                    <Typography variant="body2">{t('search.search')}</Typography>
                  </ListItemText>
                </ListItem>
            }
          </List> */}
          {
            loading && patients.length === 0 &&
            <div className={classes.infoContainer}>
              <>
                <Typography gutterBottom component="div"><Loading size={30} /></Typography>
                <Typography variant="body2">{t('search.search')}</Typography>
              </>
            </div>
          }
          {
            !loading && patients.length === 0 &&
            <div className={classes.infoContainer}>
              {t('search.empty')}
            </div>
          }
        </Paper>
        
    </div> 
  )
}