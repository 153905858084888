import { alertRoutine } from '../../redux/Alerts/routines'

import { i18n, store } from '../../index';
import theme from '../../utils/theme'
import { push } from 'connected-react-router';
import { localeIsInitialized } from '../../i18n';

export const forbiddenIpAlertActionCreator = async () => {
  
  await localeIsInitialized(i18n)
  return  alertRoutine.show({
    id: 'forbiden-ip',
    type: 'dialog',
    maxWidth: 'xs',
    title:  i18n.t('alerts.forbiddenIp.title'),
    content: i18n.t('alerts.forbiddenIp.content'),
    secondaryActions: [
      {
        title: i18n.t('common:interface.logout'),
        color: theme().palette.error.main,
        action: (parentAlertId) => {
          store.dispatch(push('/logout'))
          store.dispatch(alertRoutine.rejectAll())
        }
      }
    ],
  })
}
