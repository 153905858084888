import { normalize } from 'normalizr';
import { select, takeLatest, put, call, all, takeEvery } from 'redux-saga/effects'
import { getUserRoutine, postUserRoutine, putUserSubscriptionDeclineRoutine, putUserRoutine, putUserSubscriptionAcceptCGVRoutine} from './routines'
import { paymentNeededAlertActionCreator } from '../../components/Account/forms/paymentNeededAlertActionCreator';
import { REHYDRATE } from 'redux-persist'

import { users, getToken } from '../../utils/api'

import { user } from '../schemas'

//not getUser but get Me todo standartise users saga
function* getUserSaga(action) {
  
  yield put(getUserRoutine.request())

  try {

    const token = yield select(getToken);

    const { data } = yield call(users.me, token);

    const normalizedData = yield call(normalize, data, user);

    yield put(getUserRoutine.success(normalizedData));

  }
  catch(error) {
    console.log(error)
    yield put(getUserRoutine.failure(error));
  } 

  yield put(getUserRoutine.fulfill());
}


function* watchGetUser() {
  yield takeLatest([getUserRoutine.TRIGGER], getUserSaga)
}

function* postUserSaga(action) {
  
  yield put(postUserRoutine.request())

  try {

    const token = yield select(getToken);

    const { data } = yield call(users.post, token, action.payload);

    const normalizedData = yield call(normalize, data, user);

    yield put(postUserRoutine.success(normalizedData));

  }
  catch(error) {
    console.log(error)
    yield put(postUserRoutine.failure(error));
  } 

  yield put(postUserRoutine.fulfill());
}

function* watchPostUser() {
  yield takeLatest(postUserRoutine.TRIGGER, postUserSaga)
}

function* putUserSaga(action) {
  
  yield put(putUserRoutine.request())

  try {

    const token = yield select(getToken);

    const { data } = yield call(users.put, token, action.payload.id, action.payload.values);

    const normalizedData = yield call(normalize, data, user);

    yield put(putUserRoutine.success(normalizedData));

  }
  catch(error) {
    console.log(error)
    yield put(putUserRoutine.failure(error));
  } 

  yield put(putUserRoutine.fulfill());
}

function* watchPutUser() {
  yield takeLatest(putUserRoutine.TRIGGER, putUserSaga)
}

function* handleRehydrateUser(action) {
  if(action.type === REHYDRATE && action.key === 'auth' && action.payload && action.payload.user && action.payload.token)
    yield put(getUserRoutine.trigger())  
}

function* watchRehydrate() {
 yield takeEvery(REHYDRATE, handleRehydrateUser)
}

function* putUserSubscriptionDecline(action) {
  
  yield put(putUserSubscriptionDeclineRoutine.request())

  try {

    const token = yield select(getToken);

    const { data } = yield call(users.put_subscription_decline, token);

    const normalizedData = yield call(normalize, data, user);

    yield put(postUserRoutine.success(normalizedData));

  }
  catch(error) {
    console.log(error)
    yield put(putUserSubscriptionDeclineRoutine.failure(error));
  } 

  yield put(putUserSubscriptionDeclineRoutine.fulfill());
}

function* watchPutUserSubscriptionDecline() {
  yield takeLatest(putUserSubscriptionDeclineRoutine.TRIGGER, putUserSubscriptionDecline)
}

function* putUserSubscriptionAcceptCGV(action) {
  
  yield put(putUserSubscriptionAcceptCGVRoutine.request())

  try {

    const token = yield select(getToken);

    const { data } = yield call(users.put_subscription_accept_cgv, token, {
      version: action.payload.version
    });

    const normalizedData = yield call(normalize, data, user);

    yield put(postUserRoutine.success(normalizedData));

  }
  catch(error) {
    console.log(error)
    yield put(putUserSubscriptionAcceptCGVRoutine.failure(error));
  } 

  yield put(putUserSubscriptionAcceptCGVRoutine.fulfill());
}

function* watchPutUserSubscriptionAcceptCGV() {
  yield takeLatest(putUserSubscriptionAcceptCGVRoutine.TRIGGER, putUserSubscriptionAcceptCGV)
}

function* handlePaymentError(action) {
  if(action.error && action.payload.message === "Request failed with status code 402")
    yield put(paymentNeededAlertActionCreator())
}

function* watchPaymentError() {
  yield takeEvery('*', handlePaymentError)
}

function redirect(action) {
  if(!action.payload)
    return;
  
  const {url, params = [], target = ""} = action.payload;
  
  const form = document.createElement("form");
  form.method = "POST";
  form.action = url;
  
  params.forEach(({name, value}) => {
    const input = document.createElement("input"); 
    input.value= value;
    input.name= name;
    form.appendChild(input);
  });

  form.target = target
  document.body.appendChild(form);
  form.submit();
}

function* watchRedirecting() {
  yield takeLatest('STRIPE_REDIRECTING', redirect)
}

function* watchUser() {
  yield all([
    watchRehydrate(),
    watchGetUser(),
    watchPostUser(),
    watchPutUser(),
    watchPutUserSubscriptionDecline(),
    watchPutUserSubscriptionAcceptCGV(),
    watchPaymentError(),
    watchRedirecting(),
  ]);
}

export default watchUser;