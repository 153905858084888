import React, { useState, useEffect, useRef} from 'react';
import { connectField } from 'uniforms';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import { searchPatientsRoutine } from '../../redux/Patients/routines'
import { searchPatientsSelector, patientsIsLoadingSelector, patientsSearchCountSelector} from '../../redux/Patients/selectors'

import { useSelector, useDispatch } from 'react-redux'

import Highlighter from "react-highlight-words";

import Loading from '../Loading'

import PatientAvatar from '../PatientAvatar'
import { capitalizeName } from '../../utils/string-helpers'

import { LIMIT_RESULT } from '../../redux/Patients/sagas';
import { FixedSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import AutoSizer from "react-virtualized-auto-sizer";

const PatientListItem = ({icon, label, query, divider, selected, ...rest}) => (
  <React.Fragment>
    <ListItem button  {...rest}>
      <ListItemIcon>
        {icon}
      </ListItemIcon>
      <ListItemText>
        <Highlighter
          highlightClassName="text-highlight"
          searchWords={query.split(' ')}
          autoEscape={true}
          textToHighlight={label}
        />
      </ListItemText>
      {
        selected &&
        <ListItemSecondaryAction>
          <Checkbox
            edge="end"
            checked
          />
        </ListItemSecondaryAction>
      }
    </ListItem>
    {
      divider && <Divider variant="inset" />
    }
  </React.Fragment>
)

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    height: 200,
    overflow: 'hidden',
  },
  list: {
    padding: 0,
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  emptyListItemRoot: {
    flex: '1',
    textAlign: 'center'
  },
  listItem: {
    //flexWrap: 'wrap',
    '& .text-highlight': {
      color: theme.palette.secondary.main,
      background: 'inherit'
    },
  },
  infoContainer: {
    position: 'absolute',
    top:0,
    left:0,
    bottom:0,
    right:0,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    background: theme.palette.paper,
  },
}))

const CustomPatientSelectorField = ({ 
  onChange, 
  value, 
  className, 
  style, 
  error, 
  initialSearch = "", 
  searchInputProps = {},
}) => {

  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch() 
  const patients = useSelector(searchPatientsSelector)
  const loading = useSelector(patientsIsLoadingSelector)
  const count = useSelector(patientsSearchCountSelector)
  const start = useRef(0)
  const [search, setSearch] = useState(initialSearch)
  const [selected, setSelected] = useState()

  const handlePatientSearch = (e) => {
    setSearch(e.target.value)
  }

  const handlePatientSelect = (patient) => () => {
    setSelected(selected && patient.id === selected.id ? null : patient)
  }
  
  useEffect(() => {
    if(initialSearch !== search)
      setSearch(initialSearch)
  },[initialSearch])

  useEffect(() => {
    onChange(selected)
  },[selected])

  const defaultSearchInputProps = {
    label: "",
    variant: "outlined",
    size: "small",
    fullWidth: true,
  }

  useEffect(() => {
    dispatch(searchPatientsRoutine.trigger({search}))
    start.current = 0
  }, [search])


  const hasNextPatient = patients.length < count;

  const loadNextPatient = () => {
    console.log('loadNextPatient')
    //if(hasNextPatient) {
      start.current += LIMIT_RESULT
      dispatch(searchPatientsRoutine.trigger({
        search: search, 
        start: start.current
      }))
    //}
  }

  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const itemCount = hasNextPatient ? patients.length + 1 : patients.length;

  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreItems = loading ? () => {} : loadNextPatient;

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = index => !hasNextPatient || index < patients.length;

  const getItemSize = index => {
    if(index === itemCount -1){
      return 56
    }
    else {
      return 57
    }
  }

  const PatientListItem = ({index, style, isScrolling, ...rest}) => {

    let content;
    if (!isItemLoaded(index)) {
      content = (
        <ListItem>
          <ListItemText className={classes.emptyListItemRoot}>
            <Typography gutterBottom component="div"><Loading size={30} /></Typography>
          </ListItemText>
        </ListItem>
      );
    } else {
      const patient = patients[index];
      const label= `${capitalizeName(patient.firstname)} ${patient.lastname.toUpperCase()}`
      const divider = itemCount - 1 === index ? false : true
      content = (
        <>
          <ListItem 
            className={classes.listItem}
            onClick={handlePatientSelect(patient)}
            button
          >
            <ListItemIcon>
              <PatientAvatar patient={patient} />
            </ListItemIcon>
            <ListItemText>
              <Highlighter
                highlightClassName="text-highlight"
                searchWords={search.split(' ')}
                autoEscape={true}
                textToHighlight={label}
                sanitize={(text) => text.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase()}
              />
            </ListItemText>
            {
              selected && patient.id === selected.id &&
              <ListItemSecondaryAction>
                <Checkbox
                  edge="end"
                  checked
                />
              </ListItemSecondaryAction>
            }
          </ListItem>
          {
            divider && <Divider variant="inset" />
          }
        </>
      );
    }

    return <div style={style}>{content}</div>;

  }

  return (
    <div>
      <TextField 
        type="search"
        value={search}
        onChange={handlePatientSearch}
        {...{...defaultSearchInputProps, ...searchInputProps}}
      />
      <Paper variant="outlined" className={classes.paper}>
        <AutoSizer>
          {({width, height}) => (
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={itemCount}
              loadMoreItems={loadMoreItems}
              threshold={5}
            > 
              {({ onItemsRendered, ref }) => (
                <FixedSizeList
                  itemCount={itemCount}
                  onItemsRendered={onItemsRendered}
                  itemSize={57/*getItemSize*/}
                  ref={ref}
                  height={height-2}
                  width={width}
                  className="MuiList-root"
                >
                  {PatientListItem}
                </FixedSizeList>
              )}
            </InfiniteLoader>
          )}
        </AutoSizer>
        {/* <List className={classes.list}>
          {
            !loading &&
            patients.map((patient, index, t) => (
              <PatientListItem
                key={patient.id}
                className={classes.listItem}
                query={search} 
                label={`${capitalizeName(patient.firstname)} ${patient.lastname.toUpperCase()}`}
                icon={<PatientAvatar patient={patient} />}
                divider={t.length - 1 === index ? false : true}
                selected={selected && patient.id === selected.id}
                onClick={handlePatientSelect(patient)}
              />
            ))
          }
          {
            !patients.length && !loading &&
              <ListItem className={classes.emptyListItemRoot}>
                <ListItemText>
                  {t('search.empty')}
                </ListItemText>
              </ListItem>
          }
          {
            loading && 
              <ListItem className={classes.emptyListItemRoot}>
                <ListItemText >
                  <Typography gutterBottom component="div"><Loading size={30} /></Typography>
                  <Typography variant="body2">{t('search.search')}</Typography>
                </ListItemText>
              </ListItem>
          }
        </List> */}
        {
          loading && patients.length === 0 &&
          <div className={classes.infoContainer}>
            <>
              <Typography gutterBottom component="div"><Loading size={30} /></Typography>
              <Typography variant="body2">{t('search.search')}</Typography>
            </>
          </div>
        }
        {
          !loading && patients.length === 0 &&
          <div className={classes.infoContainer}>
            {t('search.empty')}
          </div>
        }
      </Paper>
    </div>          
  )
}

export default connectField(CustomPatientSelectorField);