import { handleActions, combineActions } from 'redux-actions'
import { alertRoutine } from './routines'
import { v4 as uuidv4} from 'uuid';


const intialState = {
  alerts: [],
}

export const alertsReducer = handleActions({
  
  [combineActions(alertRoutine.SHOW)]: (state, action) => {
    const id = action.payload.id || uuidv4()
    return {
      alerts: {
        ...state.alerts,
        [id]: {
          id,
          ...action.payload
        }
      }
    }
  },
  
  [combineActions(alertRoutine.HIDE)]: (state, action) => {
    const alerts = {...state.alerts}
    delete alerts[action.payload]
    return {
      alerts
    }
  },

},
intialState
);