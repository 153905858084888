import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'

import rootSaga from '../sagas'
import createRootReducer from '../reducers'

export const history = createBrowserHistory()


export default function configureStore(preloadedState) {

  const sagaMiddleware = createSagaMiddleware()

  const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        sagaMiddleware
        // ... other middlewares ...
      ),
    ),
  )

  sagaMiddleware.run(rootSaga)

  return store
}