const schema = {
  type: 'object',
  properties: {
    patient: { 
      type: 'object',
      properties: {
        id: {
          type: 'string'
        },
      },
      required: ['id']
    }
  },
  required: ['patient']
};

export default schema