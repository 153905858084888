import { createSelector } from "reselect";
import _ from 'lodash'
import moment from "moment";

export const tasksIsLoadingSelector = state => state.tasks.tasks.loading;
export const tasksIsErrorSelector = state => state.tasks.tasks.error;

export const tasksSelector = state => (state.entities.tasks || {});
export const tasksResultSelector = state => state.tasks.tasks.result;
export const tasksSearchCountSelector = state => state.tasks.tasks.searchCount
export const tasksActiveCountSelector = state => state.tasks.tasks.activeCount
export const tasksEndedCountSelector = state => state.tasks.tasks.endedCount
export const tasksTodayCountSelector = state => state.tasks.tasks.todayCount

export const visibleTasksSelector = createSelector(
  tasksSelector,
  (tasks = {}) => _.filter(tasks, (task) => !!task._id).map(task => {
    const today = moment().endOf('day');
    const due_date = moment(task.due_date)
    const diff = today.diff(due_date, 'days')
    const display_date = task.done? moment(task.done) : diff > 0 ? today : due_date; 

    return {
      ...task,
      display_date: display_date.format('YYYY-MM-DD'),
      diff: diff,
      type: 'task',
    }
  })
)

export const searchTasksSelector = createSelector(
  tasksSelector,
  tasksResultSelector,
  (tasks = {}, result = []) => {
    return result.map(id => tasks[id]).map(task => {
      const today = moment().endOf('day');
      const due_date = moment(task.due_date)
      const diff = today.diff(due_date, 'days')
      const display_date = task.done? moment(task.done) : diff > 0 ? today : due_date; 
  
      return {
        ...task,
        display_date: display_date.format('YYYY-MM-DD'),
        diff: diff,
        type: 'task',
      }
    })
    //return Object.values(_.filter(patients, (patient, key) => result.includes(key)))
  }
)

export const makeSearchTasksSelector = () => searchTasksSelector


export const tasksPatientSelector = createSelector(
  visibleTasksSelector,
  (_, props) => props,
  (tasks = [], patientId) => {
    return tasks.filter((task) => task.parent_patient === patientId)
  }
);

export const makeTasksPatientSelector = () => tasksPatientSelector;

export const lateTaskSelector = createSelector(
  tasksPatientSelector,
  (tasks = []) => {
    return tasks.filter((task) => !task.done && task.diff >= 0)
  }
);

export const tasksPatientByDateSelector = 
    createSelector(
      tasksPatientSelector,
      (tasks = [] ) => {
        console.log('tasksPatientByDateSelector', tasks)
        return tasks
          .reduce((obj, task) => {
            if(obj[task.display_date])
                obj[task.display_date].push(task)
              else
                obj[task.display_date] = [task]
              return obj
          }, {})
      }
    )

export const makeTasksPatientByDateSelector = () => tasksPatientByDateSelector


