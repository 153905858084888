import { createSelector } from 'reselect'
import { filter, find } from 'lodash'

import { visibleDocumentsSelector } from '../Documents/selectors'

import { Matrix } from '@svgdotjs/svg.js'


export const autoSaveStatutSelector = (state) => state.cephalo.auto_save.saving

//on selectionne le "state" des analyses cephalo
const cephalosAnalyseSelector = (state) => state.cephalo.analyses_cephalo

//on selectionne le "state" de l'analyses cephalo pour le folderId et l'orientation key 'folderId_orientation'
export const makeCephaloAnalyseSelector = () => 
  createSelector(
    cephalosAnalyseSelector,
    (_, props) => props,
    (state = {}, { folderId, orientation }) => state[`${folderId}_${orientation}`] ? state[`${folderId}_${orientation}`].present : state[`${folderId}_${orientation}`]
  )

//on selectionne le past du "state" de l'analyses cephalo pour le folderId et l'orientation key 'folderId_orientation'
export const makeCephaloAnalysePastSelector = () => 
createSelector(
  cephalosAnalyseSelector,
  (_, props) => props,
  (state = {}, { folderId, orientation }) => state[`${folderId}_${orientation}`] ? state[`${folderId}_${orientation}`].past : state[`${folderId}_${orientation}`]
)

//on selectionne le future du "state" de l'analyses cephalo pour le folderId et l'orientation key 'folderId_orientation'
export const makeCephaloAnalyseFutureSelector = () => 
createSelector(
  cephalosAnalyseSelector,
  (_, props) => props,
  (state = {}, { folderId, orientation }) => state[`${folderId}_${orientation}`] ? state[`${folderId}_${orientation}`].future : state[`${folderId}_${orientation}`]
)

export const externalCephaloPointsIsLoadingSelector = state => state.cephalo.points.loading
export const externalCephaloPointsIsErrorSelector = state => state.cephalo.points.error

export const externalCephaloPointsSelector = state => state.entities.cephalo_points

export const visibleExternalCephaloPointsSelector = createSelector(
  externalCephaloPointsSelector,
  (cephaloPoints = {}) => filter(cephaloPoints, (cephalo_point) => !!cephalo_point._id)
)

export const makeExternalCephaloPointsSelector = () => 
createSelector(
  visibleExternalCephaloPointsSelector,
  (_, props) => props,
  (cephaloPoints, { media, orientation }) => {

    let filteredPoints = cephaloPoints
    if(media)
      filteredPoints = filter(filteredPoints, (point) => point.media === media)
    if(orientation)
      filteredPoints = filter(filteredPoints, (point) => point.orientation === orientation)

    return filteredPoints
  }
)

const getBBox = (size, angle) => {
  let canvasSize;

  if (Math.floor(Math.abs(angle) / 90) % 2) {
    canvasSize = {
      width:
        size.width * Math.sin((Math.abs(angle % 90) * Math.PI) / 180) +
        size.height * Math.cos((Math.abs(angle % 90) * Math.PI) / 180),
      height:
        size.height * Math.sin((Math.abs(angle % 90) * Math.PI) / 180) +
        size.width * Math.cos((Math.abs(angle % 90) * Math.PI) / 180)
    };
  } else {
    canvasSize = {
      width:
        size.height * Math.sin((Math.abs(angle % 90) * Math.PI) / 180) +
        size.width * Math.cos((Math.abs(angle % 90) * Math.PI) / 180),
      height:
        size.width * Math.sin((Math.abs(angle % 90) * Math.PI) / 180) +
        size.height * Math.cos((Math.abs(angle % 90) * Math.PI) / 180)
    };
  }

  return canvasSize;
}; 

export const makeMatriceSelector = () => 
createSelector(
  cephalosAnalyseSelector,
  visibleDocumentsSelector,
  (_, props) => props,
  (state = {}, documents = [], {folderId, orientation, }) => {

    const photo_original = orientation === 'sagital' ? 
      find(documents, doc => doc.parent_doc === folderId && doc.name === `exo_lateral_original` )
      : find(documents, doc => doc.parent_doc === folderId && doc.name === `exo_frontal_original` )

    const photo_cropped = orientation === 'sagital' ? 
      find(documents, doc => doc.parent_doc === folderId && doc.name === `exo_lateral` )
      : find(documents, doc => doc.parent_doc === folderId && doc.name === `exo_frontal` )

    const radio_original = orientation === 'sagital' ? 
      find(documents, doc => doc.parent_doc === folderId && doc.name === `xray_lateral_original` )
      : find(documents, doc => doc.parent_doc === folderId && doc.name === `xray_frontal_original` )

    const radio_cropped = orientation === 'sagital' ? 
      find(documents, doc => doc.parent_doc === folderId && doc.name === `xray_lateral` )
      : find(documents, doc => doc.parent_doc === folderId && doc.name === `xray_frontal` )



    //if((!photo_cropped.data.cropData.original_width && !photo_original.data.imageMediaMetadata )|| (!radio_cropped.data.cropData.original_width && !radio_original.data.imageMediaMetadata))
    //  return false

    let photoRadioScale = 1
    if(photo_cropped && radio_cropped) {
      
      const photoSize = {w: photo_cropped.data.cropData.width, h: photo_cropped.data.cropData.height}
      const radioSize = {w: radio_cropped.data.cropData.width, h: radio_cropped.data.cropData.height}

      const photoMin = Math.min(photoSize.w, photoSize.h)
      const radioMax = Math.max(radioSize.w, radioSize.h)

      photoRadioScale = photoMin/radioMax
    }

    //CXRAY_to_OXRAY
    let M1 = new Matrix()
    if(radio_original && radio_cropped && (radio_original.data.imageMediaMetadata || (radio_cropped.data.cropData.original_width && radio_cropped.data.cropData.original_height))) {
      const xray_crop_data = radio_cropped.data.cropData
      const xray_original_width = radio_cropped.data.cropData.original_width || radio_original.data.imageMediaMetadata.width
      const xray_original_height = radio_cropped.data.cropData.original_height || radio_original.data.imageMediaMetadata.height

      const xray_original_size = {
        width: xray_original_width,
        height: xray_original_height,
      }

      const { width: bb_xray_w, height: bb_xray_h } = getBBox(xray_original_size, xray_crop_data.rotate);

      M1 = M1.translate(xray_crop_data.x, xray_crop_data.y)
              .translate(-bb_xray_w / 2, -bb_xray_h / 2)
                .rotate(-xray_crop_data.rotate)
                  .translate(xray_original_size.width / 2, xray_original_size.height / 2);
    }
    //else M1 matrice unité pour ne rien changer car meme coordonée

    const IM1 = M1.inverse()

    //CPHOTO_to_OPHOTO
    let M3 = new Matrix()
    if(photo_original && photo_cropped && (photo_original.data.imageMediaMetadata || (photo_original.data.cropData.original_width && photo_original.data.cropData.original_height))) {
      const photo_crop_data = photo_cropped.data.cropData
      const photo_original_width = photo_cropped.data.cropData.original_width || photo_original.data.imageMediaMetadata.width
      const photo_original_height = photo_cropped.data.cropData.original_height || photo_original.data.imageMediaMetadata.height

      const photo_original_size = {
        width: photo_original_width,
        height: photo_original_height,
      }

      const { width: bb_photo_w, height: bb_photo_h } = getBBox(photo_original_size, photo_crop_data.rotate);

      M3 = M3.translate(photo_crop_data.x, photo_crop_data.y)
                .translate(-bb_photo_w / 2, -bb_photo_h / 2)
                  .rotate(-photo_crop_data.rotate)
                    .translate(photo_original_size.width / 2, photo_original_size.height / 2);
    }
    //else M3 matrice unité pour ne rien changer car meme coordonée

    const IM3 = M3.inverse()

    //OXRAY_to_OPHOTO
    let M4;
    //CXRAY_to_CPHOTO
    let M2;
    if(state[`${folderId}_${orientation}`] && state[`${folderId}_${orientation}`].present && state[`${folderId}_${orientation}`].present.profil_transform) {
      const cropped_xray_to_cropped_photo_transform = state[`${folderId}_${orientation}`].present.profil_transform
      
      M4 = new Matrix().scale(
        cropped_xray_to_cropped_photo_transform.scaleX,
        cropped_xray_to_cropped_photo_transform.scaleY
      )
      
      .rotate(cropped_xray_to_cropped_photo_transform.rotate)
      .translate(
        cropped_xray_to_cropped_photo_transform.translateX,
        cropped_xray_to_cropped_photo_transform.translateY
      )

      M2 = IM3.multiply(
        M4.multiply(M1)
      )

    }
    else {
      M2 = new Matrix().scale(photoRadioScale)
      M4 = M3.multiply(
        M2.multiply(IM1)
      )
    }

    const IM4 = M4.inverse()

    

    const IM2 = M2.inverse()

    //OXRAY_to_CPHOTO
    const M5 =M2.multiply(
      IM1
    )

    const IM5 = M5.inverse()


    //OPHOTO_to_CXRAY
    const M6 =IM2.multiply(
      IM3
    )

    const IM6 = M6.inverse()

    return {
      M1,
      IM1,
      M2,
      IM2,
      M3,
      IM3,
      M4,
      IM4,
      M5,
      IM5,
      M6,
      IM6
    }
  }
)