import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Typography } from '@material-ui/core';
import WifiOffIcon from '@material-ui/icons/WifiOff';
import { useNetworkState } from 'react-use';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: theme.palette.background.default,
    zIndex: theme.zIndex.modal+1,
    "& p": {
      margin: theme.spacing(2)
    },
    "& svg": {
      margin: theme.spacing(2),
      //fontSize: 50,
      ...theme.typography.h2,
      color: theme.palette.text.secondary
    },
  },
}));

export default ({containerClassName ,...props}) => {
  
  const classes = useStyles()
  const { online, ...rest } = useNetworkState();  
  const {t} = useTranslation()
  console.log('useNetwork', rest)

  if(online || process.env.NODE_ENV === 'development')
    return false

  return (
    <div className={`${classes.root} ${containerClassName}`} >
      
      <WifiOffIcon />
      <Typography align="center" variant="body1">
        {t("offline.Pas de connexion internet")}
      </Typography>
      <Typography align="center" variant="body1">
        {t("offline.Veuillez vérifier votre réseau et son branchement, le modem et le routeur ainsi que votre connexion au Wifi")}
      </Typography>
    </div>
  )
}