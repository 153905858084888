import { takeEvery } from 'redux-saga/effects'
import { garbageRoutine } from './routines'

function garbage(action) {
  URL.revokeObjectURL(action.payload.src)
}

function* watchGarbage() {
  yield takeEvery(garbageRoutine.TRIGGER, garbage)
}

export default watchGarbage;