import React, { useContext, useEffect } from 'react';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { useSelector, useDispatch } from 'react-redux'
import { alertRoutine } from '../redux/Alerts/routines'
import { useTranslation } from 'react-i18next';

import { AutoForm } from 'uniforms-material';
import { JSONSchemaBridge } from 'uniforms-bridge-json-schema';
import { AjvValidatorContext } from '../utils/context/AjvContext';

import { MemoryRouter } from "react-router-dom";
import { Box, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  wrapper: {
//    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  contentWrapper: {
    flex: '1 1 auto',
    overflow: 'hidden',
    display: 'flex',
  },
  nav: {
    width: 250,
    flexGrow: 0,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AlertDialog() {

  const dispatch = useDispatch()
  const theme = useTheme()
  const alerts = useSelector(state => state.alerts.alerts || {}) 
  const [closed, setCLosed] = React.useState({})
  const [loading, setLoading] = React.useState({})
  const { createValidator } = useContext(AjvValidatorContext);
  const { t } = useTranslation()
  const classes = useStyles();

  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const handleReject = (id) => () => {
    dispatch(alertRoutine.reject(id))
  }

  const handleSecondary = (action, parentAlertId) => () => {
    action(parentAlertId, {
      progress : {
        handleTrigger: handleTrigger(parentAlertId),
        handleFulfill: handleFulfill(parentAlertId)
      },
      reject: handleReject(parentAlertId),
    })
  }

  const handleAccept = (payload) => () => {
    dispatch(alertRoutine.accept(payload))
  }

  const handleClose = (id, closeReasons = ['clickaway', 'timeout', 'escapeKeyDown', 'backdropClick']) => (e, reason) => {
    if(reason && !closeReasons.includes(reason))
      return

    setCLosed({
      ...closed,
      [id]: true
    })
  }

  const handleTrigger = (id) => () => {
    setLoading({
      ...loading,
      [id]: true
    })
  } 

  const handleFulfill = (id) => () => {
    setLoading({
      ...loading,
      [id]: false
    })
  } 

  const snacks = Object.values(alerts).filter(alert => alert.type === 'snack')
  const dialogs = Object.values(alerts).filter(alert => alert.type !== 'snack')
  
  useEffect(() => {
    setCLosed({})
  }, [alerts])
  return (
    <div>
      { 
        snacks.map((alert, index) => {
            if(alert.severity)
              return (
                <Snackbar
                  key={alert.id}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  open={!closed[alert.id]}
                  onClose={handleClose(alert.id, alert.closeReasons)}
                  autoHideDuration={alert.persist ? null : 5000}
                  onExited={handleReject(alert.id)}                  
                >
                  <Alert 
                    onClose={handleClose(alert.id)}
                    action={ 
                      alert.action &&
                      <Button onClick={handleAccept({id: alert.id})} color="inherit">
                        {alert.actionTitle || t('common:button.ok')}
                      </Button> || 
                        alert.loading && <CircularProgress size={30} style={{marginRight: 8}}/>
                    } 
                    severity={alert.severity}>
                    {alert.title}
                  </Alert>
                </Snackbar>
              )




            return (
              <Snackbar
                key={alert.id}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                open={!closed[alert.id]}
                onClose={handleClose(alert.id, alert.closeReasons)}
                autoHideDuration={alert.persist ? null : 5000}
                onExited={handleReject(alert.id)}
                message={alert.title}
                severity={alert.severity}
                action={
                  alert.action &&
                  <Button onClick={handleAccept({id: alert.id})} color="primary">
                    {alert.actionTitle || t('common:button.ok')}
                  </Button> || 
                    alert.loading && <CircularProgress size={30} style={{marginRight: 8}}/>
                }
              />
            )
        })
      }
      { 
        dialogs.map((alert, index) => {
          if(alert.type === 'dialog') {
            return (
              <Dialog
                PaperProps={{
                  style: {opacity: index < dialogs.length - 1 && !alert.neverHide? 0 : 1 }
                }}
                key={alert.id}
                open={!closed[alert.id]}
                fullWidth={alert.maxWidth ? true : false}
                fullScreen={alert.fullScreen ? true : alert.fullScreenSmallScreen && fullScreen ? true : false}
                maxWidth={alert.maxWidth || false}
                onClose={alert.closeAction || handleClose(alert.id)}
                onExited={handleReject(alert.id)}
                hideBackdrop={dialogs.length > 1 && index > 0 && !alert.neverHide}
                disableEscapeKeyDown={alert.disableEscapeKeyDown ? true : false}
                disableBackdropClick={alert.disableBackdropClick ? true : false}
                disableEnforceFocus={alert.disableEnforceFocus || false}
                container={alert.container}
                scroll={alert.scroll}
              >
                {
                  (alert.title || alert.headerActions) && 
                  <Box m={0} pl={3} pr={1} py={2} flex="0 0 auto" display="flex">
                    {
                      alert.title && 
                      <Typography variant="h6">{alert.title}</Typography>
                    } 
                    <Box flex={1}/> 
                    {
                      alert.headerActions && 
                      alert.headerActions.map((action, index) => (
                        <Button 
                          key={index}
                          style={{color: action.color || theme.palette.secondary.main}} 
                          onClick={handleSecondary(action.action, alert.id)}>
                          {action.title}
                        </Button>
                      ))
                    }        
                  </Box>
                }
                {/* {
                  alert.title && <DialogTitle>{alert.title}</DialogTitle>
                } */}
                {
                  alert.content &&
                  <DialogContent {...(alert.noPaddingContent ? {style: {paddingRight: 0, paddingLeft: 0,}} : {})}>

                    {
                      typeof alert.content === 'string' ?
                      <DialogContentText style={{whiteSpace: 'pre-line'}}>
                        {alert.content}
                      </DialogContentText> :
                      alert.content
                    }
                    
                  </DialogContent>
                }
                <DialogActions>
                  {
                    alert.secondaryActions &&
                      alert.secondaryActions.map((action, index) => (
                        <Button 
                          key={index}
                          style={{color: action.color || theme.palette.secondary.main}} 
                          onClick={handleSecondary(action.action, alert.id)}>
                          {action.title}
                        </Button>
                      ))
                  }
                  <div style={{flexGrow: 1}}/>
                  {
                    alert.action ? 
                    <React.Fragment>
                      <Button onClick={alert.closeAction || handleClose(alert.id)} color="inherit" style={{color: theme.palette.action.active}}>
                        {t('common:button.cancel')}
                      </Button>
                      <div className={classes.wrapper}>
                        <Button 
                          color="primary" 
                          disabled={loading[alert.id]} 
                          variant="text" 
                          onClick={
                            handleAccept({
                              id: alert.id, 
                              progress : {
                                handleTrigger: handleTrigger(alert.id),
                                handleFulfill: handleFulfill(alert.id)
                              }
                            })
                          }
                          //style={{color: theme.palette.success.main}}
                        >
                          {alert.actionTitle || t('common:button.ok')}
                        </Button>
                        {loading[alert.id] && <CircularProgress size={24} className={classes.buttonProgress} />}
                      </div>
                      {/* <Button 
                        onClick={
                          //handleAccept({id: alert.id})
                          handleAccept({
                            id: alert.id, 
                            progress : {
                              handleTrigger: handleTrigger(alert.id),
                              handleFulfill: handleFulfill(alert.id)
                            }
                          })
                        } 
                        color="primary" 
                        autoFocus
                      >
                        {alert.actionTitle || t('common:button.ok')}
                      </Button> */}
                    </React.Fragment> :
                    <Button onClick={handleClose(alert.id)} color="primary">
                      {t('common:button.close')}
                    </Button>
                  }
                  
                </DialogActions>
              </Dialog>
            )
          }
          if(alert.type === 'form') {
            let formRef;
            let focusRef;
            const schemaValidator = createValidator(alert.schema);
            const bridge = new JSONSchemaBridge(alert.schema, schemaValidator);
            return (
              <Dialog
                PaperProps={{
                  style: {opacity: index < dialogs.length - 1 && !alert.neverHide? 0 : 1 }
                }}
                key={alert.id}
                open={!closed[alert.id]}
                onClose={handleClose(alert.id)}
                fullWidth={alert.maxWidth ? true : false}
                fullScreen={alert.fullScreen ? true : alert.fullScreenSmallScreen && fullScreen ? true : false}
                maxWidth={alert.maxWidth || false}
                onExited={handleReject(alert.id)}
                hideBackdrop={dialogs.length > 1 && index > 0 && !alert.neverHide}
                onEntered={() => focusRef && focusRef.current && focusRef.current.focus()}
                disableEscapeKeyDown={alert.disableEscapeKeyDown ? true : false}
                disableBackdropClick={alert.disableBackdropClick ? true : false}
                disableEnforceFocus={alert.disableEnforceFocus || false}
                scroll={alert.scroll}
              >
                <DialogTitle>{alert.title}</DialogTitle>
                { 
                  alert.memoryRouterWrapper ? 
                  <MemoryRouter
                    initialEntries={alert.initialEntrie ? [alert.initialEntrie] : ['/']}
                    initialIndex={0}
                  >
                    <div className={classes.contentWrapper}>
                        <div />
                        {
                          alert.nav &&
                          <DialogContent className={classes.nav}>
                            {alert.nav}
                          </DialogContent>
                        }
                        {
                          alert.content &&
                          <DialogContent id={alert.contentId}>
                            <AutoForm 
                              schema={bridge}
                              onSubmit={ async (values) => { 
                                handleAccept({
                                  id: alert.id, 
                                  values, 
                                  progress : {
                                    handleTrigger: handleTrigger(alert.id),
                                    handleFulfill: handleFulfill(alert.id)
                                  }
                                })()
                              }}
                              ref={ref => (formRef = ref)}
                              model={alert.model}
                              showInlineError={!!alert.showInlineError}
                            >
                              {/*React.cloneElement(alert.content, {autoFocusRef: (ref) => focusRef = ref})*/}
                              {alert.content}
                            </AutoForm>
                          </DialogContent>
                        }
                    </div>
                  </MemoryRouter>
                  :
                  <div className={classes.contentWrapper}>
                    <div />
                    {
                      alert.nav &&
                      <DialogContent className={classes.nav}>
                        {alert.nav}
                      </DialogContent>
                    }
                    {
                      alert.content &&
                      <DialogContent id={alert.contentId}>
                        <AutoForm 
                          schema={bridge}
                          onSubmit={ async (values) => { 
                            handleAccept({
                              id: alert.id, 
                              values, 
                              progress : {
                                handleTrigger: handleTrigger(alert.id),
                                handleFulfill: handleFulfill(alert.id)
                              }
                            })()
                          }}
                          ref={ref => (formRef = ref)}
                          model={alert.model}
                          showInlineError={!!alert.showInlineError}
                        >
                          {/*React.cloneElement(alert.content, {autoFocusRef: (ref) => focusRef = ref})*/}
                          {alert.content}
                        </AutoForm>
                      </DialogContent>
                    }
                  </div>
                }
                {/*
                  alert.content &&
                  <DialogContent>
                    <AutoForm 
                      schema={bridge}
                      onSubmit={ async (values) => { 
                        handleAccept({
                          id: alert.id, 
                          values, 
                          progress : {
                            handleTrigger: handleTrigger(alert.id),
                            handleFulfill: handleFulfill(alert.id)
                          }
                        })()
                      }}
                      ref={ref => (formRef = ref)}
                      model={alert.model}
                    >
                      {alert.content}
                    </AutoForm>
                  </DialogContent>
                */}
                <DialogActions>
                  {
                    alert.secondaryActions &&
                      alert.secondaryActions.map((action, index) => (
                        <Button 
                          key={index}
                          style={{color: action.color || theme.palette.secondary.main}} 
                          onClick={handleSecondary(action.action, alert.id)}>
                          {action.title}
                        </Button>
                      ))
                  }
                  <div style={{flexGrow: 1}}/>
                  {
                      !alert.disableCancel && 
                    <Button 
                      onClick={handleClose(alert.id)} 
                      color="default"
                    >
                      {t('common:button.cancel')}
                    </Button>
                  }
                  <div className={classes.wrapper}>
                    <Button 
                      color="primary" 
                      disabled={loading[alert.id]} 
                      variant="text" 
                      onClick={() => formRef.submit()}
                      //style={{color: theme.palette.success.main}}
                    >
                      {alert.actionTitle || t('common:button.ok')}
                    </Button>
                    {loading[alert.id] && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </div>
                </DialogActions>
              </Dialog>
            )
          }
          return false
        })
      }
    </div>
  );
}