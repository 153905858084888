import { createSelector } from "reselect";
import { filter, find, groupBy } from 'lodash'
import { patientFolderByDateSelector } from "../Documents/selectors";
import { tasksPatientByDateSelector } from "../Tasks/selectors";
export const contactsIsLoadingSelector = state => state.contacts.contacts.loading;
export const contactsIsErrorSelector = state => state.contacts.contacts.error;

export const autoSaveStatutSelector = (state) => state.contacts.auto_save.saving
export const contactsSelector = state => (state.entities.contacts || {});
export const contactsResultSelector = state => state.contacts.contacts.result;

export const visibleContactsPatientSelector = createSelector(
  contactsSelector,
  (contacts = {}) => filter(contacts, (contact) => !!contact._id)
)

const pendingContactSelector = createSelector(
  visibleContactsPatientSelector,
  (_, props) => props,
  (contacts = {},  patientId) =>  find(contacts, (contact) => contact.parent_patient === patientId && !contact.date) || false
)

export const makePendingContactSelector =  () => pendingContactSelector;

export const contactsPatientSelector = createSelector(
  visibleContactsPatientSelector,
  patientFolderByDateSelector,
  tasksPatientByDateSelector,
  (_, props) => props,
  (contacts = {}, folder_by_date = {}, tasks_by_date = {}, patientId) => {

    let simple_contacts = Object.values(contacts).filter(contact => contact.parent_patient === patientId && !contact.type)

    let chip_contacts = Object.values(contacts).filter(contact => contact.parent_patient === patientId && contact.type)

    const group_by_date_chip_contacts = groupBy(chip_contacts, chip_contact => chip_contact.date)

    const chips_dates = [...new Set([...Object.keys(group_by_date_chip_contacts), ...Object.keys(folder_by_date), ...Object.keys(tasks_by_date)])]

    chip_contacts = chips_dates.map(date => {
      const chips = [...(group_by_date_chip_contacts[date] || []), ...(folder_by_date[date] || []), ...(tasks_by_date[date] || [])]
      return {
        id: chips.map(chip => chip._id).join(','),
        updatedAt: chips.map(chip => chip.updatedAt).join(','),
        date,
        alone: !simple_contacts.some(contact => contact.date === date),
        chips, 
      }
    })

    simple_contacts = simple_contacts.map(contact => ({
      ...contact,
      alone: !chip_contacts.some(chip_contact => chip_contact.date === contact.date) &&  !folder_by_date[contact.date] &&  !tasks_by_date[contact.date],
    }))

    return [...chip_contacts, ...simple_contacts].sort((a,b) => a.date < b.date ? 1 : -1)
  }
);

export const makeContactsPatientSelector = () =>contactsPatientSelector;