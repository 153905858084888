import { all, call } from 'redux-saga/effects';

import { createVisibleChangeSaga } from "redux-saga-document-visibility";
import { watchChangeLanguageSaga } from './i18n';
import { routinePromiseWatcherSaga } from 'redux-saga-routines';

import authSaga from './redux/Auth/sagas';
import userSaga from './redux/Users/sagas';
import patientsSaga from './redux/Patients/sagas';
import documentsSaga from './redux/Documents/sagas';
import alertsSaga from './redux/Alerts/sagas';
import paramsSaga from './redux/Params/sagas';
import importsFilesSaga from './redux/ImportsFiles/sagas';
import garbageSaga from './redux/Garbage/sagas';
import cephaloSaga from './redux/Cephalos/sagas';
import contactsSaga from './redux/Contacts/sagas';
import watchTask from './redux/Tasks/sagas';
import diagnosticSaga from './redux/Diagnostic/sagas';

const timeoutDelay = 5 * 1000; // 30 sec.
const visibilitySaga = createVisibleChangeSaga(timeoutDelay);

export default function* rootSaga() {
  console.log('rootSaga')
  yield all([
    call(watchChangeLanguageSaga),
    call(authSaga),
    call(userSaga),
    call(patientsSaga),
    call(documentsSaga),
    call(alertsSaga),
    call(paramsSaga),
    call(importsFilesSaga),
    call(garbageSaga),
    call(cephaloSaga),
    call(contactsSaga),
    call(diagnosticSaga),
    call(visibilitySaga),
    call(watchTask),
    //insert sagas before
    call(routinePromiseWatcherSaga)
  ])
};