import React, { useMemo, useContext, useRef, useEffect, useState} from "react";

import { Avatar, Box, Typography, Button, Paper, ButtonBase, Grid, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, CircularProgress} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useDispatch, useSelector } from "react-redux";
import { loggedInUserSelector } from "../../redux/Auth/selectors";
import {  makeUserSelector, usersIsLoadingSelector} from "../../redux/Users/selectors";
import { objectKeysEqualityFn } from "../../utils/equality";
import { capitalizeInitialsWithoutDot, capitalizeName, stringToHslColor } from "../../utils/string-helpers";
import { AutoForm, AutoField } from 'uniforms-material';

import { Route, Switch, Link, useHistory, useLocation} from 'react-router-dom'

import { schema } from '../ProfileForm'

import { JSONSchemaBridge } from 'uniforms-bridge-json-schema';
import { AjvValidatorContext } from '../../utils/context/AjvContext';
import { Trans, useTranslation } from "react-i18next";


import { putUserRoutinePromiseCreator, postUserRoutinePromiseCreator, putUserSubscriptionDeclineRoutinePromiseCreator, getUserRoutinePromiseCreator} from "../../redux/Users/routines";
import { tokenSelector } from '../../redux/Auth/selectors';

import NotFound from '../404'
import CustomAvatarField from "../uniforms/CustomAvatarField";
import CustomPhoneField from "../uniforms/CustomPhoneField";
import WithUniforms from "../uniforms/WithUniforms";

import { parse as parseqs } from 'query-string'

import { redirectingAction } from '../../redux/Users/routines';
import Cookies from 'cookies-js';

const useStyle = makeStyles(theme => ({
  root: {
    flex: 1,
    overflow: 'auto',
    height: '100%',
  },
  colorAvatar: ({id = ''}) => {
    const color = stringToHslColor(id, 56, 54)
    return {
      width: theme.spacing(10),
      height: theme.spacing(10),
      fontSize: '2rem',
      ...(id ? {
        color: "#fff",
        backgroundColor: color,
      } : {}),
    } 
  },
  price: {
    color: theme.palette.secondary.main,
    ...theme.typography.h5
  },
  price2: {
    color: theme.palette.primary.main,
    //...theme.typography.h5
  },
  price_economy: {
    color: '#0e6245',
    background: '#cbf4c9',
    borderRadius: 4,
    padding: '2px 6px',
    whiteSpace: 'nowrap',
  },
  plan_canceled: {
    color: '#505769',
    background: '#E4E8ED',
    borderRadius: 4,
    padding: '2px 6px',
  },
  plan_trial: {
    color: '#1E57B5',
    background: '#D6F4F5',
    borderRadius: 4,
    padding: '2px 6px',
  },
  plan_active: {
    color: '#428124',
    background: '#DDF5C7',
    borderRadius: 4,
    padding: '2px 6px',
  },
  plan_past_due: {
    color: '#A4243F',
    background: '#FBE8F1',
    borderRadius: 4,
    padding: '2px 6px',
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  }
}))

function PaperButton ({children, selected, disabled, ...rest}) {
  
  return (
    <Box marginTop={2} width={'100%'} maxWidth={600} component={Paper} variant="outlined" overflow={'hidden'} borderColor={selected ? "secondary.main" : undefined} border={selected ? 2 : undefined} style={{opacity: disabled ? 0.5 : 1}}>
      <ButtonBase  {...rest} style={{width: '100%'}} component={rest.to ? Link : undefined} disabled={disabled || selected}>
        <Box padding={2} width={'100%'} textAlign={'left'}>
          {children}
        </Box>
      </ButtonBase>
    </Box>
  )
}

let url
if(process.env.NODE_ENV === 'development')
  url = `http://${window.location.hostname}:1337`;
else if(process.env.NODE_ENV === 'production')
  url = process.env.REACT_APP_API_PROXY

function SubscriptionPaperButton ({children, selected, disabled, price_id, returnPathCancel, returnPathSuccess, ...rest}) {
  const formRef = useRef()
  const qstoken = useSelector(tokenSelector)
  const dispatch = useDispatch()
  const handleRedirecting = () => {
    dispatch(redirectingAction())
  }
  return (
    <Box marginTop={2} width={'100%'} maxWidth={600} component={Paper} variant="outlined" overflow={'hidden'} borderColor={selected ? "secondary.main" : undefined} border={selected ? 2 : undefined} style={{opacity: disabled ? 0.5 : 1}}>
      <form action={`${url}/stripe-subscriptions?qstoken=${qstoken}`} method="POST" ref={formRef} onSubmit={handleRedirecting}>
        <input type="hidden" name="price_id" value={price_id} />
        {
          returnPathCancel && <input type="hidden" name="return_path_cancel" value={returnPathCancel} />
        }
        {
          returnPathSuccess && <input type="hidden" name="return_path_success" value={returnPathSuccess} />
        }
        <ButtonBase  {...rest} style={{width: '100%'}}  disabled={disabled || selected}  type='submit' >
          <Box padding={2} width={'100%'} textAlign={'left'}>
            {children}
          </Box>
        </ButtonBase>
      </form>
    </Box>
  )
}

function PortalPaperButton ({children, selected, disabled, ...rest}) {
  const formRef = useRef()
  const qstoken = useSelector(tokenSelector)
  const dispatch = useDispatch()
  const handleRedirecting = () => {
    dispatch(redirectingAction())
  }
  return (
    <Box marginTop={2} width={'100%'} maxWidth={600} component={Paper} variant="outlined" overflow={'hidden'} borderColor={selected ? "secondary.main" : undefined} border={selected ? 2 : undefined} style={{opacity: disabled ? 0.5 : 1}}>
      <form action={`${url}/stripe-subscriptions/portal?qstoken=${qstoken}`} method="POST" ref={formRef} onSubmit={handleRedirecting}>
        <ButtonBase  {...rest} style={{width: '100%'}}  disabled={disabled || selected}  type='submit'>
          <Box padding={2} width={'100%'} textAlign={'left'}>
            {children}
          </Box>
        </ButtonBase>
      </form>
    </Box>
  )
}

function AccountUser ({user = {}, info = {}, create}) {

  const usersIsLoading = useSelector(usersIsLoadingSelector)
  const dispatch = useDispatch()
  const history = useHistory()

  const {t} = useTranslation()
  const classes = useStyle({id: user.id})

  const [currentModel, setCurrentModel] = useState({})

  const { createValidator } = useContext(AjvValidatorContext);
  const schemaValidator = createValidator(schema);
  const bridge = new JSONSchemaBridge(schema, schemaValidator);

  const formRef = useRef();

  useEffect(() => {
    if(currentModel.updatedAt !== info.updatedAt)
      setCurrentModel({...info, email: user.email})
  }, [info.updatedAt])

  useEffect(() => {
    //if(currentModel.updatedAt !== user.updatedAt)
      setCurrentModel({...info, email: user.email})
  }, [user.updatedAt])
  
  const handleSubmit = async (values) => { 
    console.log('handleSubmit')
    let data;
    if(values.avatar && !values.avatar.id) {
      const { avatar, ...rest} = values;
      data = new FormData()
      data.append('data', JSON.stringify(rest))
      const blob = await (await fetch(avatar.url)).blob()
      const file = new File([blob], 'avatar.jpeg');
      data.append('files.avatar', file)
    }
    else if (values.avatar && typeof values.avatar.id){
      const { avatar, ...rest} = values;
      data = rest
    }
    else if (!values.avatar)
      data = {...values, avatar: null}
      
    if(info.id)
      await putUserRoutinePromiseCreator({values: data, id: info.id}, dispatch)
      //dispatch(putUserRoutine.trigger({values: data, id: info.id}))
    else
      await postUserRoutinePromiseCreator(data, dispatch)
      //dispatch(postUserRoutine.trigger(data))
    if(create)
      history.replace('/')
    else
      history.go(-1)
  }

  return (
    <div className={classes.root}>
      <AutoForm 
        schema={bridge}
        onSubmit={handleSubmit}
        showInlineError
        ref={formRef}
        model={currentModel}
      >
        <CustomAvatarField name="avatar" classes={classes}/>
        <Box padding={2} display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <Box width={'100%'} maxWidth={600} display={'flex'} component={Paper} variant="outlined" padding={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <AutoField name="firstname" label={t('form:userdata.firstname')}/>
              </Grid>
              <Grid item xs={12} md={6}>
                <AutoField name="lastname" label={t('form:userdata.lastname')}/>
              </Grid>
              <Grid item xs={12} md={6}>
                <AutoField name="dateofbirth" placeholder='' label={t('form:userdata.dateofbirth')} disableFuture className={classes.buttonTextField}/>
              </Grid>
              <Grid item xs={12} md={6}>
                <AutoField name="activity" placeholder='' label={t('form:userdata.activity.label')} options={[
                  { value: ''  },
                  { value: 'odf', label: t('form:userdata.activity.odf')},
                  { value: 'chirdent', label: t('form:userdata.activity.chirdent')},
                  { value: 'stomato', label: t('form:userdata.activity.stomato')},
                  { value: 'maxillo', label: t('form:userdata.activity.maxillo')},
                  { value: 'interne', label: t('form:userdata.activity.interne')},
                  { value: 'etudiant', label: t('form:userdata.activity.etudiant')},
                ]} />
              </Grid>
              <Grid item xs={12} md={6}>
                <AutoField name="email" label={t('form:userdata.email')} disabled/>
              </Grid>
              <Grid item xs={12} md={6}>
                <AutoField name="contact_email" label={t('form:userdata.contact_email')} />
              </Grid>
              <Grid item xs={12} md={6}>
                <AutoField name="country" placeholder='' label={t('form:userdata.country')}/>
              </Grid>
              <Grid item xs={12} md={6}>
                <WithUniforms>
                  {uniforms => (
                    <CustomPhoneField name="phone" label={t('form:userdata.phone')} defaultCountry={uniforms.model?.country?.toLowerCase()}/>
                  )}
                </WithUniforms>
              </Grid>
              <Grid item xs={12}>
                <AutoField name="adresse1" label={t('form:userdata.adresse1')}/>
              </Grid>
              <Grid item xs={12}>
                <AutoField name="adresse2" label={t('form:userdata.adresse2')}/>
              </Grid>
              <Grid item xs={12} md={6}>
                <AutoField name="code_postal" label={t('form:userdata.code_postal')}/>
              </Grid>
              <Grid item xs={12} md={6}>
                <AutoField name="ville" label={t('form:userdata.ville')}/>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </AutoForm>
      <Box padding={2} display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <Box width={'100%'} maxWidth={600} display={'flex'} >
          <Box flex={1}/>
          {
            !create && <Button to='/account' component={Link}>{t('common:button.back')}</Button>
          }
          <Button 
            color="primary" 
            variant="text" 
            disabled={usersIsLoading} 
            //type="submit" 
            onClick={() => {formRef.current.submit()}}
          >
            {t('common:button.save')}
          </Button>
        </Box>
      </Box>
    </div>
  )
}

function SubscriptionSelect ({user = {}, info = {}, create, beforeCloseModal = true}) {

  const classes = useStyle({id: user.id})
  const [next, setNext] = useState(false)
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const {t} = useTranslation()

  const qs = parseqs(location.search)

  console.log(location, qs)
  const handleNext = async () => {
    setNext(true)
  }

  const cancelNext = () => {
    setNext(false)
  }

  const handleDecline = async () => {
    Cookies.set('no-subscription-error-notification', Date.now())
    await putUserSubscriptionDeclineRoutinePromiseCreator(undefined, dispatch)
    history.push('/')
  }

  const handleClose = () => {
    history.goBack()
  }

  return (
    <>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <Box width={'100%'} maxWidth={600} display={'flex'} padding={2} >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5" align="center" gutterBottom>
                {t('account.Select your subscription')} :
              </Typography>
            </Grid>
            {
              qs.canceled &&
              <Grid item xs={12}>
                <Typography variant="body1" align="center" gutterBottom color="error">
                  {t('account.Payment has been cancelled')}
                </Typography>
              </Grid>
            }
            <Grid item xs={12} md={6}>
              <SubscriptionPaperButton 
                returnPathCancel="/account/subscription/select"
                returnPathSuccess="/account"
                price_id={process.env.REACT_APP_PLAN_1}
              >
                <Trans t={t} i18nKey="account.montly subscription" >
                  <Typography align="center" gutterBottom>
                    <span className={classes.price}>39€</span><span className={classes.price2}>/mois</span>
                  </Typography>
                  <Typography variant="body1" align="center" gutterBottom>
                    Facturation mensuelle sans engagement pour plus de liberté
                  </Typography>
                  <Typography variant="body2" align="center">
                    <Box color="secondary.main" component="span">30 jours d'essai gratuit</Box> lors de votre première souscription.
                  </Typography>
                </Trans>
              </SubscriptionPaperButton>
            </Grid>
            <Grid item xs={12} md={6} >
              <SubscriptionPaperButton 
                returnPathCancel="/account/subscription/select"
                returnPathSuccess="/account"
                price_id={process.env.REACT_APP_PLAN_2}
              >
                <Trans t={t} i18nKey="account.yearly subscription" >
                  <Typography align="center" gutterBottom>
                    <span className={classes.price}>324€</span><span className={classes.price2}>/an</span>
                  </Typography>
                  <Typography variant="body1" align="center" gutterBottom >
                    Économisez avec une facturation annuelle <span className={classes.price_economy}>Réduction de 144 €</span>
                  </Typography>
                  <Typography variant="body2" align="center" >
                    <Box color="secondary.main" component="span">30 jours d'essai gratuit</Box> lors de votre première souscription.
                  </Typography>
                </Trans>
              </SubscriptionPaperButton>
            </Grid>
          </Grid>
        </Box>
        {
          create &&
          <Box width={'100%'} maxWidth={600} display={'flex'} padding={2}>
            <Box flex={1}/>
            {
              // <Button to='/' component={Link}>Passer</Button>
              <Button onClick={beforeCloseModal ? handleNext : handleClose} >{t('common:button.next')}</Button>
            }
          </Box>
        }
      </Box>
      <Dialog open={next} >
        <Trans t={t} i18nKey="account.decline subscription dialog" >
          <DialogTitle id="alert-dialog-title">Continuer sans abonnement?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Sans abonnement vous ne pourrez pas créer de dossier patient ou ajouter de photos. Souhaitez vous continuer sans abonnement?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={cancelNext} color="error">
              Annuler
            </Button>
            <Button color="primary" onClick={handleDecline}>
              Continuer
            </Button>
          </DialogActions>
        </Trans>
      </Dialog>
    </>
  )
}

const useSubscriptionThanksStyles = makeStyles(theme => ({
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  }
}))

function SubscriptionThanks ({user = {}, info = {}}) {
  const classes = useSubscriptionThanksStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const {t} = useTranslation()

  const handleDecline = async () => {
    await putUserSubscriptionDeclineRoutinePromiseCreator(undefined, dispatch)
    history.push('/')
  }
  const {gift} = user
  return (
    <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
      <Box width={'100%'} maxWidth={600} display={'flex'} >
        <Grid container spacing={2}>
          {
            gift.logo && 
            <Grid item xs={12}>
              <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <Avatar className={classes.avatar} src={gift.logo.url}/>
              </Box>
            </Grid>
          }
          <Grid item xs={12}>
            <Typography variant="h5" align="center" gutterBottom>
              {t('account.Your subscription is offered by')} : {gift.from} 
            </Typography>
            <Typography variant="body1" align="center" gutterBottom>
              {t('account.Free period until')} {t('common:interface.date_LL', {date: new Date(gift.end)})}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box width={'100%'} maxWidth={600} display={'flex'} padding={2}>
        <Box flex={1}/>
        <Button onClick={handleDecline} >{t('account.Thanks')}</Button>
      </Box>
    </Box>
  )
}

function AccountRoot ({user, info}) {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const qstoken = useSelector(tokenSelector)

  const classes = useStyle({id: user.id})

  const initials = useMemo(() => info && `${capitalizeInitialsWithoutDot(info.firstname)}${capitalizeInitialsWithoutDot(info.lastname)}`, [info.lastname, info.firstname])
  const userFullname = useMemo(() => info &&  `${capitalizeName(info.firstname)} ${capitalizeName(info.lastname)}`, [info.lastname, info.firstname])

  const customer = user.stripe_customer_id
  const subscription = user.stripe_subscription?.subscription_data
  const gift = user.gift

  const location = useLocation()
  const qs = parseqs(location.search)

  const [successLoading, setSuccessLoading] = useState(false)
  useEffect(() => {

    async function handleSuccessDelay() {
      setSuccessLoading(true)
      await new Promise(success => setTimeout(success ,5000))
      const user = await getUserRoutinePromiseCreator(undefined, dispatch)
      console.log(user)
      setSuccessLoading(false)
    }

    if(qs.success &&!subscription){
      handleSuccessDelay()
    }
  }, [])
  

  // // useEffect(() => {
  //   if(qs.redirect === 'update_payment_method') {
  //     dispatch(redirectingAction({
  //       url: `${apiURL}/stripe-subscriptions/portal?qstoken=${qstoken}`,
  //       params: [
  //         {name: "route", value: "update_payment_method"}
  //       ]
  //     }))
  //   }
  //   else if(qs.redirect === 'last_invoice')
  //     dispatch(redirectingAction({
  //       url: `${apiURL}/stripe-subscriptions/invoice?qstoken=${qstoken}`,
  //       //target: '_blank',
  //     }))
  //     //handlePostRedirect(`${apiURL}/stripe-subscriptions/invoice?qstoken=${qstoken}`)
  // }, [])

  const date = ""

  return (
    <div className={classes.root}>
      <Box padding={2} display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <Box padding={2} >
          {
            info?.avatar?.url ? 
              <Avatar className={classes.colorAvatar} src={info.avatar.url} /> :
                initials ? 
                  <Avatar className={classes.colorAvatar}>{initials}</Avatar> :
                    <Avatar className={classes.colorAvatar}/>
          }
        </Box>
        <Box height={40} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
          <Typography variant="h5">
            {userFullname} 
          </Typography>
        </Box>
      </Box>
      <Box padding={2} display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <PaperButton to="/account/user">
          <Typography variant="h5">
            {t('account.Personal information')}
          </Typography>
          <Typography variant="body2">
            {t('account.Edit your personal information that is used by CephaloMax')}
          </Typography>
        </PaperButton>
        {
          subscription && 
          <PaperButton selected>
            <Typography align="left" variant="h5" gutterBottom>
              {t("account.Current subscription")} : {customer && <><span className={classes.price}>{subscription.plan.amount/100}€</span><span className={classes.price2}>/{subscription.plan.interval === "month" ? t('account.month') : t('account.year')}</span></>}
            </Typography>
            {/* <Typography align="center" gutterBottom>
              <span className={classes.price}>{subscription.plan.amount/100}€</span><span className={classes.price2}>/{subscription.plan.interval === "month" ? 'mois' : 'an'}</span>
            </Typography> */}
            {
              subscription.status === 'trialing' && !subscription.cancel_at_period_end &&
              <Trans 
                t={t} 
                i18nKey="account.subscription status trialing"
                values={{ date: t('common:interface.date_LL', {date: new Date(subscription.trial_end*1000)})}}
              >
                <Typography variant="body1" align="left" gutterBottom>
                  <span className={classes.plan_trial}>Période d'essai</span>
                </Typography>
                <Typography variant="body1" align="left" gutterBottom>
                  Période d'éssai gratuite jusqu'au {{date}}
                </Typography>
              </Trans>
            }
            {
              subscription.status === 'trialing' && subscription.cancel_at_period_end &&
              <Trans 
                t={t} 
                i18nKey="account.subscription status trialing canceled"
                values={{ date: t('common:interface.date_LL', {date: new Date(subscription.trial_end*1000)})}}
              >
                <Typography variant="body1" align="left" gutterBottom>
                  <span className={classes.plan_canceled}>Annulé</span>
                </Typography>
                <Typography variant="body1" align="left" gutterBottom>
                  À la fin de votre période d'essai gratuite le {{date}}, ce plan ne sera plus disponible.
                </Typography>
              </Trans>
            }
            {
              subscription.status === 'active' && !subscription.cancel_at_period_end &&
              <Trans 
                t={t} 
                i18nKey="account.subscription status active"
                values={{ date: t('common:interface.date_LL', {date: new Date(subscription.current_period_end*1000)})}}
              >
                <Typography variant="body1" align="left" gutterBottom>
                  <span className={classes.plan_active}>Actif</span>
                </Typography>
                <Typography variant="body1" align="left" gutterBottom>
                  Votre abonnement sera renouvelé le {{date}}
                </Typography>
              </Trans>
            }
            {
              subscription.status === 'active' && subscription.cancel_at_period_end &&
              <Trans 
                t={t} 
                i18nKey="account.subscription status active canceled" 
                values={{ date: t('common:interface.date_LL', {date: new Date(subscription.current_period_end*1000)})}}
              >
                <Typography variant="body1" align="left" gutterBottom>
                  <span className={classes.plan_canceled}>Annulé</span>
                </Typography>
                <Typography variant="body1" align="left" gutterBottom>
                  Votre abonnement sera annulé le {{date}}
                </Typography>
              </Trans>
            }
            {
              subscription.status === 'past_due' &&
              <Trans 
                t={t} 
                i18nKey="account.subscription status past_due"
                values={{ date: t('common:interface.date_LL', {date: new Date(subscription.current_period_end*1000)})}}
              >
                <Typography variant="body1" align="left" gutterBottom>
                  <span className={classes.plan_past_due}>En retard</span>
                </Typography>
                <Typography variant="body1" align="left" gutterBottom color="error">
                  Votre moyen de paiement a échoué. Mettez à jour votre moyen de paiement par défaut.
                </Typography>
              </Trans>
            }
            {
              subscription.status === 'canceled' &&
              <Trans 
                t={t} 
                i18nKey="account.subscription status canceled" 
                values={{ date: t('common:interface.date_LL', {date: new Date(subscription.current_period_end*1000)})}}
              >
                <Typography variant="body1" align="center" gutterBottom>
                  <span className={classes.plan_canceled}>Annulé</span>
                </Typography>
                <Typography variant="body1" align="center" gutterBottom>
                  Votre abonnement sera annulé le {{date}}
                </Typography>
              </Trans>
             
            }
          </PaperButton>
        }
        {
          !!gift && 
          <PaperButton selected>
            <Grid container spacing={2}>
              {
                gift.logo && 
                <Grid item xs={12}>
                  <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                    <Avatar className={classes.avatar} src={gift.logo.url}/>
                  </Box>
                </Grid>
              }
              <Grid item xs={12}>
                <Typography variant="h5" align="center" gutterBottom>
                  {t('account.Your subscription is offered by')} : {gift.from} 
                </Typography>
                <Typography variant="body1" align="center" gutterBottom>
                  {t('account.Free period until')} {t('common:interface.date_LL', {date: new Date(gift.end)})}
                </Typography>
              </Grid>
            </Grid>
          </PaperButton>
        }
        {
          !subscription && !successLoading && !gift &&
          <Box width={'100%'} maxWidth={600} display={'flex'} flexDirection="column">
            {
              qs.canceled &&
              <Box paddingTop={2}>
                <Typography variant="body1" align="center" color="error">
                  {t('account.Payment has been cancelled')}
                </Typography>
              </Box>
            }
            <Grid container spacing={2}>
              {/* {
                qs.canceled &&
                <Grid item xs={12}>
                  <Typography variant="body1" align="center" gutterBottom color="error">
                    Le paiement a été annulé 
                  </Typography>
                </Grid>
              } */}
              <Grid item xs={12} md={6}>
                <SubscriptionPaperButton 
                  price_id={process.env.REACT_APP_PLAN_1}
                  returnPathCancel="/account"
                  returnPathSuccess="/account"
                >
                  <Trans t={t} i18nKey="account.montly subscription" >
                    <Typography align="center" gutterBottom>
                      <span className={classes.price}>39€</span><span className={classes.price2}>/mois</span>
                    </Typography>
                    <Typography variant="body1" align="center" gutterBottom>
                      Facturation mensuelle sans engagement pour plus de liberté
                    </Typography>
                    <Typography variant="body2" align="center">
                      <Box color="secondary.main" component="span">30 jours d'essai gratuit</Box> lors de votre première souscription.
                    </Typography>
                  </Trans>
                </SubscriptionPaperButton>
              </Grid>
              <Grid item xs={12} md={6} >
                <SubscriptionPaperButton 
                  price_id={process.env.REACT_APP_PLAN_2}
                  returnPathCancel="/account"
                  returnPathSuccess="/account"
                >
                  <Trans t={t} i18nKey="account.yearly subscription" >
                    <Typography align="center" gutterBottom>
                      <span className={classes.price}>324€</span><span className={classes.price2}>/an</span>
                    </Typography>
                    <Typography variant="body1" align="center" gutterBottom >
                      Économisez avec une facturation annuelle <span className={classes.price_economy}>Réduction de 144 €</span>
                    </Typography>
                    <Typography variant="body2" align="center" >
                      <Box color="secondary.main" component="span">30 jours d'essai gratuit</Box> lors de votre première souscription.
                    </Typography>
                  </Trans>
                </SubscriptionPaperButton>
              </Grid>
            </Grid>
          </Box>
        }
        {
          !subscription && successLoading && 
          <Box width={'100%'} maxWidth={600} display={'flex'} flexDirection="column" component={Paper} variant="outlined" marginTop={2}>
            
             {
              qs.success &&
              <>
                <Box paddingTop={2}>
                  <Typography variant="body1" align="center" gutterBottom >
                    {t('account.Payment pending')}
                  </Typography>
                </Box>
                <Box padding={2} textAlign="center">
                  <CircularProgress />
                </Box>
              </>
            }
          </Box>
        }
        {
          customer &&
          <PortalPaperButton>
            <Typography variant="h5">
              {t('account.Subscription and Invoices')}
            </Typography>
            <Typography variant="body2">
              {t('account.Change or cancel your subscription')} {t('account.Add a payment method')} {t('account.View and download your invoices')} 
            </Typography>
          </PortalPaperButton>
        }
        
        {/* <PaperButton>
          <Typography variant="h5">
            Factures
          </Typography>
          <Typography variant="body2">
            Visualiser et télécharger vos factures
          </Typography>
        </PaperButton> */}
        {/* <PaperButton to="/cgv/latest">
          <Typography variant="h5">
            {t('account.Conditions générales de vente')}
          </Typography>
          <Typography variant="body2">
            {t('account.Acceder à l\'historique de nos conditions générales de vente')}
          </Typography>
        </PaperButton> */}
      </Box>
      <Box padding={2} display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <Box width={'100%'} maxWidth={600} display={'flex'} >
          <Box flex={1}/>
          {
            <Button to='/' component={Link}>{t('common:button.back')}</Button>
          }
        </Box>
      </Box>
    </div>
  )
}

function Account () {
  const userSelector = useMemo(makeUserSelector, [])  
  //const infoSelector = useMemo(makeInfoSelector, [])
  

  const loggedInUser = useSelector(loggedInUserSelector)

  const user = useSelector(state => userSelector(state, loggedInUser), objectKeysEqualityFn(['id', 'updatedAt']))
  //const info = useSelector(state => infoSelector(state, user.infos), objectKeysEqualityFn(['id', 'updatedAt']))
  const info = user.infos
  return (
    <Switch>
      <Route exact path="/account" >
        <AccountRoot user={user} info={info} />
      </Route>
      <Route exact path="/account/user" >
        <AccountUser user={user} info={info} />
      </Route>
      <Route exact path="/account/user/create" >
        <AccountUser user={user} info={info} create/>
      </Route>
      <Route exact path="/account/subscription/select" >
        <SubscriptionSelect user={user} info={info} create/>
      </Route>
      <Route exact path="/account/subscription/select/modal" >
        <SubscriptionSelect user={user} info={info} create beforeCloseModal={false}/>
      </Route>
      <Route exact path="/account/subscription/thanks" >
        <SubscriptionThanks user={user} info={info} />
      </Route>
      <Route component={NotFound} />
    </Switch>
  )
}

export default Account