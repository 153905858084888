import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'

import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIcon from '@material-ui/icons/Phone';

import { AutoField, DateField, HiddenField} from 'uniforms-material'
import { BaseField } from 'uniforms';

import CustomSelectField from '../../uniforms/CustomSelectField'
import CustomDatePickerField from '../../uniforms/CustomDatePickerField'
import CustomAutocompleteField from '../../uniforms/CustomAutocompleteField'

import { alertRoutine } from '../../../redux/Alerts/routines'
import schema from './patientFormSchema'

import { history } from '../../../utils/configureStore'
import theme from '../../../utils/theme'
import { store, i18n } from '../../../index'
import { useSelector, useDispatch } from 'react-redux'

import { useTranslation } from 'react-i18next';

import PatientAvatar from '../../PatientAvatar'

import { capitalizeName } from '../../../utils/string-helpers.js' 

import { isMobile } from "react-device-detect";

import { postPatientRoutinePromiseCreator, putPatientRoutinePromiseCreator, getTagsPatientsRoutine, deletePatientRoutinePromiseCreator, tabsPatientRoutine} from '../../../redux/Patients/routines'
import { tagsSelector, tagsIsLoadingSelector } from '../../../redux/Patients/selectors';
import { TextField, Typography } from '@material-ui/core';

const randomStr = () => Math.random().toString(36).substr(2)

const mapName = {
  firstname: randomStr(),
  lastname: randomStr(),
  dateofbirth: randomStr(),
  gender: randomStr(),
  tags: randomStr(),
  email: randomStr(),
  phone: randomStr(),
  adresse1: randomStr(),
  adresse2: randomStr(),
  postcode: randomStr(),
  city: randomStr(),
  collect_accept: randomStr(),
  external_ids: randomStr(),
}

const mapNameReverse = {}
Object.entries(mapName).forEach(([key, value]) => mapNameReverse[value] = key)

const getRandomizedModel = (patient) => {
  const model = {}
  Object.entries(patient || {collect_accept :true}).forEach(([key, value]) => {
    if(mapName[key])
      model[mapName[key]] = value
  })
  return model
}

const getUnrandomizedValues = (values) => {
  const tmpValues = {}
  Object.entries(values).forEach(([key, value]) => {
    if(mapNameReverse[key])
      tmpValues[mapNameReverse[key]] = value
  })
  return tmpValues
}

const useStyles = makeStyles( theme => ({
  avatarRoot: {
    display: 'flex',
    justifyContent: 'center',
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  checkboxContainer: {
    "& .MuiFormControlLabel-label": {
      fontSize: theme.typography.body2.fontSize,
    }
  },
  buttonTextField: {
    "& .MuiInputAdornment-root button": {
      marginRight: -12
    }
  }
}));

const Form = (props, { uniforms }) => {
  
  const classes = useStyles();
  const options = useSelector(tagsSelector);
  const loading = useSelector(tagsIsLoadingSelector);
  const dispatch = useDispatch()
  
  const { t } = useTranslation();

  React.useEffect(() => {
    dispatch(getTagsPatientsRoutine.trigger())
  }, []);

  const getOptionLabel = React.useCallback((option) => {
    if(!option)
      return null
    if(typeof option === 'string')
      return option              
    if(typeof option === 'object') {
      if(Array.isArray(option))
        return option.map(opt => typeof opt === 'string' ? opt : opt.tag)
      return option.tag
    }
    return null
  },[])

  const InputProps = React.useMemo(() => ({
    endAdornment: (
      <React.Fragment>
        {loading ? <CircularProgress color="inherit" size={20} /> : null}
      </React.Fragment>
    ),
  }), [loading])

  const avatarText = `${uniforms.model[mapName.firstname]?.trim() ? uniforms.model[mapName.firstname].trim()[0].toUpperCase() : ''}${uniforms.model[mapName.lastname]?.trim() ? uniforms.model[mapName.lastname].trim()[0].toUpperCase() : ''}` || null

  return (
    <div style={{display: 'flex', position: 'relative'}}>
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.avatarRoot}>
          {/*<Avatar className={classes.large}>{avatarText}</Avatar>*/}
          <PatientAvatar className={classes.large} patient={props.patient} avatarText={avatarText}/> 
        </Grid>
        <Grid item xs={12} md={6}>
          <AutoField name={mapName.firstname} label={t('form:patient.firstname')} />
        </Grid>
        <Grid item xs={12} md={6}>
          <AutoField name={mapName.lastname} label={t('form:patient.lastname')} />
        </Grid>
        <Grid item xs={12} md={6}>
          {
            isMobile && false ?
              <AutoField name={mapName.dateofbirth} placeholder='' label={t('form:patient.dateofbirth')} type="date" component={DateField}/> :
                <AutoField className={classes.buttonTextField} name={mapName.dateofbirth} placeholder='' label={t('form:patient.dateofbirth')} disableFuture component={CustomDatePickerField}/>
          }
        </Grid>
        <Grid item xs={12} md={6}>
          <AutoField 
            name={mapName.gender} 
            placeholder='' 
            label={t('form:patient.gender.label')} 
            options={[
              { value: ''  },
              { value: 'male', label: t('form:patient.gender.male')},
              { value: 'female', label: t('form:patient.gender.female')},
            ]}
            component={CustomSelectField}
          />
        </Grid>
        <Grid item xs={12}>
          <AutoField 
            name={mapName.tags}
            label={t('form:patient.tags.label')}
            multiple
            InputProps={InputProps}
            autoComplete
            includeInputInList
            options={options}
            getOptionLabel={getOptionLabel}
            ChipProps={{
              variant: "outlined",
              size: "small",
            }}
            freeSolo
            disableClearable
            component={CustomAutocompleteField}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AutoField 
            className={classes.buttonTextField}
            name={mapName.email} 
            label={t('form:patient.email')} 
            required={false}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {window.location = `mailto:${uniforms.model[mapName.email]}`;}}
                  >
                    <MailOutlineIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AutoField
            className={classes.buttonTextField}
            name={mapName.phone} 
            label={t('form:patient.phone')} 
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {window.location = `tel:${uniforms.model[mapName.phone]}`;}}
                  >
                    <PhoneIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <AutoField name={mapName.adresse1} label={t('form:patient.adresse1')} />
        </Grid>
        <Grid item xs={12}>
          <AutoField name={mapName.adresse2} label={t('form:patient.adresse2')} />
        </Grid>
        <Grid item xs={12} md={6}>
          <AutoField name={mapName.postcode} label={t('form:patient.postcode')} />
        </Grid>
        <Grid item xs={12} md={6}>
          <AutoField name={mapName.city} label={t('form:patient.city')} />
        </Grid>
        <Grid item xs={12} className={classes.checkboxContainer}>
          <AutoField name={mapName.collect_accept} placeholder='' label={t('form:patient.collect_accept')} />
        </Grid>
        <AutoField name={mapName.external_ids} component={HiddenField} noDOM/>
      </Grid>
    </div>
  )
}

Form.contextTypes = BaseField.contextTypes

const errorActionCreator = () => (
  alertRoutine.show({
    type: 'snack',
    title: i18n.t('common:interface.an error occured'),
    severity: 'error'
  })
)

export const patientFormActionCreator = (patient, parentAlertId) => {

  if(patient) {
    patient.firstname = capitalizeName(patient.firstname)
    patient.lastname = patient.lastname.toUpperCase()
  }

  return (
    alertRoutine.show({
      type: 'form',
      maxWidth: 'sm',
      parent: parentAlertId,
      title: i18n.t(patient && patient.id ? 'form:patient.title.edit' : 'form:patient.title.add'),
      schema: schema(mapName),
      model: getRandomizedModel(patient),
      content: <Form patient={patient} />,
      showInlineError: true,
      fullScreenSmallScreen: true,
      scroll: 'body',
      secondaryActions: patient?.id && [
        {
          title: i18n.t('common:button.delete'),
          color: theme().palette.error.main,
          action: (parentAlertId) => store.dispatch(deletePatientFormActionCreator(patient, parentAlertId))
        }
      ],
      action: async (values, progress) => {      
        
        try {
          progress.handleTrigger()
          
          values = getUnrandomizedValues(values)

          values.firstname = capitalizeName(values.firstname)
          values.lastname = values.lastname.toUpperCase()

          let promise;

          if(patient && patient.id) {
            promise = putPatientRoutinePromiseCreator({values, id: patient.id}, store.dispatch)
          }
          else {
            promise = postPatientRoutinePromiseCreator({ values }, store.dispatch)
          }

          const  { result }  = await promise; 
          
          if(!patient || !patient.id)
            history.push(`/patient/${result}`)
          
          return true
        }
        catch (e) {
          //afficher l'erreur
          store.dispatch(errorActionCreator())
          return false
        }
        finally {
          progress.handleFulfill()
        }
     
      }
    })
  )
}

const DeletePatientForm = ({setName, lastname}) => {
  
  const {t} = useTranslation()
  const [value, setValue] = React.useState('')
  const [error, setError] = React.useState(false)

  const handleChange = (e) => {
    setError(e.target.value !== lastname)
    setValue(e.target.value)
  }

  React.useEffect(() => {
    setName(value)
  }, [value])

  return (
    <div>
      <Typography gutterBottom >{t('patient.delete patient detail')}</Typography>
      <Typography>{t('patient.delete type patient name')} : <b>{lastname}</b></Typography>
      <TextField value={value} onChange={handleChange} fullWidth dense error={error} placeholder={lastname} />
    </div>
  )
}

export const deletePatientFormActionCreator = (patient, parentAlertId) => {

  const confirm = {
    lastname: ''
  }
  

  return alertRoutine.show({
    type: 'dialog',
    maxWidth: 'xs',
    parent: parentAlertId,
    title: i18n.t('patient.delete patient'),
    content: <DeletePatientForm setName={(value) => confirm.lastname = value} lastname={patient.lastname}/>,
    action: async (values) => {
      if(confirm.lastname !== patient.lastname)
        return false

      try {
        
        await deletePatientRoutinePromiseCreator({
          id: patient.id,
        }, store.dispatch)   
        history.push(`/`)
        //close tab  
        store.dispatch(tabsPatientRoutine.close(patient.id));
        return true
      }
      catch (e) {
        //afficher l'erreur
        store.dispatch(errorActionCreator())
        return false
      }
      finally {
      }         
    }
  })
}