import { combineReducers } from 'redux'
import { handleActions, combineActions } from 'redux-actions'

import { importsFilesRoutine } from './routines'

import {findIndex, omitBy, omit, } from 'lodash'


const initialState = {
  candidateFiles: {},
  chosenFiles: {},
  uploads: {},
  updateDocuments: {},
  loading: {},
}

const loadingReducer = handleActions({
  [combineActions(importsFilesRoutine.INCREASE_LOADING)]: (state, action) => {
    return {
      ...state,
      [action.payload.folderId]: state[action.payload.folderId] ? state[action.payload.folderId] + 1 : 1
    }
  },
  [combineActions(importsFilesRoutine.DECREASE_LOADING)]: (state, action) => {
    return {
      ...state,
      [action.payload.folderId]: state[action.payload.folderId] ? state[action.payload.folderId] - 1 : 0
    }
  },
},
initialState.loading
)

const updateDocumentsReducer = handleActions({
  [combineActions(importsFilesRoutine.UPDATE_DOCUMENT)]: (state, action) => {
    return {
      ...state,
      [action.payload.folderId]: {
        ...(state[action.payload.folderId] || {}),
        [action.payload.photoId]: {
          ...((state[action.payload.folderId] || {})[action.payload.photoId] || {}),
          ...action.payload.document
        }
      }
    }
  },
  [combineActions(importsFilesRoutine.REMOVE_UPDATE_DOCUMENT)]: (state, action) => {
    return {
      ...state,
      [action.payload.folderId]: omit(state[action.payload.folderId] || {}, action.payload.photoId)
    }
  },
},
initialState.updateDocuments
)

const uploadsReducer = handleActions({
  [importsFilesRoutine.ADD_UPLOAD]: (state, action) => {
    return {
      ...state,
      [action.payload.id]: {
        progress: 0,
        success: false,
        error: null,
        retry: 0,
        request: action.payload.request,
        title: action.payload.title,
        url: action.payload.url,
      }
    }
  },
  [importsFilesRoutine.PROGRESS_UPLOAD]: (state, action) => {
    return {
      ...state,
      [action.payload.id]: {
        ...state[action.payload.id],
        progress: action.payload.progress,
      }
    }
  },
  [importsFilesRoutine.SUCCESS_UPLOAD]: (state, action) => {
    return {
      ...state,
      [action.payload.id]: {
        ...state[action.payload.id],
        success: true,
      }
    }
  },
  [importsFilesRoutine.ERROR_UPLOAD]: (state, action) => {
    return {
      ...state,
      [action.payload.id]: {
        ...state[action.payload.id],
        error: action.payload.error,
        retry: state[action.payload.id].retry + 1
      }
    }
  },
  [importsFilesRoutine.REMOVE_UPLOAD]: (state, action) => {
   const { filter } = action.payload
   return omitBy(state, (value) => {
      if(filter === 'ENDED')
        return value.success
      else if (filter === 'ACTIVE')
        return !value.error && !value.success
      else if (filter === 'ERROR')
        return value.error
    })
  }
}, 
initialState.uploads
)

const candidateFilesReducer = handleActions({
  
  [combineActions(importsFilesRoutine.ADD_CANDIDATES)]: (state, action) => {
    const newFiles = state[action.payload.folderId] ? [...state[action.payload.folderId]] : [];

    action.payload.candidates.forEach((file, i) => {
              
      if(findIndex(state[action.payload.folderId], {id: file.name}) === -1)
        newFiles.push({id: file.name})
      
    })

    return {
      ...state, 
      [action.payload.folderId]: newFiles
    }
  },

  [combineActions(importsFilesRoutine.ADD_CANDIDATE)]: (state, action) => {
    const newFiles = state[action.payload.folderId] ? [...state[action.payload.folderId]] : [];
    
              
    if(findIndex(state[action.payload.folderId], {id: action.payload.candidate.id}) === -1)
      newFiles.push(action.payload.candidate)
      
    return {
      ...state, 
      [action.payload.folderId]: newFiles
    }
  },

  [combineActions(importsFilesRoutine.REMOVE_CANDIDATES)]: (state, action) => {
    if(!state[action.payload.folderId])
      return state

    const notSelected = state[action.payload.folderId].filter(file => !file.selected)

    return {
      ...state, 
      [action.payload.folderId]: notSelected.length === state[action.payload.folderId].length ? [] : state[action.payload.folderId].filter(file => !file.selected)
    }
  
  },

  [combineActions(importsFilesRoutine.NO_SELECTION_CANDIDATES)]: (state, action) => {
    if(!state[action.payload.folderId])
      return state

    return {
      ...state, 
      [action.payload.folderId]: state[action.payload.folderId].map((file, i) => {
        return {...file, selected: false, focus: false, anchor: false}
      }) 
    }
  },

  [combineActions(importsFilesRoutine.ALL_SELECTION_CANDIDATES)]: (state, action) => {
    if(!state[action.payload.folderId])
      return state
    
    return {
      ...state, 
      [action.payload.folderId]: state[action.payload.folderId].map((file, i) => {
        return {...file, selected: true, focus: false, anchor: false}
      })
    }
  },
  
  [combineActions(importsFilesRoutine.SHIFT_SELECTION_CANDIDATES)]: (state, action) => {
    
    const index = findIndex(state[action.payload.folderId], {id: action.payload.selected})
    let oldAnchor = findIndex(state[action.payload.folderId], {anchor: true}) 
    let oldFocus = findIndex(state[action.payload.folderId], {focus: true})  
  
    oldAnchor = oldAnchor === -1 ? 0 : oldAnchor
    oldFocus = oldFocus === -1 ? 0 : oldFocus

    //on sélectionne entre début et click
    let start = Math.min(oldAnchor, oldFocus)
    let end = Math.max(oldAnchor, oldFocus)
    
    // remove between anchor and focus
    const newFiles = state[action.payload.folderId].map((file, i) => {
      return {...file, selected: (i >= start && i <= end) ? false : file.selected, focus: index === i}
    })

    const newFocus = index

    start = Math.min(oldAnchor, newFocus)
    end = Math.max(oldAnchor, newFocus)
    
    return {
      ...state,
      [action.payload.folderId]: newFiles.map((file, i) => {
        return {...file, selected: (i >= start && i <= end) ? true : file.selected}
      })
    }

  },

  [combineActions(importsFilesRoutine.CTRL_SELECTION_CANDIDATES)]: (state, action) => {
    
    const index = findIndex(state[action.payload.folderId], {id: action.payload.selected})
    
    let anchor = index
    let focus = index

    if(state[action.payload.folderId][index].selected) {
      let isFound
      // search forwards
      const max = state.length
      
      for (let i = index + 1; i < max; i++) {
        if (state[action.payload.folderId][i].selected) {
          anchor = i
          focus = i
          isFound = true
          break
        }
      }

      // search backwards
      if (!isFound) {
        for (let i = index - 1; i > -1; i--) {
          if (state[action.payload.folderId][i].selected) {
            anchor = i
            focus = i
            isFound = true
            break
          }
        }
      }

      // nothing selected
      if (!isFound) {
        anchor = 0
        focus = 0
      }
    }
      

    return {
      ...state,
      [action.payload.folderId]: state[action.payload.folderId].map((file, i) => {
        return {...file, selected: index === i ? !file.selected : file.selected, anchor: anchor === i, focus: focus === i }
      })
    }
  },

  [combineActions(importsFilesRoutine.SELECTION_CANDIDATES)]: (state, action) => {
    
    const index = findIndex(state[action.payload.folderId], {id: action.payload.selected})
    
    return {
      ...state,
      [action.payload.folderId]: state[action.payload.folderId].map((file, i) => {
        return {...file, selected: index === i, anchor: index === i, focus: index === i}
      })
    }
  },

  [combineActions(importsFilesRoutine.SET_CANDIDATES)]: (state, action) => {
    return {
      ...state, 
      [action.payload.folderId]: action.payload.candidates
    }
  },

},
initialState.candidateFiles
);

const chosenFilesReducer = handleActions({
  
  [combineActions(importsFilesRoutine.ADD_CHOSENS)]: (state, action) => {
    
    return {
      ...state,
      [action.payload.folderId]: {
        ...state[action.payload.folderId],
        ...action.payload.chosens
      }
    }
  },

  [combineActions(importsFilesRoutine.ADD_CHOSEN)]: (state, action) => {
    let chosen;
    
    if(!action.payload.chosen.id){
      chosen = {id: action.payload.chosen.name}
    }
    else {
      chosen = action.payload.chosen
    }
    

    return {
      ...state,
      [action.payload.folderId]: {
        ...state[action.payload.folderId],
        [action.payload.photoId]: chosen
      }
    }
  },

  [combineActions(importsFilesRoutine.REMOVE_CHOSEN)]: (state, action) => {
    return {
      ...state,
      [action.payload.folderId]: {
        ...state[action.payload.folderId],
        [action.payload.photoId]: null
      }
    }
  },

  [combineActions(importsFilesRoutine.UPDATE_CHOSEN)]: (state, action) => {
    return {
      ...state,
      [action.payload.folderId]: {
        ...state[action.payload.folderId],
        [action.payload.photoId]: {
          ...state[action.payload.folderId][action.payload.photoId],
          ...action.payload.chosen
        }
      }
    }
  },

},
initialState.chosenFiles
);

export const importsFilesReducer = combineReducers({ 
  candidateFiles: candidateFilesReducer, 
  chosenFiles: chosenFilesReducer,
  uploads: uploadsReducer,
  updateDocuments: updateDocumentsReducer,
  loading: loadingReducer,
})