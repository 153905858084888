import { combineReducers } from 'redux';
import { handleActions, combineActions } from 'redux-actions';
import { getTaskRoutine, postTaskRoutine, putTaskRoutine, deleteTaskRoutine, getTasksRoutine, searchTasksRoutine, countActiveTasksRoutine, countEndedTasksRoutine, countTodayTasksRoutine } from './routines';

const initialState = {
  tasks: {
    result: [],
    error: null,
    loading: false,
    searchCount: 0,
    todayCount: 0,
    activeCount: 0,
    endedCount: 0,
  }
}

export const subTaskReducer = handleActions({

  [combineActions(getTaskRoutine.TRIGGER, getTasksRoutine.TRIGGER, postTaskRoutine.TRIGGER, putTaskRoutine.TRIGGER, deleteTaskRoutine.TRIGGER, searchTasksRoutine.TRIGGER)]: (state, action) => ({
    ...state,
    loading: true
  }),

  [combineActions(getTaskRoutine.SUCCESS, postTaskRoutine.SUCCESS, putTaskRoutine.SUCCESS)]: (state, action) => ({
    ...state,
  }),

  [searchTasksRoutine.SUCCESS]: (state, action) => ({
    ...state,
    result: action.payload.result,
    searchCount: action.payload.searchCount
  }),

  [countActiveTasksRoutine.SUCCESS]: (state, action) => ({
    ...state,
    activeCount: action.payload
  }),

  [countEndedTasksRoutine.SUCCESS]: (state, action) => ({
    ...state,
    endedCount: action.payload
  }),

  [countTodayTasksRoutine.SUCCESS]: (state, action) => ({
    ...state,
    todayCount: action.payload
  }),

  [getTasksRoutine.SUCCESS] : (state, action) => ({
    ...state,
    result: action.payload.result
  }),

  [deleteTaskRoutine.SUCCESS] : (state, action) => ({
    ...state
  }),

  [combineActions(getTaskRoutine.FAILURE, getTasksRoutine.FAILURE, postTaskRoutine.FAILURE, putTaskRoutine.FAILURE, deleteTaskRoutine.FAILURE, searchTasksRoutine.FAILURE)]: (state, action) => ({
    ...state,
    error: action.payload
  }),

  [combineActions(getTaskRoutine.FULFILL, getTasksRoutine.FULFILL, postTaskRoutine.FULFILL, putTaskRoutine.FULFILL, deleteTaskRoutine.FULFILL, searchTasksRoutine.FULFILL)]: (state, action) => ({
    ...state,
    loading: false
  }),
},
initialState.tasks,
);

export const tasksReducer = combineReducers({
  tasks: subTaskReducer,
})