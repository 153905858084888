import { alertRoutine } from "../../../redux/Alerts/routines"
import { history } from '../../../utils/configureStore'
import { store, i18n } from '../../../index'


export const paymentNeededAlertActionCreator = () => {

  return alertRoutine.show({
    id: 'payment-needed',
    title: i18n.t("account.subscription alert title"),
    type: 'dialog',
    maxWidth: 'xs',
    content: i18n.t("account.subscription alert detail"),
    action: () => { 
      history.push(`/account`)
      store.dispatch(alertRoutine.rejectAll())
      return true
    },
    actionTitle: i18n.t("account.subscription alert button"),
  })
}