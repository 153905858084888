import { select, takeLatest, put, call, all } from 'redux-saga/effects'
import { alertRoutine } from './routines'

export const alertSelector = (state, id) => state.alerts.alerts[id]

function* rejectParent(children) {
  //const parent = yield select(state => _.find(state.alerts.alerts, ['id', children.parent]));
  const parent = yield select(alertSelector, children.parent);
  if(!parent)
    return;
  yield call(rejectSaga, { payload : parent.id})
  yield rejectParent(parent)
}

function* acceptSaga(action) {
  console.log(action)
  //const alert = yield select(state => _.find(state.alerts.alerts, ['id', action.payload.id]));
  const alert = yield select(alertSelector, action.payload.id);
  let shouldHide = true;
  if(typeof alert.action === 'function')
    shouldHide = yield call(alert.action, action.payload.values, action.payload.progress)
  else if (alert.action)
    yield put(alert.action)
  
  if(!shouldHide)
    return

  yield call(rejectParent, alert)
  yield put(alertRoutine.hide(action.payload.id))
}

function* watchAcceptAlerts() {
  yield takeLatest(alertRoutine.ACCEPT, acceptSaga)
}

function* rejectSaga(action) {
  //const alert = yield select(state => _.find(state.alerts.alerts, ['id', action.payload]));
  const alert = yield select(alertSelector, action.payload);
  if(typeof alert.reject === 'function')
    yield call(alert.reject)
  else if (alert.reject)
    yield put(alert.reject)
  yield put(alertRoutine.hide(action.payload))
}

function* watchRejectAlerts() {
  yield takeLatest(alertRoutine.REJECT, rejectSaga)
}



function* rejectAllSaga(action) {
  const alerts = yield select(state => state.alerts.alerts);
  yield all(Object.values(alerts).map(alert => {
    let action;
    if(typeof alert.reject === 'function')
      action = call(alert.reject)
    else if (alert.reject)
      action = put(alert.reject)
    return action
  }))
  yield all(Object.values(alerts).map(alert => {
    return put(alertRoutine.hide(alert.id))
  }))
}

function* watchRejectAllAlerts() {
  yield takeLatest(alertRoutine.REJECT_ALL, rejectAllSaga)
}

function* watchAlerts() {
  yield all([
    watchAcceptAlerts(),
    watchRejectAlerts(),
    watchRejectAllAlerts(),
  ]);
}

export default watchAlerts;