import { select, takeEvery, put, call, all, race, delay, take } from 'redux-saga/effects'
import { getPointsRoutine, putPointsRoutine, postPointsRoutine, deletePointsRoutine, cephaloRoutine } from './routines'

import {postDocumentRoutine, putDocumentRoutine} from '../Documents/routines'
import { makeDocumentSelector, makeAnalyseSelector } from '../Documents/selectors'
import { patientsIsLoadingSelector } from '../Patients/selectors'
import { getPatientRoutine } from '../Patients/routines'


import { cephalo_points as cephalo_points_api, getToken } from '../../utils/api'

import { normalize } from 'normalizr';

import { cephalo_point as cephalo_point_schema } from '../schemas'

import { makeParamsSelector } from '../Params/selectors'
import { makeCephaloAnalyseSelector } from './selectors'

import {isEqual} from 'lodash'

function* postPointSaga(action) {
  console.log("postPointsSaga", action)
  yield put(postPointsRoutine.request())

  try {

    const token = yield select(getToken);

    const { data } = yield call(cephalo_points_api.post, token, action.payload.values);

    const normalizedData = yield call(normalize, data, cephalo_point_schema);

    yield put(postPointsRoutine.success(normalizedData));

  }
  catch(error) {
    console.log(error)
    yield put(postPointsRoutine.failure(error));
  } 

  yield put(postPointsRoutine.fulfill());
}

function* watchPostPoint() {
  yield takeEvery(postPointsRoutine.TRIGGER, postPointSaga)
}

function* putPointSaga(action) {
  
  yield put(putPointsRoutine.request())

  try {

    const token = yield select(getToken);

    const { data } = yield call(cephalo_points_api.put, token, action.payload.id, action.payload.values);

    const normalizedData = yield call(normalize, data, cephalo_point_schema);

    yield put(putPointsRoutine.success(normalizedData));

  }
  catch(error) {
    console.log(error)
    yield put(putPointsRoutine.failure(error));
  } 

  yield put(putPointsRoutine.fulfill());
}

function* watchPutPoint() {
  yield takeEvery(putPointsRoutine.TRIGGER, putPointSaga)
}

function* deletePointSaga(action) {
  
  yield put(deletePointsRoutine.request())

  try {

    const token = yield select(getToken);

    const { data } = yield call(cephalo_points_api.delete, token, action.payload.id);

    const normalizedData = yield call(normalize, data, cephalo_point_schema);
    //a voir
    console.log('normalizedData delete', normalizedData)

    Object.keys(normalizedData.entities).forEach(key => {
      if(normalizedData.entities[key][normalizedData.result])
        normalizedData.entities[key][normalizedData.result]._id = null
    })

    yield put(deletePointsRoutine.success(normalizedData));

  }
  catch(error) {
    console.log(error)
    yield put(deletePointsRoutine.failure(error));
  } 

  yield put(deletePointsRoutine.fulfill());
}

function* watchDeletePoint() {
  yield takeEvery(deletePointsRoutine.TRIGGER, deletePointSaga)
}

function* getPointsSaga(action) {
  yield put(getPointsRoutine.request())

  try {

    const token = yield select(getToken);

    const { data } = yield call(cephalo_points_api.get, token);

    const normalizedData = yield call(normalize, data, [cephalo_point_schema]); 

    yield put(getPointsRoutine.success(normalizedData));

  }
  catch(error) {
    console.log(error)
    yield put(getPointsRoutine.failure(error));
  } 

  yield put(getPointsRoutine.fulfill());
}

function* watchGetPoints() {
  yield takeEvery(getPointsRoutine.TRIGGER, getPointsSaga)
}

function* monitorSelector(selector, previousValue, takePattern = "*") {
  while (true) {
    const nextValue = yield select(selector)
    if (!isEqual(nextValue, previousValue)) {
      return nextValue
    }
    yield take(takePattern)
  }
}

const paramsSelector = makeParamsSelector()
const selectedAnalysesParams =  state => (paramsSelector(state, 'selected-cephalo-analyse')[0] || {}).value


function* watchSelectedAnalysesSaga() {
  let previousSelectedAnalyses = {}
  while (true) {
    const selectedAnalyses = yield monitorSelector(selectedAnalysesParams, previousSelectedAnalyses)
    
    //Faire quelquechose avec selectedAnalyses
    //console.log('redux-saga watchSelectedAnalysesSaga', selectedAnalyses)


    previousSelectedAnalyses = selectedAnalyses
  }
}

const cephaloAnalyseSelector = makeCephaloAnalyseSelector()
const analyseSelector = makeAnalyseSelector()

function* startAnalyse(action) {
  //ici on load l'analyse si ce n'est pas fait on load une analyse par defaut
  
  const isLoading = yield select(patientsIsLoadingSelector)

  if(isLoading)
    yield take([getPatientRoutine.FULFILL])

  const analyseState = yield select(cephaloAnalyseSelector, { folderId: action.payload.folder, orientation: action.payload.orientation})

  console.log('analyse dans le state', analyseState)
  //TODO load analyse state from saved analyse
  
  if(!analyseState) {
    //check if saved analyse
    const analyse = yield select(analyseSelector, { folderId: action.payload.folder, orientation: action.payload.orientation})
    


    const test_state = {
      points: {
        NoseTip: {
          x: 1281.8353091832503,
          y: 970.1502779506849
        },
        PogCut: {
          x: 1093.5939309785635,
          y: 1316.0948671815838
        },
        S: {
          x: 1045.4142210800294,
          y: 909.4981575494409
        },
        Na: {
          x: 1575.639240381338,
          y: 783.2402902507232
        },
        A: {
          x: 1616.216851553827,
          y: 1189.1024829314733
        },
        B: {
          x: 1619.5828011287654,
          y: 1447.3274320987466
        },
        Gn: {
          x: 1649.8383259122052,
          y: 1612.404897047389
        },
        Go: {
          x: 990.0145863195148,
          y: 1519.3448743491267
        },
        Po: {
          x: 834.7478654699019,
          y: 1000.2521836086229
        },
        Or: {
          x: 1522.146818072527,
          y: 988.3093618643336
        },
        uie: {
          x: 1675.4438360264787,
          y: 1359.5154748448513
        },
        uia: {
          x: 1574.0561498995096,
          y: 1190.3241830876077
        },
        lie: {
          x: 1648.8534117114423,
          y: 1320.920114859161
        },
        lia: {
          x: 1566.825833268344,
          y: 1473.556340582225
        },
        OccP: {
          x: 1535.8472768217875,
          y: 1336.8020913830437
        },
        OccM: {
          x: 1392.897316333295,
          y: 1340.4297979897642
        },
        distalU6: {
          x: 1357.0445075533385,
          y: 1319.598781409114
        },
        apexU6: {
          x: 1374.7464860202858,
          y: 1211.2799295444443
        },
        distalL6: {
          x: 1362.5545810412164,
          y: 1358.8049914728717
        },
        apexL6: {
          x: 1352.7063175746393,
          y: 1489.5380759058844
        }
      },
      paths: {
        profil_sup: [
          [
            [
              [
                1370.4702844620851,
                193.55150052139112
              ],
              [
                1414.6066310723756,
                218.74245900903668
              ],
              [
                1434.270744471992,
                249.6262379754431
              ],
              [
                1465.774440884792,
                285.9883758621778
              ]
            ],
            [
              [
                1465.774440884792,
                285.9883758621778
              ],
              [
                1531.9907492093912,
                362.41644011534004
              ],
              [
                1633.6880955674917,
                551.4314937987888
              ],
              [
                1662.5660422342442,
                648.7299237642574
              ]
            ],
            [
              [
                1662.5660422342442,
                648.7299237642574
              ],
              [
                1678.941952428541,
                703.9052577153657
              ],
              [
                1636.9345354194418,
                738.021656939237
              ],
              [
                1651.3838970264856,
                786.7058731195733
              ]
            ],
            [
              [
                1651.3838970264856,
                786.7058731195733
              ],
              [
                1676.519344231208,
                871.3947042792706
              ],
              [
                1842.4353386199368,
                955.7768319804335
              ],
              [
                1865.2292329031352,
                1036.516073855364
              ]
            ],
            [
              [
                1865.2292329031352,
                1036.516073855364
              ],
              [
                1902.3360614110513,
                1167.9537810277088
              ],
              [
                1758.4890350352237,
                1096.9825089461428
              ],
              [
                1772.6427821156158,
                1197.6916523802333
              ]
            ],
            [
              [
                1772.6427821156158,
                1197.6916523802333
              ],
              [
                1778.6105157880536,
                1240.1542838643504
              ],
              [
                1879.9041302710411,
                1297.8552541062104
              ],
              [
                1743.0106557013814,
                1338.2605774945496
              ]
            ]
          ]
        ],
        profil_inf: [
          [
            [
              [
                1752.2356463046185,
                1336.964089615049
              ],
              [
                1783.1107684767617,
                1348.849711378211
              ],
              [
                1799.4667655374276,
                1378.920071866758
              ],
              [
                1791.2548870599608,
                1413.7932937548721
              ]
            ],
            [
              [
                1791.2548870599608,
                1413.7932937548721
              ],
              [
                1779.8491427609042,
                1462.2298424485139
              ],
              [
                1731.2770522904395,
                1420.3976954784357
              ],
              [
                1712.4435572392038,
                1455.442904130899
              ]
            ],
            [
              [
                1712.4435572392038,
                1455.442904130899
              ],
              [
                1703.8253395229667,
                1471.4796109267545
              ],
              [
                1775.8476088021205,
                1548.4831820715176
              ],
              [
                1743.7088417939522,
                1610.9712565064287
              ]
            ],
            [
              [
                1743.7088417939522,
                1610.9712565064287
              ],
              [
                1674.0001523167605,
                1746.5073141188373
              ],
              [
                1447.203762812508,
                1732.6035285820108
              ],
              [
                1316.5419351159444,
                1750.9668509102257
              ]
            ]
          ]
        ],
        mandibule: [
          [
            [
              [
                1634.180712558421,
                1400.5916290044938
              ],
              [
                1581.5347428846057,
                1461.552290454414
              ],
              [
                1695.5910556311806,
                1536.1038576171927
              ],
              [
                1640.2518194774093,
                1627.8629928016207
              ]
            ],
            [
              [
                1640.2518194774093,
                1627.8629928016207
              ],
              [
                1576.4222288899782,
                1663.4831142512876
              ],
              [
                1346.8165413218956,
                1637.7295490859037
              ],
              [
                1299.0767426043683,
                1609.9616222409845
              ]
            ],
            [
              [
                1299.0767426043683,
                1609.9616222409845
              ],
              [
                1190.6064373620818,
                1586.5636941353978
              ],
              [
                1114.7702797757224,
                1591.481240447215
              ],
              [
                1015.670038586609,
                1534.5536358568493
              ]
            ],
            [
              [
                1015.670038586609,
                1534.5536358568493
              ],
              [
                936.8699375434385,
                1489.2873384186223
              ],
              [
                976.9371821597717,
                1354.3631345913504
              ],
              [
                951.6934061508791,
                1280.143362604712
              ]
            ],
            [
              [
                951.6934061508791,
                1280.143362604712
              ],
              [
                935.4147543507033,
                1232.2821462623833
              ],
              [
                798.3492113125967,
                997.380637525235
              ],
              [
                947.3550906663147,
                1014.99967048501
              ]
            ],
            [
              [
                947.3550906663147,
                1014.99967048501
              ],
              [
                1001.2189650425674,
                1021.3687438537435
              ],
              [
                1010.9354743878221,
                1135.0796365803926
              ],
              [
                1068.8881555724993,
                1160.193513686219
              ]
            ],
            [
              [
                1068.8881555724993,
                1160.193513686219
              ],
              [
                1150.0038731828608,
                1195.3451256871517
              ],
              [
                1215.6867361629897,
                1084.8782662679941
              ],
              [
                1235.9477462020768,
                1127.307619282094
              ]
            ],
            [
              [
                1235.9477462020768,
                1127.307619282094
              ],
              [
                1265.2745972046528,
                1188.72209524256
              ],
              [
                1204.392870543765,
                1320.039758835952
              ],
              [
                1280.5020315217073,
                1377.3921253408057
              ]
            ],
            [
              [
                1280.5020315217073,
                1377.3921253408057
              ],
              [
                1302.161099743036,
                1393.7134039204416
              ],
              [
                1341.9253412997305,
                1388.6918035866247
              ],
              [
                1367.7405325592179,
                1395.7049538858205
              ]
            ]
          ]
        ],
        maxillaire: [
          [
            [
              [
                1651.6333567788593,
                1257.0308184379337
              ],
              [
                1634.7436871242362,
                1240.0642708496484
              ],
              [
                1600.357118285062,
                1199.203571708043
              ],
              [
                1620.866740717127,
                1171.9863834661828
              ]
            ],
            [
              [
                1620.866740717127,
                1171.9863834661828
              ],
              [
                1630.4981402963472,
                1159.2050845296221
              ],
              [
                1659.6031684540753,
                1165.7843170479505
              ],
              [
                1666.3566859736193,
                1152.6647676653406
              ]
            ],
            [
              [
                1666.3566859736193,
                1152.6647676653406
              ],
              [
                1653.2762451453214,
                1145.4896712646885
              ],
              [
                1583.1516961291895,
                1133.7202740647012
              ],
              [
                1553.4494964889584,
                1127.369858714324
              ]
            ],
            [
              [
                1553.4494964889584,
                1127.369858714324
              ],
              [
                1506.8557289769892,
                1133.9181856923833
              ],
              [
                1462.9013574373996,
                1161.2617699643865
              ],
              [
                1415.6854875184674,
                1167.8975277339919
              ]
            ],
            [
              [
                1415.6854875184674,
                1167.8975277339919
              ],
              [
                1393.4819746005844,
                1171.0180279727956
              ],
              [
                1185.8678705920431,
                1187.3052634210364
              ],
              [
                1184.7366004676644,
                1198.0034770706975
              ]
            ],
            [
              [
                1184.7366004676644,
                1198.0034770706975
              ],
              [
                1183.0493176498953,
                1213.9598005719563
              ],
              [
                1418.660869132178,
                1188.7616623474783
              ],
              [
                1441.9891837253122,
                1187.7187845433316
              ]
            ],
            [
              [
                1441.9891837253122,
                1187.7187845433316
              ],
              [
                1505.523553040422,
                1184.8785200529605
              ],
              [
                1551.9849499507102,
                1266.4484617011385
              ],
              [
                1602.803246418623,
                1294.4668432055832
              ]
            ]
          ]
        ],
        base_crane: [
          [
            [
              [
                1562.3680073194178,
                702.2058460159665
              ],
              [
                1543.2227687047312,
                708.5393249273535
              ],
              [
                1424.7173449178283,
                817.3365154808379
              ],
              [
                1415.9843833031018,
                833.8736995697267
              ]
            ],
            [
              [
                1415.9843833031018,
                833.8736995697267
              ],
              [
                1389.9794552464093,
                883.1179653141963
              ],
              [
                1431.0983114467012,
                974.9575132754302
              ],
              [
                1484.5798802850456,
                989.1215652252929
              ]
            ],
            [
              [
                1484.5798802850456,
                989.1215652252929
              ],
              [
                1500.5394145564874,
                993.3482862700622
              ],
              [
                1520.9635056895745,
                990.0883109628434
              ],
              [
                1537.4738799004228,
                991.043164839989
              ]
            ]
          ]
        ]
      },
      current_mesure: null,
      current_path: null,
      ruler: {
        scaleX: 1.970262560440623,
        scaleY: 1.970262560440623,
        shear: 0,
        rotate: 88.79018616452326,
        translateX: 1337.48711532963,
        translateY: 413.5660094598381,
        originX: 0,
        originY: 0,
        a: 0.041599465143399045,
        b: 1.96982335288569,
        c: -1.96982335288569,
        d: 0.041599465143399045,
        e: 1337.48711532963,
        f: 413.5660094598381
      },
      image_settings_xray: {
        contrast: 100,
        brightness: 100,
        invert: 0
      },
      image_settings_photo: {
        contrast: 100,
        brightness: 100,
        invert: 0
      },
      profil_transform: {
        scaleX: 0.6483639497050867,
        scaleY: 0.6483639497050867,
        shear: 0,
        rotate: 15.174492456485662,
        translateX: 293.0519143654875,
        translateY: -17.199213483457903,
        originX: 0,
        originY: 0,
        a: 0.6257575236197892,
        b: 0.16971544688215362,
        c: -0.16971544688215362,
        d: 0.6257575236197892,
        e: 293.0519143654875,
        f: -17.199213483457903
      }
    }

    //else load a fresh one
    yield put(cephaloRoutine.loadAnalyse({
      folder: action.payload.folder,
      orientation: action.payload.orientation,
      analyse: {
        points: {
        },
        paths: {
        },
        current_mesure: null,
        current_path: null,
        ruler: null,
        image_settings_xray: {
          contrast : 100,
          brightness: 100,
          invert: 0,
        },
        image_settings_photo: {
          contrast : 100,
          brightness: 100,
          invert: 0,
        },
        ...(analyse? analyse.data : {})     
      }
    }))


  }

}

function* watchStartAnalyse() {
  yield takeEvery(cephaloRoutine.START_ANALYSE, startAnalyse)
}



const documentSelector = makeDocumentSelector()

function* handleAutoSave(actions, action) {
  // debounce save by 5s
  yield put(cephaloRoutine.autosaveStart())
  //yield delay(3000)
  
  const { debounced } = yield race({
    debounced: delay(3000),
    latestAction: take((newAction) => {
      console.log('debounce', actions, newAction)
      return actions.includes(newAction.type) && newAction.payload.folder === action.payload.folder && newAction.payload.orientation === action.payload.orientation
    }),
  })

  if (!debounced) {
    return
  }

  //on mets à jour l'analyse ou on la crée
  const analyseState = yield select(cephaloAnalyseSelector, { folderId: action.payload.folder, orientation: action.payload.orientation})

  const analyse = yield select(analyseSelector, { folderId: action.payload.folder, orientation: action.payload.orientation})

  const parent_doc = yield select(documentSelector, { documentId: action.payload.folder })

  const {points, paths, ruler, image_settings_xray, image_settings_photo, profil_transform } = analyseState

  if(analyse) {
    yield put(putDocumentRoutine.trigger({
      id: analyse._id,
      values: {
        data: {
          points, 
          paths, 
          ruler, 
          image_settings_xray, 
          image_settings_photo, 
          profil_transform
        }
      }
    }))
  }
  else {
    yield put(postDocumentRoutine.trigger({
      type : `cephalo-${action.payload.orientation}`,
      data: {
        points, 
        paths, 
        ruler, 
        image_settings_xray, 
        image_settings_photo, 
        profil_transform
      },
      parent_doc: action.payload.folder,
      parent_patient: parent_doc.parent_patient,
    }))
  } 
    
  yield take([putDocumentRoutine.FULFILL, postDocumentRoutine.FULFILL])

  yield put(cephaloRoutine.autosaveEnd())
}

function* watchAutoSave() {
  // will cancel current running handleInput task
  const actions = [
    cephaloRoutine.SET_POINT,
    cephaloRoutine.DELETE_POINT,
    cephaloRoutine.SET_PATH,
    cephaloRoutine.DELETE_PATH,
    cephaloRoutine.SET_CURRENT_PATH,
    cephaloRoutine.SET_RULER,
    cephaloRoutine.SET_PROFIL_TRANSFORM,
    cephaloRoutine.SET_IMAGE_SETTINGS,
    cephaloRoutine.RESET,
    cephaloRoutine.UNDO,
    cephaloRoutine.REDO,
  ]
  yield takeEvery(actions, handleAutoSave, actions);
}

function* watchCephalo() {
  yield all([
    watchPostPoint(),
    watchPutPoint(),
    watchDeletePoint(),
    watchGetPoints(),
    watchStartAnalyse(),
    watchSelectedAnalysesSaga(),
    watchAutoSave(),
  ]);
}

export default watchCephalo;

