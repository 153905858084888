import { createSelector } from 'reselect'

export const usersIsLoadingSelector = state => state.users.users.loading

export const usersSelector = state => state.entities.users

// export const userSelector = createSelector(
//   usersSelector,
//   (_, props) => props,
//   (users = {}, userId) => {
//     //console.log('userSelector')
//     return users[userId] || {}
//   }
// )

export const userSelector = createSelector(
  (state, userId) => state.entities?.users?.[userId] || {},
  state => state.entities.infos || {},
  state => state.entities.subscriptions || {},
  state => state.entities.gifts || {},
  (user, infos, subscriptions, gifts) => {
    //console.log('userSelector')
    return {...user, 
      infos: infos[user.infos],
      stripe_subscription: subscriptions[user.stripe_subscription], 
      gift: gifts[user.gift],
    } || {}
  }
)

export const makeUserSelector = () => userSelector


export const infosSelector = state => state.entities.infos

export const infoSelector = createSelector(
  infosSelector,
  (_, props) => props,
  (infos = {}, infoId) => {
    //console.log('infoSelector')
    return infos[infoId] || {}
  }
)

export const makeInfoSelector = () => infoSelector

export const redirectingSelector = state => state.users.redirecting 