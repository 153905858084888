import { getExternalPatientRoutine, getExternalPatientRoutinePromiseCreator } from '../redux/Patients/routines'
import { externalLinkLoading } from '../redux/Patients/selectors'
import { alertRoutine } from '../redux/Alerts/routines'
import { localeIsInitialized } from '../i18n';
import moment from 'moment';
import { v4 as uuidv4} from 'uuid';


export default (store, i18n) => {

  const isInitialized = localeIsInitialized(i18n)

  window.addEventListener("message", async (e) => {
    if (e.origin !== window.origin)
      return;
    if(e.data.type === 'doctolib_get_next_appointment'){
      let response = {
        delay : new Date(),
        delay_name: undefined,
        visit_motive_id: undefined,
        next_appointment_type: undefined,
      }
      try {
        const {entities: {contacts = {}}} = await getExternalPatientRoutinePromiseCreator({
          service: 'doctolib',
          silent: true,
          id: e.data.patient.id.split('-')[0],
          patient: e.data.patient
        }, store.dispatch)
        const last_contact = Object.values(contacts).filter(c => c.date && !c.type).sort((a,b) => a.date < b.date ? 1 : a.date > b.date ? -1 : a.createdAt < b.createdAt ? 1 : -1 )[0]
        if(last_contact) {
          //on récupère les donnée de délai et de type de rdv
          if(last_contact.delai_time_unit && last_contact.delai_number) {
            response.delay = moment().add(last_contact.delai_number, last_contact.delai_time_unit).toDate()
            response.delay_name = last_contact.delai
          }
          if(last_contact.next_appointment_type_external_id) {
            response.visit_motive_id = parseInt(last_contact.next_appointment_type_external_id)
          }
          if(last_contact.next_appointment_type) {
            response.next_appointment_type = last_contact.next_appointment_type
          }
        }
      } finally {
        e.source.postMessage({
          id: e.data.id,
          type: 'response_doctolib_get_next_appointment',
          ...response,
        })
      }
    }
    if(e.data.type === 'doctolib_open'){
      store.dispatch(getExternalPatientRoutine.trigger({
        service: 'doctolib',
        id: e.data.patient.id.split('-')[0],
        patient: e.data.patient
      }))
    }
    else if(e.data.type === 'ortho_advance_open'){
      console.log("ortho_advance_open", e.data.patient)
      store.dispatch(getExternalPatientRoutine.trigger({
        service: 'ortho_advance',
        id: e.data.patient.uuid,
        patient: {
          first_name: e.data.patient.firstname,
          last_name: e.data.patient.lastname,
          birthdate: e.data.patient.birthday_value,
          gender: e.data.patient.sex === 'female' ? 1 : 0,
          phone_number: e.data.patient.portable_with_indicatif || e.data.patient.phone_with_indicatif || e.data.patient.habite_chez_portable || e.data.patient.habite_chez_phone,
          email: e.data.patient.guessed_email,
          address: e.data.patient.address_dict.address_street,
          zipcode: e.data.patient.address_dict.address_cp,
          city: e.data.patient.address_dict.address_town,
        }
      }))
    }
    else if(e.data.type === 'veasy_open'){
      console.log("veasy_open", e.data.patient)
      const naissance = moment(e.data.patient.Patient_Date_Naissance, 'DD/MM/YYYY')
      store.dispatch(getExternalPatientRoutine.trigger({
        service: 'veasy',
        id: e.data.patient.Patient_Num_Dossier,
        patient: {
          first_name: e.data.patient.Patient_Prenom,
          last_name: e.data.patient.Patient_Nom,
          birthdate: naissance ? naissance.format('YYYY-MM-DD') : undefined,
          gender: e.data.patient.Patient_Sexe === 'F' ? 1 : 0,
          phone_number: e.data.patient.Patient_Tel_Portable || e.data.patient.Parent_Tel_Portable || e.data.patient.Parent_Tel_Domicile || e.data.patient.Parent_Tel_Travail,
          email: e.data.patient.Parent_Email,
          address: `${e.data.patient.Parent_Adresse_1} ${e.data.patient.Parent_Adresse_2}`.trim(),
          zipcode: e.data.patient.Parent_Code_Postal,
          city: e.data.patient.Parent_Ville,
        }
      }))
    }
    else if(e.data.type === 'generic_open'){
      console.log("generic_open", e.data.patient)
      store.dispatch(getExternalPatientRoutine.trigger({
        service: e.data.software_name,
        id: `${e.data.patient.uuid}`,
        patient: {
          first_name: e.data.patient.firstname,
          last_name: e.data.patient.lastname,
          birthdate: e.data.patient.birthdate,
          gender: e.data.patient.gender === 'female' ? 1 : 0,
          phone_number: e.data.patient.phone_number,
          email: e.data.patient.email,
          address: e.data.patient.address,
          zipcode: e.data.patient.zipcode,
          city: e.data.patient.city,
        }
      }))
    }
  }, false);
  
  let alertId;
  
  store.subscribe(async () => {

    await isInitialized

    const showExternalLinkLoading = externalLinkLoading(store.getState())
    
    if(showExternalLinkLoading) {
      if(!alertId) {
        alertId = uuidv4()
        store.dispatch(alertRoutine.show({
          id: alertId,
          type: 'snack',
          title: i18n.t('common:interface.external link loading'),
          persist: true,
          closeReasons: [],
          loading: true,
        }))
      }
      return
    }



    if(alertId) {
      const action = alertRoutine.hide(alertId)
      alertId = null
      store.dispatch(action)
    }
    
  })
}
