import { select, takeLatest, put, call, all, takeEvery } from 'redux-saga/effects'

import { putParamRoutine, postParamRoutine, getParamRoutine, getParamsRoutine, deleteParamRoutine } from './routines'


import { normalize } from 'normalizr';

import { params, getToken } from '../../utils/api'

import { param } from '../schemas'

function* getParamSaga(action) {
  
  yield put(getParamRoutine.request())

  try {

    const token = yield select(getToken);

    const { data } = yield call(params.get, token, action.payload.id);

    const normalizedData = yield call(normalize, data, param);

    yield put(getParamRoutine.success(normalizedData));

  }
  catch(error) {
    console.log(error)
    yield put(getParamRoutine.failure(error));
  } 

  yield put(getParamRoutine.fulfill());
}


function* watchGetParam() {
  yield takeLatest(getParamRoutine.TRIGGER, getParamSaga)
}

function* postParamSaga(action) {

  yield put(postParamRoutine.request())

  try {

    const token = yield select(getToken);

    const { data } = yield call(params.post, token, action.payload);

    const normalizedData = yield call(normalize, data, param);

    yield put(postParamRoutine.success(normalizedData));

  }
  catch(error) {
    console.log(error)
    yield put(postParamRoutine.failure(error));
  } 

  yield put(postParamRoutine.fulfill());
}

function* watchPostParam() {
  yield takeEvery(postParamRoutine.TRIGGER, postParamSaga)
}

function* putParamSaga(action) {
  
  yield put(putParamRoutine.request())

  try {

    const token = yield select(getToken);

    const { data } = yield call(params.put, token, action.payload.id, action.payload.values);

    const normalizedData = yield call(normalize, data, param);

    yield put(putParamRoutine.success(normalizedData));

  }
  catch(error) {
    console.log(error)
    yield put(putParamRoutine.failure(error));
  } 

  yield put(putParamRoutine.fulfill());
}

function* watchPutParam() {
  yield takeEvery(putParamRoutine.TRIGGER, putParamSaga)
}

function* deleteParamSaga(action) {
  
  yield put(deleteParamRoutine.request())

  try {

    const token = yield select(getToken);

    const { data } = yield call(params.delete, token, action.payload.id);

    const normalizedData = yield call(normalize, data, param);
    //a voir
    console.log('normalizedData delete', normalizedData)

    Object.keys(normalizedData.entities).forEach(key => {
      if(normalizedData.entities[key][normalizedData.result])
        normalizedData.entities[key][normalizedData.result]._id = null
    })

    yield put(deleteParamRoutine.success(normalizedData));

  }
  catch(error) {
    console.log(error)
    yield put(deleteParamRoutine.failure(error));
  } 

  yield put(deleteParamRoutine.fulfill());
}

function* watchDeleteParam() {
  yield takeEvery(deleteParamRoutine.TRIGGER, deleteParamSaga)
}

function* getParamsSaga(action) {
  yield put(getParamsRoutine.request())

  try {

    const token = yield select(getToken);

    const { data } = yield call(params.gets, token, action.payload);

    const normalizedData = yield call(normalize, data, [param]); 

    yield put(getParamsRoutine.success(normalizedData));

  }
  catch(error) {
    console.log(error)
    yield put(getParamsRoutine.failure(error));
  } 

  yield put(getParamsRoutine.fulfill());
}

function* watchGetParams() {
  yield takeLatest(getParamsRoutine.TRIGGER, getParamsSaga)
}

function* watchParam() {
  yield all([
    watchGetParam(),
    watchPostParam(),
    watchPutParam(),
    watchDeleteParam(),
    watchGetParams(),
  ]);
}

export default watchParam;