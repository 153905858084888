import { normalize } from 'normalizr';
import { select, takeLatest, takeEvery, put, call, all, debounce, race, delay, take, fork} from 'redux-saga/effects'
import { putPatientRoutine, getPatientsRoutine, postPatientRoutine, getPatientRoutine, searchPatientsRoutine, getTagsPatientsRoutine, tabsPatientRoutine, getExternalPatientRoutine, deletePatientRoutine, autoSavePatientRoutine, searchAdvancedPatientsRoutine} from './routines'
import { push } from 'connected-react-router'

import { REHYDRATE } from 'redux-persist'

import { LOCATION_CHANGE } from 'connected-react-router'
import { matchPath } from "react-router";

import { DOCUMENT_VISIBILITY_CHANGE } from "redux-saga-document-visibility";


import { patients, getToken } from '../../utils/api'

import { patient, tag } from '../schemas'

import { patientLinkFormActionCreator } from '../../components/Patient/forms/patientLinkFormActionCreator'

import { patientsResultSelector, patientSelector} from './selectors';

const takeEveryPatientAutoSave = () => fork(function*() {
  while (true) {
    const action = yield take(autoSavePatientRoutine.AUTOSAVE_START)
    yield fork(saveDebounce, 3000, action.type, putPatientSaga, action)
  }
})

function* saveDebounce (ms, pattern, task, action) {
  const { debounced } = yield race({
    debounced: delay(ms),
    latestAction: take((newAction) => newAction.type === pattern && newAction.payload.id === action.payload.id),
  })

  if (debounced) {
    yield fork(task, action)
  }
  else {
    //yield put(diagnosticRoutine.autosaveEnd())
  }

}

function* getPatientSaga(action) {
  
  yield put(getPatientRoutine.request())

  try {

    const token = yield select(getToken);

    const { data } = yield call(patients.get, token, action.payload.id);

    const normalizedData = yield call(normalize, data, patient);

    yield put(getPatientRoutine.success(normalizedData));

  }
  catch(error) {
    console.log(error)
    yield put(getPatientRoutine.failure(error));
  } 

  yield put(getPatientRoutine.fulfill());
}


function* watchGetPatient() {
  yield takeLatest(getPatientRoutine.TRIGGER, getPatientSaga)
}

function* getPatientsSaga(action) {
  
  yield put(getPatientsRoutine.request())

  try {

    const token = yield select(getToken);

    const { data } = yield call(patients.gets, token, action.payload.ids);

    const normalizedData = yield call(normalize, data, [patient]);

    yield put(getPatientsRoutine.success(normalizedData));

  }
  catch(error) {
    console.log(error)
    yield put(getPatientsRoutine.failure(error));
  } 

  yield put(getPatientsRoutine.fulfill());
}


function* watchGetPatients() {
  yield takeLatest(getPatientsRoutine.TRIGGER, getPatientsSaga)
}


function* postPatientSaga(action) {
  
  yield put(postPatientRoutine.request())

  try {

    const token = yield select(getToken);

    const { data } = yield call(patients.post, token, action.payload.values);

    const normalizedData = yield call(normalize, data, patient);

    yield put(postPatientRoutine.success(normalizedData));

  }
  catch(error) {
    console.log(error)
    yield put(postPatientRoutine.failure(error));
  } 

  yield put(postPatientRoutine.fulfill());
}

function* watchPostPatient() {
  yield takeLatest(postPatientRoutine.TRIGGER, postPatientSaga)
}

function* putPatientSaga(action) {
  
  yield put(putPatientRoutine.request())

  try {

    if(!action.payload.id)
      throw new Error('id must be defined')

    const token = yield select(getToken);

    const { data } = yield call(patients.put, token, action.payload.id, action.payload.values);

    const normalizedData = yield call(normalize, data, patient);

    yield put(putPatientRoutine.success(normalizedData));

  }
  catch(error) {
    console.log(error)
    yield put(putPatientRoutine.failure(error));
  } 

  yield put(putPatientRoutine.fulfill());
  yield put(autoSavePatientRoutine.autosaveEnd())
}

function* watchPutPatient() {
  yield takeLatest(putPatientRoutine.TRIGGER, putPatientSaga)
}

export const LIMIT_RESULT = 30

function* searchPatientsSaga(action) {
  yield put(searchPatientsRoutine.request())

  try {

    const token = yield select(getToken);
    console.log(action.payload)
    const [{ data }, {data: searchCount}] = yield all([
      call(patients.search, token, {search: action.payload.search , start: action.payload.start || 0, limit: LIMIT_RESULT}),
      call(patients.search_count, token, {search: action.payload.search})
    ])

    let normalizedData = yield call(normalize, data, [patient]);
    normalizedData.searchCount = searchCount
    
    if(action.payload.start) {
      const previousResults = yield select(patientsResultSelector);
      normalizedData = {
        ...normalizedData,
        result: [...previousResults, ...normalizedData.result]
      }
    }
      

    yield put(searchPatientsRoutine.success(normalizedData));

  }
  catch(error) {
    console.log(error)
    yield put(searchPatientsRoutine.failure(error));
  } 

  yield put(searchPatientsRoutine.fulfill());
}

function* watchSearchPatients() {
  yield debounce(500, searchPatientsRoutine.TRIGGER, searchPatientsSaga)
}

function* gettagsPatientsSaga(action) {
  yield put(getTagsPatientsRoutine.request())

  try {

    const token = yield select(getToken);

    const { data } = yield call(patients.getsTags, token);

    const normalizedData = yield call(normalize, data, [tag]);

    yield put(getTagsPatientsRoutine.success(normalizedData));

  }
  catch(error) {
    console.log(error)
    yield put(getTagsPatientsRoutine.failure(error));
  } 

  yield put(getTagsPatientsRoutine.fulfill());
}

function* watchGetTagsPatients() {
  yield takeLatest(getTagsPatientsRoutine.TRIGGER, gettagsPatientsSaga)
}

function* handleRehydratePatients(action) {
  if(action.type === REHYDRATE && action.key === 'patients' && action.payload && action.payload.tabs && action.payload.tabs.length)
    yield put(getPatientsRoutine.trigger({ids : action.payload.tabs}))     
}

function* watchRehydrate() {
 yield takeEvery(REHYDRATE, handleRehydratePatients)
}

function* logoutSaga(action) {
  if(action.payload.location.pathname !== '/logout')
    return;

  yield put(tabsPatientRoutine.closeAll());

}

function* historySaga(action) {

  const match = matchPath(action.payload.location.pathname, {
    path: "/patient/:id",
    exact: false,
    strict: false
  });
  
  if(!match)
    return

  yield put(tabsPatientRoutine.history({
    id: match.params.id,
    pathname: action.payload.location.pathname+action.payload.location.search
  }));

}

function* watchLogout() {
  yield takeEvery(LOCATION_CHANGE, logoutSaga)
}

function* watchHistory() {
  yield takeEvery(LOCATION_CHANGE, historySaga)
}

function* getExternalSaga(action) {
  yield put(getExternalPatientRoutine.request())

  try {

    const token = yield select(getToken);

    const { data } = yield call(patients.getExternal, token, action.payload.service, action.payload.id);

    const normalizedData = yield call(normalize, data, patient);

    yield put(getExternalPatientRoutine.success(normalizedData));

    if(!action.payload.silent) {
      //if data open patient put router change action
      yield put(push(`/patient/${data.id}`))
    }
  }
  catch(error) {
    console.log(error)

    yield put(getExternalPatientRoutine.failure(error));
    
    if(!action.payload.silent) {

      yield put(patientLinkFormActionCreator(action.payload.service, action.payload.id, {
        firstname: action.payload.patient.first_name || '',
        lastname: action.payload.patient.last_name || '',
        dateofbirth: (action.payload.patient.birthdate || '').substr(0,10) || undefined,
        gender: action.payload.patient.gender? 'female' : 'male',
        phone: action.payload.patient.phone_number || undefined,
        email: action.payload.patient.email || undefined,
        adresse1: action.payload.patient.address || undefined,
        postcode: action.payload.patient.zipcode || undefined,
        city: action.payload.patient.city || undefined,
        collect_accept: true,
        external_ids: [
          {service:  action.payload.service, id: action.payload.id}
        ]
      }))
      //show patient link alert
      console.log('show_link_external_alert')
    }
  } 

  yield put(getExternalPatientRoutine.fulfill());
}

function* watchGetExternal() {
  yield takeLatest(getExternalPatientRoutine.TRIGGER, getExternalSaga)
}

function* watchPatientChange(patientId) {
  while(true){
    const action = yield take(LOCATION_CHANGE)
    console.log('watchPatientChange action', action)
    if(!action.payload)
      continue;
    const match = matchPath(action.payload.location.pathname, {
      path: "/patient/:id",
      exact: false,
      strict: false
    });
    console.log('watchPatientChange match', match)
    if(patientId === match?.params.id)
      continue;
    
    console.log('watchPatientChange patientId', match?.params.id)
    return match?.params.id
  }
}

function* pollTask(patientId, visibility) {
  while (true) {
      console.log(patientId, visibility)
      if(!patientId || !visibility) {
        yield take('WAIT_FOREVER')
      }
        
      try {
          // Fetching posts at regular interval 20 seconds.
          const token = yield select(getToken);
          if(!token)
            throw new Error('no credential')
          const { data } = yield call(patients.getUpdate, token, patientId);
          const patient = yield select(patientSelector, patientId)

          if(data.delete) {
            //le patient a été supprimé
            //close tab
            yield put(tabsPatientRoutine.close(patientId))
            //go to home
            yield put(push('/'))
          }
          else if(patient.updatedAt < data.updatedAt) {
            yield put(getPatientRoutine.trigger({id: patientId}));
          } 
          yield delay(20000);
      } catch (err) {
        //console.log(err)
        yield delay(20000);
      }
  }
}


/* Watcher Function */
function* pollPatientWatcher() {
  let patientId;
  let visibility = true;
  while (true) {
    const result= yield race({
      polling: call(pollTask, patientId, visibility), 
      patientId: call(watchPatientChange, patientId),
      action: take(DOCUMENT_VISIBILITY_CHANGE)
    })
    if(result.action)
      visibility = result.action?.payload.visibility
    else
      patientId = result.patientId
  }
}

function* deletePatientSaga(action) {
  
  yield put(deletePatientRoutine.request())

  try {

    const token = yield select(getToken);

    const { data } = yield call(patients.delete, token, action.payload.id);

    const normalizedData = yield call(normalize, data, patient);
    //a voir
    console.log('normalizedData delete', normalizedData)

    Object.keys(normalizedData.entities).forEach(key => {
      if(normalizedData.entities[key][normalizedData.result])
        normalizedData.entities[key][normalizedData.result]._id = null
    })

    yield put(deletePatientRoutine.success(normalizedData));
  }
  catch(error) {
    console.log(error)
    yield put(deletePatientRoutine.failure(error));
  } 

  yield put(deletePatientRoutine.fulfill());
}

function* watchDeletePatient() {
  yield takeEvery(deletePatientRoutine.TRIGGER, deletePatientSaga)
}


function* searchAdvancedPatientsSaga(action) {
  yield put(searchAdvancedPatientsRoutine.request());

  try {
    const token = yield select(getToken);
    const currentPage = action.payload.page || 0;
    const pageSize = action.payload.size || 20;
    const { data } = yield call(patients.advancedSearch, token, { ...action.payload, page: currentPage, size: pageSize });

    yield put(searchAdvancedPatientsRoutine.success({
      result: data.data,
      searchCount: data.metadata[0]?.total || 0,
      page: currentPage,
      size: pageSize,
    }));
  } catch (error) {
    console.log(error);
    yield put(searchAdvancedPatientsRoutine.failure(error));
  }

  yield put(searchAdvancedPatientsRoutine.fulfill());
}

function* watchSearchAdvancedPatients() {
  yield takeLatest(searchAdvancedPatientsRoutine.TRIGGER, searchAdvancedPatientsSaga);
}

function* watchPatient() {
  yield all([
    watchGetPatient(),
    watchGetPatients(),
    watchPostPatient(),
    watchPutPatient(),
    watchSearchPatients(),
    watchGetTagsPatients(),
    watchRehydrate(),
    watchLogout(),
    watchHistory(),
    watchGetExternal(),
    pollPatientWatcher(),
    watchDeletePatient(),
    takeEveryPatientAutoSave(),
    watchSearchAdvancedPatients(),
  ]);
}

export default watchPatient;