import { combineReducers } from 'redux'
import { handleActions, combineActions } from 'redux-actions'
import { getUserRoutine, postUserRoutine, putUserRoutine, putUserSubscriptionDeclineRoutine, putUserSubscriptionAcceptCGVRoutine} from './routines'

const intialState = {
  users: {
    result: [],
    error: null,
    loading: true,
  },
  redirecting: false,
}

export const subUsersReducer = handleActions({
  
  [combineActions(postUserRoutine.TRIGGER, getUserRoutine.TRIGGER, putUserRoutine.TRIGGER, putUserSubscriptionDeclineRoutine.TRIGGER, putUserSubscriptionAcceptCGVRoutine.TRIGGER)]: (state, action) => ({
    ...state,
    loading: true,
  }),

  [combineActions(postUserRoutine.SUCCESS, getUserRoutine.SUCCESS, putUserRoutine.SUCCESS, putUserSubscriptionDeclineRoutine.SUCCESS, putUserSubscriptionAcceptCGVRoutine.SUCCESS)]: (state, action) => ({
    ...state,
    result: [action.payload.result],
  }),
   
  [combineActions(postUserRoutine.FAILURE, getUserRoutine.FAILURE, putUserRoutine.FAILURE, putUserSubscriptionDeclineRoutine.FAILURE, putUserSubscriptionAcceptCGVRoutine.FAILURE)]: (state, action) => ({
    ...state,
    error: action.payload,
  }),

  [combineActions(postUserRoutine.FULFILL, getUserRoutine.FULFILL, putUserRoutine.FULFILL, putUserSubscriptionDeclineRoutine.FULFILL, putUserSubscriptionAcceptCGVRoutine.FULFILL)]: (state, action) => ({
    ...state,
    loading: false,
  }),
  
},
intialState.users
);

export const subRedicrectingReducer = handleActions({
  STRIPE_REDIRECTING: (state) => true, 
},
intialState.redirecting
);

export const usersReducer = combineReducers({
  users: subUsersReducer,
  redirecting: subRedicrectingReducer,
})