import { createRoutine, createRoutineCreator, promisifyRoutine} from 'redux-saga-routines';

export const getTaskRoutine = createRoutine('GET_TASK');
export const getTasksRoutine = createRoutine('GET_TASKS'); 

export const postTaskRoutine = createRoutine('POST_TASK'); 
export const postTaskRoutinePromiseCreator = promisifyRoutine(postTaskRoutine);

export const putTaskRoutine = createRoutine('PUT_TASK');
export const putTaskRoutinePromiseCreator = promisifyRoutine(putTaskRoutine);

export const deleteTaskRoutine = createRoutine('DELETE_TASK');
export const deleteTaskRoutinePromiseCreator = promisifyRoutine(deleteTaskRoutine);

const createSearchRoutine = createRoutineCreator(['TRIGGER', 'REQUEST', 'SUCCESS', 'FAILURE', 'FULFILL']);
export const searchTasksRoutine = createSearchRoutine('SEARCH_TASKS')

export const countTodayTasksRoutine = createSearchRoutine('TODAY_COUNT')
export const countActiveTasksRoutine = createSearchRoutine('ACTIVE_COUNT')
export const countEndedTasksRoutine = createSearchRoutine('ENDED_COUNT')
