import pointer from 'json-pointer';

//for gulp i18next
const t = (x) => x

t('validation:ajv.isNotEmpty')
t('validation:ajv.isNotNull')
t('validation:ajv.type')
t('validation:ajv.false schema')
t('validation:ajv.$ref')
t('validation:ajv.additionalItems')
t('validation:ajv.additionalProperties')
t('validation:ajv.anyOf')
t('validation:ajv.dependencies')
t('validation:ajv.enum')
t('validation:ajv.format')
t('validation:ajv.maximum')
t('validation:ajv.minimum')
t('validation:ajv.exclusiveMaximum')
t('validation:ajv.exclusiveMinimum')
t('validation:ajv.maxItems')
t('validation:ajv.maxItems_plural')
t('validation:ajv.minItems')
t('validation:ajv.minItems_plural')
t('validation:ajv.maxLength')
t('validation:ajv.maxLength_plural')
t('validation:ajv.minLength')
t('validation:ajv.minLength_plural')
t('validation:ajv.maxProperties')
t('validation:ajv.maxProperties_plural')
t('validation:ajv.minProperties')
t('validation:ajv.minProperties_plural')
t('validation:ajv.multipleOf')
t('validation:ajv.not')
t('validation:ajv.oneOf')
t('validation:ajv.pattern')
t('validation:ajv.required')
t('validation:ajv.type')
t('validation:ajv.uniqueItems')
t('validation:ajv.custom')
t('validation:ajv.propertyNames')
t('validation:ajv.patternRequired')
t('validation:ajv.switch')
t('validation:ajv.const')
t('validation:ajv.constvalue')
t('validation:ajv.constvalue_pointer')
t('validation:ajv.contains')
t('validation:ajv.if')

//formatMaximum et formatMinimum ajv-keywords
t('validation:ajv.formatMaximum')
t('validation:ajv.formatMinimum')
t('validation:ajv.formatMaximum_exclusive')
t('validation:ajv.formatMinimum_exclusive')
t('validation:ajv.formatMaximum_date')
t('validation:ajv.formatMaximum_date_exclusive')
t('validation:ajv.formatMinimum_date')
t('validation:ajv.formatMinimum_date_exclusive')
t('validation:ajv.formatMaximum_date-time')
t('validation:ajv.formatMaximum_date-time_exclusive')
t('validation:ajv.formatMinimum_date-time')
t('validation:ajv.formatMinimum_date-time_exclusive')
t('validation:ajv.formatMaximum_time')
t('validation:ajv.formatMaximum_time_exclusive')
t('validation:ajv.formatMinimum_time')
t('validation:ajv.formatMinimum_time_exclusive')


//types
t('validation:ajv.types.string')
t('validation:ajv.types.integer')
t('validation:ajv.types.number')
t('validation:ajv.types.boolean')
t('validation:ajv.types.array')
t('validation:ajv.types.object')
t('validation:ajv.types.null')

//formats
t('validation:ajv.formats.date')
t('validation:ajv.formats.date-time')
t('validation:ajv.formats.time')
t('validation:ajv.formats.uri')
t('validation:ajv.formats.email')
t('validation:ajv.formats.hostname')
t('validation:ajv.formats.ipv4')
t('validation:ajv.formats.ipv6')
t('validation:ajv.formats.regex')
t('validation:ajv.formats.tel')

//moment
t('validation:ajv.moment.D')
t('validation:ajv.moment.M')
t('validation:ajv.moment.Y')
t('validation:ajv.moment.H')
t('validation:ajv.moment.h')
t('validation:ajv.moment.k')
t('validation:ajv.moment.a')
t('validation:ajv.moment.A')
t('validation:ajv.moment.m')
t('validation:ajv.moment.s')

/** Not translated already todo if we use array or object in our forms **/
// false schema
// $ref
// additionalItems
// additionalProperties
// anyOf
// dependencies
// maxProperties
// minProperties
// maxProperties_plural
// minProperties_plural
// not
// oneOf
// custom
// propertyNames
// patternRequired
// switch
// if

const localize = (error, i18next, local_format, schema) => {
  // console.log('localize', error)
  const formatContext = `${error.parentSchema && ['date', 'date-time', 'time'].includes(error.parentSchema.format) ? error.parentSchema.format : ''}`
  const exclusiveContext = `${error.parentSchema && ((error.parentSchema.formatExclusiveMaximum && error.keyword === 'formatMaximum') || (error.parentSchema.formatExclusiveMinimum &&  error.keyword === 'formatMinimum')) ? 'exclusive' : ''}`
  const pointerContext = `${error.keyword === 'const' && pointer.has(schema.properties, error.schema.$data) ? 'pointer' : ''}`
  
  const context = [formatContext, exclusiveContext, pointerContext].filter(Boolean).join('_')
  const count = error.params.limit || 0;
  const constData = error.keyword === 'const' ? pointer.has(schema.properties, error.schema.$data) ? pointer.get(schema.properties, error.schema.$data).title : error.params.allowedValue : ''
  // console.log('t', `validation:ajv.${error.keyword}`, { ...error.params, ...local_format, context, count, constData})
  error.message = i18next.t(`validation:ajv.${error.keyword}`, { ...error.params, ...local_format, context, count, constData} )
}

export const translate = (errors, i18next, moment, schema) => {
  if(!errors || !errors.length )
    return
  const local_format = {
    local_format_time: moment.localeData().longDateFormat('LT').replace(/^([hH]):/, '$1$1:'),
    local_format_date: moment.localeData().longDateFormat('L'),
    local_format_date_time: `${moment.localeData().longDateFormat('L')} ${moment.localeData().longDateFormat('LT').replace(/^([hH]):/, '$1$1:')}`,
  }

  errors.forEach(e => localize(e, i18next, local_format, schema))
}

