import { combineReducers } from 'redux';
import { handleActions, combineActions } from 'redux-actions';
import { getContactRoutine, postContactRoutine, putContactRoutine, deleteContactRoutine, getContactsRoutine, autoSaveContactRoutine } from './routines';

const initialState = {
  auto_save: {
    saving: false,
  },
  contacts: {
    result: [],
    error: null,
    loading: false,
  }
}

export const autoSaveReducer = handleActions({
  [autoSaveContactRoutine.AUTOSAVE_START]: (state, action) => {
    return {
      saving: true
    }
  },
  [autoSaveContactRoutine.AUTOSAVE_END]: (state, action) => {
    return {
      saving: false,
    }
  },
},
initialState.auto_save
)

export const subContactReducer = handleActions({

  [combineActions(getContactRoutine.TRIGGER, getContactsRoutine.TRIGGER, postContactRoutine.TRIGGER, putContactRoutine.TRIGGER, deleteContactRoutine.TRIGGER)]: (state, action) => ({
    ...state,
    loading: true
  }),

  [combineActions(getContactRoutine.SUCCESS, postContactRoutine.SUCCESS, putContactRoutine.SUCCESS)]: (state, action) => ({
    ...state,
  }),

  [getContactsRoutine.SUCCESS] : (state, action) => ({
    ...state,
    result: action.payload.result
  }),

  [deleteContactRoutine.SUCCESS] : (state, action) => ({
    ...state
  }),

  [combineActions(getContactRoutine.FAILURE, getContactsRoutine.FAILURE, postContactRoutine.FAILURE, putContactRoutine.FAILURE, deleteContactRoutine.FAILURE)]: (state, action) => ({
    ...state,
    error: action.payload
  }),

  [combineActions(getContactRoutine.FULFILL, getContactsRoutine.FULFILL, postContactRoutine.FULFILL, putContactRoutine.FULFILL, deleteContactRoutine.FULFILL)]: (state, action) => ({
    ...state,
    loading: false
  }),
},
initialState.contacts,
);

export const contactsReducer = combineReducers({
  contacts: subContactReducer,
  auto_save: autoSaveReducer,
})