import { handleActions, combineActions } from 'redux-actions'
import { loginRoutine, logoutRoutine } from './routines'

const intialStates = {
  token: null,
  user: null,
  error: null,
  loading: false,
}

export const authReducer = handleActions({
  
  [combineActions(loginRoutine.TRIGGER, logoutRoutine.TRIGGER)]: (state, action) => ({
    ...state,
    loading: true,
  }),
  
  [loginRoutine.SUCCESS]: (state, action) => ({
    ...state,
    token: action.payload.token,
    user: action.payload.userId
  }),

  [logoutRoutine.SUCCESS]: (state, action) => ({
    ...state,
    token: null,
    user: null,
  }),

  [loginRoutine.FAILURE]: (state, action) => ({
    ...state,
    token: null,
    user: null,
    error: action.payload
  }),

  [combineActions(loginRoutine.FULFILL, logoutRoutine.FULFILL)]: (state, action) => ({
    ...state,
    loading: false
  }),
  
},
intialStates
);

