import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper'
import { compose } from 'redux'

import { withFullLayout } from '../containers/FullLayout'
import Loading from '../components/Loading'

import { isLoggedInSelector, isNotLoggedInSelector, isAuthenticatingSelector, isCompletedSelector2, hasSubscriptionSelector, hasSubscriptionOrDeclinedSubscriptionSelector, hasGiftSubscriptionOrDeclinedSubscriptionSelector, hasAcceptedCGV} from '../redux/Auth/selectors'
import { usersIsLoadingSelector } from '../redux/Users/selectors'

const LoadingComponent = withFullLayout(Loading);

const locationHelper = locationHelperBuilder({})

export const userIsNotAuthenticated = connectedRouterRedirect({
  // This sends the user either to the query param route if we have one, or to the landing page if none is specified and the user is already logged in
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/',
  // This prevents us from adding the query parameter when we send the user away from the login page
  allowRedirectBack: false,
   // If selector is true, wrapper will not redirect
   // So if there is no user data, then we show the page
  authenticatedSelector: isNotLoggedInSelector,
  authenticatingSelector: isAuthenticatingSelector,
  AuthenticatingComponent: LoadingComponent,
  // A nice display name for this check
  wrapperDisplayName: 'UserIsNotAuthenticated',
  //redirectAction: replace,
})

export const userIsAuthenticated = connectedRouterRedirect({
   // The url to redirect user to if they fail
  redirectPath: '/login',
   // If selector is true, wrapper will not redirect
   // For example let's check that state contains user data
  authenticatedSelector: isLoggedInSelector,
  authenticatingSelector: isAuthenticatingSelector,
  AuthenticatingComponent: LoadingComponent,
  // A nice display name for this check
  wrapperDisplayName: 'UserIsAuthenticated',
  allowRedirectBack: (state, ownProps, b) => {
    return state?.location?.pathname !== '/logout' 
  },
})

export const userIsCompleted = connectedRouterRedirect({
  redirectPath: '/account/user/create',
  allowRedirectBack: false,
  authenticatedSelector: isCompletedSelector2,
  authenticatingSelector: usersIsLoadingSelector,
  AuthenticatingComponent: LoadingComponent,
});

export const userIsNotCompleted = connectedRouterRedirect({
  redirectPath: '/',
  allowRedirectBack: false,
  authenticatedSelector: state => !isCompletedSelector2(state),
  authenticatingSelector: usersIsLoadingSelector,
  AuthenticatingComponent: LoadingComponent,
});

export const userHasSubscription = connectedRouterRedirect({
  redirectPath: '/account/subscription/select',
  allowRedirectBack: false,
  authenticatedSelector: state => hasSubscriptionOrDeclinedSubscriptionSelector(state),// || true,
  authenticatingSelector: usersIsLoadingSelector,
  AuthenticatingComponent: LoadingComponent,
});

export const userHasNoSubscriptionOrDeclined = connectedRouterRedirect({
  redirectPath: '/',
  allowRedirectBack: false,
  authenticatedSelector: state => !hasSubscriptionOrDeclinedSubscriptionSelector(state),// && false,
  authenticatingSelector: usersIsLoadingSelector,
  AuthenticatingComponent: LoadingComponent,
});

export const userHasNoSubscription = connectedRouterRedirect({
  redirectPath: '/',
  allowRedirectBack: false,
  authenticatedSelector: state => !hasSubscriptionSelector(state),// && false,
  authenticatingSelector: usersIsLoadingSelector,
  AuthenticatingComponent: LoadingComponent,
});

export const userHasGiftSubscription = connectedRouterRedirect({
  redirectPath: '/account/subscription/thanks',
  allowRedirectBack: false,
  authenticatedSelector: state => hasGiftSubscriptionOrDeclinedSubscriptionSelector(state),// && false,
  authenticatingSelector: usersIsLoadingSelector,
  AuthenticatingComponent: LoadingComponent,
});

export const userHasNoGiftSubscription = connectedRouterRedirect({
  redirectPath: '/',
  allowRedirectBack: false,
  authenticatedSelector: state => !hasGiftSubscriptionOrDeclinedSubscriptionSelector(state),// && false,
  authenticatingSelector: usersIsLoadingSelector,
  AuthenticatingComponent: LoadingComponent,
});

export const userHasAcceptedCGV = connectedRouterRedirect({
  redirectPath: '/cgv',
  allowRedirectBack: false,
  authenticatedSelector: state => hasAcceptedCGV(state),// && false,
  authenticatingSelector: usersIsLoadingSelector,
  AuthenticatingComponent: LoadingComponent,
});

export const userHasNotAcceptedCGV = connectedRouterRedirect({
  redirectPath: '/',
  allowRedirectBack: false,
  authenticatedSelector: state => !hasAcceptedCGV(state),// && false,
  authenticatingSelector: usersIsLoadingSelector,
  AuthenticatingComponent: LoadingComponent,
});

export const userIsAuthenticatedAndNotCompleted = compose(userIsAuthenticated, userIsNotCompleted)

export const userIsAuthenticatedAndCompleted = compose(userIsAuthenticated, userIsCompleted)

export const userIsAuthenticatedAndCompletedAndNotAcceptedCGV = compose(userIsAuthenticated, userIsCompleted, userHasNotAcceptedCGV)

export const userIsAuthenticatedAndCompletedAndAcceptedCGV = compose(userIsAuthenticated, userIsCompleted, userHasAcceptedCGV)


export const userIsAuthenticatedAndCompletedAndHasSubscription = compose(userIsAuthenticatedAndCompletedAndAcceptedCGV, userHasGiftSubscription, userHasSubscription)

export const userIsAuthenticatedAndCompletedAndHasNoGiftSubscription = compose(userIsAuthenticatedAndCompletedAndAcceptedCGV, userHasNoGiftSubscription)

export const userIsAuthenticatedAndCompletedAndHasNoSubscriptionOrDeclined = compose(userIsAuthenticatedAndCompletedAndAcceptedCGV, userHasNoSubscriptionOrDeclined)

export const userIsAuthenticatedAndCompletedAndHasNoSubscription = compose(userIsAuthenticatedAndCompletedAndAcceptedCGV, userHasNoSubscription)


// export const userIsAuthenticatedAndNotCompleted = connectedRouterRedirect({
//   // The url to redirect user to if they fail
//  redirectPath: (state) => !isLoggedInSelector(state) ? '/login' : '/',
//   // If selector is true, wrapper will not redirect
//   // For example let's check that state contains user data
//  authenticatedSelector: (state) => isLoggedInSelector(state) && !isCompletedSelector(state),
//  authenticatingSelector: isAuthenticatingSelector,
//  AuthenticatingComponent: LoadingComponent,
//  // A nice display name for this check
//  wrapperDisplayName: 'UserIsAuthenticated',
//  allowRedirectBack: false,
// })

// export const userIsAuthenticatedAndCompleted = connectedRouterRedirect({
//   // This sends the user either to the query param route if we have one, or to the landing page if none is specified and the user is already logged in
//   redirectPath: '/account/user/create',
//   // This prevents us from adding the query parameter when we send the user away from the login page
//   allowRedirectBack: false,
//    // If selector is true, wrapper will not redirect
//    // So if there is no user data, then we show the page
//    authenticatedSelector: (state) => !!isLoggedInSelector(state) && !!isCompletedSelector(state),
//    authenticatingSelector: isAuthenticatingSelector,
//    AuthenticatingComponent: LoadingComponent,
//   // A nice display name for this check
//   wrapperDisplayName: 'userIsAuthenticatedAndCompleted',
//   //redirectAction: replace,
// })
