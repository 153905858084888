import { createSelector } from "reselect"

export const autoSaveStatutSelector = (state) => state.diagnostic.auto_save.saving

//on selectionne le "state" des analyses cephalo
const diagnosticsSelector = (state) => state.diagnostic.diagnostics

//on selectionne le "state" de l'analyses cephalo pour le folderId et l'orientation key 'folderId_orientation'
export const makeStateDiagnosticSelector = () => 
  createSelector(
    diagnosticsSelector,
    (_, props) => props,
    (state = {}, { patientId }) => state[patientId]
  )