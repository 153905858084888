import React, {useContext} from 'react'
import { connectField } from 'uniforms'
import { KeyboardDatePicker, MuiPickersContext } from "@material-ui/pickers";
import { useTranslation } from 'react-i18next';

const CustomDatePicker = ({
  className,
  onChange, 
  value, 
  name, 
  label, 
  errorMessage, 
  variant, 
  margin, 
  fullWidth, 
  autoOk, 
  disabled,
  disableFuture,
  disablePast,
  disableToolbar,
  dateFormat,
  id,
  showInlineError,
  required,
  initialValue,
  ...other
}) => {
  //console.log(other)
  const { moment } = useContext(MuiPickersContext);

  const { t } = useTranslation();

  return (    
    <KeyboardDatePicker
      clearable
      name={name}
      label={label}
      value={value? value : null}
      format={moment.localeData().longDateFormat('L')}
      helperText={showInlineError? errorMessage: ''}
      error={Boolean(errorMessage)}
      onChange={(date,value) => {
        onChange(date? date.format('YYYY-MM-DD') : null)
      }}
      id={id}
      inputVariant={variant}
      margin={margin}
      fullWidth={fullWidth}
      autoOk={autoOk}
      disabled={disabled}
      disableFuture={disableFuture}
      disablePast={disablePast}
      disableToolbar={disableToolbar}
      required={required}
      cancelLabel={t('common:button.cancel')}
      clearLabel={t('common:button.clear')}
      okLabel={t('common:button.ok')}
      todayLabel={t('common:button.today')}
      className={className}
    />
    
  );

}

export default connectField(CustomDatePicker)