import { combineReducers } from 'redux'
import { handleActions, combineActions } from 'redux-actions'
import { getPatientRoutine, getPatientsRoutine, postPatientRoutine, putPatientRoutine, tabsPatientRoutine , searchPatientsRoutine, getTagsPatientsRoutine, getExternalPatientRoutine, deletePatientRoutine, autoSavePatientRoutine, searchAdvancedPatientsRoutine, searchAdvancedFilterRoutine} from './routines'

import _ from 'lodash'

const intialState = {
  tabsHistory: {},
  tabs: [],
  patients: {
    result: [],
    searchCount: 0,
    error: null,
    loading: false
  },
  tags: {
    result: [],
    error: null,
    loading: false
  },
  externalLink: {
    loading: false
  },
  auto_save: {
    saving: false,
  },
}

export const autoSaveReducer = handleActions({
  [autoSavePatientRoutine.AUTOSAVE_START]: (state, action) => {
    return {
      saving: true
    }
  },
  [autoSavePatientRoutine.AUTOSAVE_END]: (state, action) => {
    return {
      saving: false,
    }
  },
},
intialState.auto_save
)

export const externalLinkReducer = handleActions({
  
  [getExternalPatientRoutine.TRIGGER]: (state, action) => {
    return {
      loading: true
    }
  },

  [getExternalPatientRoutine.FULFILL]: (state, action) => {
    return {
      loading: false
    }
  },

},
intialState.externalLink
)

export const tabsHistoryReducer = handleActions({
  
  [tabsPatientRoutine.CLOSE]: (state, action) => {
    return _.omit(state, action.payload)
  },

  [tabsPatientRoutine.CLOSE_ALL]: (state, action) => {
    return {}
  },
  
  [tabsPatientRoutine.HISTORY]: (state, action) => {
    return {
      ...state,
      [action.payload.id]: action.payload.pathname
    }
  },

},
intialState.tabsHistory
)

export const tabsReducer = handleActions({
  
  [tabsPatientRoutine.CLOSE]: (state, action) => {
    return _.without(state, action.payload)
  },

  [tabsPatientRoutine.CLOSE_ALL]: (state, action) => {
    return []
  },
  
  [getPatientRoutine.SUCCESS]: (state, action) => {
    return state.includes(action.payload.result) ? state : [...state, action.payload.result]
  },

},
intialState.tabs
)

export const subPatientsReducer = handleActions({
  [combineActions(getPatientRoutine.TRIGGER, getPatientsRoutine.TRIGGER, postPatientRoutine.TRIGGER, putPatientRoutine.TRIGGER, searchPatientsRoutine.TRIGGER, deletePatientRoutine.TRIGGER)]: (state, action) => ({
    ...state,
    loading: true,
  }),

  //same state
  [combineActions(postPatientRoutine.SUCCESS, getPatientsRoutine.SUCCESS, getPatientRoutine.SUCCESS, putPatientRoutine.SUCCESS, deletePatientRoutine.SUCCESS)]: (state, action) => ({
    ...state,
  }),
  
  [searchPatientsRoutine.SUCCESS]: (state, action) => ({
    ...state,
    result: action.payload.result,
    searchCount: action.payload.searchCount
  }),

  [searchPatientsRoutine.CLEAR]: (state, action) => ({
    ...state,
    result: [],
    searchCount: 0,
  }),

  [combineActions(getPatientRoutine.FAILURE, getPatientsRoutine.FAILURE, postPatientRoutine.FAILURE, putPatientRoutine.FAILURE, searchPatientsRoutine.FAILURE, deletePatientRoutine.FAILURE)]: (state, action) => ({
    ...state,
    error: action.payload,
  }),

  [combineActions(getPatientRoutine.FULFILL, getPatientsRoutine.FULFILL, postPatientRoutine.FULFILL, putPatientRoutine.FULFILL, searchPatientsRoutine.FULFILL, deletePatientRoutine.FULFILL)]: (state, action) => ({
    ...state,
    loading: false,
  }),

},
intialState.patients
)

export const tagsReducer = handleActions({
  
  [getTagsPatientsRoutine.TRIGGER]: (state, action) => ({
    ...state,
    loading: true,
  }), 
  
  [getTagsPatientsRoutine.SUCCESS]: (state, action) => ({
    ...state,
    result: action.payload.result
  }), 

  [getTagsPatientsRoutine.FAILURE]: (state, action) => ({
    ...state,
    error: action.payload
  }), 
  
  [getTagsPatientsRoutine.FULFILL]: (state, action) => ({
    ...state,
    loading: false
  }), 

},
intialState.tags
);

export const searchAdvancedPatientsReducer = handleActions({

  [combineActions(searchAdvancedPatientsRoutine.TRIGGER)]: (state, action) => ({
    ...state,
    page: action.payload.page,
    size: action.payload.size,
    loading: true,
  }),
  
  [searchAdvancedPatientsRoutine.SUCCESS]: (state, action) => ({
    ...state,
    ...action.payload
  }),
  
  [searchAdvancedPatientsRoutine.FAILURE]: (state, action) => ({
    ...state,
    error: action.payload,
  }),
  
  [searchAdvancedPatientsRoutine.FULFILL]: (state, action) => ({
    ...state,
    loading: false,
  }),

  [searchAdvancedFilterRoutine.SET]: (state, action) => ({
    ...state,
    filters : {
      ...state.filters,
      [action.payload.id]: action.payload.value
    }
  }),

  [searchAdvancedFilterRoutine.REMOVE]: (state, action) => {
    const {[action.payload.id]: _, ...rest} = state.filters
    return {
      ...state,
      filters : rest
    }
  },

  [searchAdvancedFilterRoutine.RESET]: (state, action) => ({
    ...state,
    filters : {}
  })

}, {
  filters: {},
  result: [],
  page: 0,
  size: 20,
  searchCount: 0,
  error: null,
  loading: false
})

export const patientsReducer = combineReducers({
  tabsHistory: tabsHistoryReducer,
  tabs: tabsReducer,
  patients: subPatientsReducer,
  tags: tagsReducer,
  externalLink: externalLinkReducer,
  auto_save: autoSaveReducer,
  searchAdvancedPatients: searchAdvancedPatientsReducer
})