import { createRoutine, createRoutineCreator} from 'redux-saga-routines';

export const getContactRoutine = createRoutine('GET_CONTACT');
export const getContactsRoutine = createRoutine('GET_CONTACTS'); 

export const postContactRoutine = createRoutine('POST_CONTACT'); 

export const putContactRoutine = createRoutine('PUT_CONTACT');

export const deleteContactRoutine = createRoutine('DELETE_CONTACT'); 

 const createAutoSaveContactRoutine = createRoutineCreator([
  'AUTOSAVE_START',
  'AUTOSAVE_END',
])

export const autoSaveContactRoutine = createAutoSaveContactRoutine('CONTACT_AUTO_SAVE')