import { combineReducers } from 'redux'
import { handleActions, combineActions } from 'redux-actions'
import { getDocumentRoutine , postDocumentRoutine, putDocumentRoutine, getDocumentsRoutine, deleteDocumentRoutine, getFoldertitlesRoutine, documentCacheRoutine, getTemplatesRoutine, postTemplateRoutine, copyTemplateRoutine} from './routines'

import _ from 'lodash'

const initialStates = {
  documents: {
    result: [],
    error: null,
    loading: false,
  },
  folder_titles: {
    result: [],
    error: null,
    loading: false
  },
  cache: {},
  templates: {
    result: [],
    error: null,
    loading: false
  }
}

const cacheReducer = handleActions({
  [documentCacheRoutine.SET]: (state, action) => {
    return {
      ...state,
      [action.payload.id]: {
        url: action.payload.url,
        date: Date.now()
      }
    }
  },
  [documentCacheRoutine.DELETE]: (state, action) => {
    return {
      ...state,
      [action.payload.id]: null
    }
  },
  [documentCacheRoutine.DELETE_ALL]: (state, action) => {
    return _.omitBy(state, (val, key) => key.startsWith(action.payload.id))
  },
},
initialStates.cache
)

export const subDocumentsReducer = handleActions({
  [combineActions(postDocumentRoutine.TRIGGER, getDocumentRoutine.TRIGGER, putDocumentRoutine.TRIGGER, getDocumentsRoutine.TRIGGER, deleteDocumentRoutine.TRIGGER)]: (state, action) => ({
    ...state,
    loading: true,
  }),
  
  //same state
  [combineActions(postDocumentRoutine.SUCCESS, getDocumentRoutine.SUCCESS, putDocumentRoutine.SUCCESS)]: (state, action) => ({
    ...state
  }),  
  
  //delete? same state see saga
  [deleteDocumentRoutine.SUCCESS]: (state, action) => ({
    ...state,
    //documents: _.omit(state.documents, action.payload.id),
  }),

  [getDocumentsRoutine.SUCCESS] : (state, action) => ({
    ...state,
    result: action.payload.result
  }),
   
  [combineActions(postDocumentRoutine.FAILURE, getDocumentRoutine.FAILURE, putDocumentRoutine.FAILURE, getDocumentsRoutine.FAILURE, deleteDocumentRoutine.FAILURE)]: (state, action) => ({
    ...state,
    error: action.payload,
  }),

  [combineActions(postDocumentRoutine.FULFILL, getDocumentRoutine.FULFILL, putDocumentRoutine.FULFILL, getDocumentsRoutine.FULFILL, deleteDocumentRoutine.FULFILL)]: (state, action) => ({
    ...state,
    loading: false,
  }),
},
initialStates.documents
)


export const folderTitlesReducer = handleActions({
   
  [getFoldertitlesRoutine.TRIGGER]: (state, action) => ({
    ...state,
    loading: true
  }), 
  
  [getFoldertitlesRoutine.SUCCESS]: (state, action) => ({
    ...state,
    result: action.payload.result
  }), 

  [getFoldertitlesRoutine.FAILURE]: (state, action) => ({
    ...state,
    error: action.payload
  }), 
  
  [getFoldertitlesRoutine.FULFILL]: (state, action) => ({
    ...state,
    loading: false
  }), 


},
initialStates.folder_titles
);

const templatesReducer = handleActions({
   
  [combineActions(getTemplatesRoutine.TRIGGER, postTemplateRoutine.TRIGGER, copyTemplateRoutine.TRIGGER)]: (state, action) => ({
    ...state,
    loading: true
  }), 
  
  [combineActions(getTemplatesRoutine.SUCCESS)]: (state, action) => ({
    ...state,
    result: action.payload.result
  }),

  [combineActions(postTemplateRoutine.SUCCESS, copyTemplateRoutine.SUCCESS)]: (state, action) => ({
    ...state,
    result: [
      ...state.result,
      action.payload.result
    ]
  }), 

  [combineActions(getTemplatesRoutine.FAILURE, postTemplateRoutine.FAILURE, copyTemplateRoutine.FAILURE)]: (state, action) => ({
    ...state,
    error: action.payload
  }), 
  
  [combineActions(getTemplatesRoutine.FULFILL, postTemplateRoutine.FULFILL, copyTemplateRoutine.FULFILL)]: (state, action) => ({
    ...state,
    loading: false
  }), 


},
initialStates.templates
);

export const documentsReducer = combineReducers({ 
  documents: subDocumentsReducer, 
  folderTitles: folderTitlesReducer,
  cache: cacheReducer,
  templates: templatesReducer,
})