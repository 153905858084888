import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import { takeEvery, call } from 'redux-saga/effects'
import { createRoutineCreator } from 'redux-saga-routines';

const momentFormat = ['D', 'M', 'Y', 'H', 'h', 'k', 'a', 'A', 'm', 's']

export default (moment) => {

  i18n
  // load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    backend: {
      loadPath: '/i18n/{{lng}}/{{ns}}.json',
    },
    
    detection: {
      // order and from where user language should be detected
      order: ['localStorage', 'cookie', 'navigator', 'htmlTag'],

      // keys or params to lookup language from
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18nextLng',
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,

      // cache user language on
      caches: ['localStorage', 'cookie'],
      cookieDomain: process.env.NODE_ENV === 'production' ? 'cephalomax.com' : undefined, 
      
    },
    ns: [
      'common',
      'form',
      'translation',
      'validation',
      'cephalo',
    ],
    
    defaultNS: 'translation',

    fallbackLng: "en",
    
    lowerCaseLng: true,

    cleanCode: true,

    whitelist: ['en', 'fr', 'es'//,],//
    , 'ar'],

    nonExplicitWhitelist: true,
    // load: 'languageOnly',

    //debug: true,
    returnEmptyString: false,
    interpolation: {
      escapeValue: false, // react already safes from xss
      format: function(value, format, lng) {
        if(value instanceof Date) 
          return moment(value).format(format);
        if(moment(value).isValid() && format === 'localize_date')
          return moment(value).format('L')
        if(moment(value).isValid() && format === 'localize_date_time')
          return moment(value).format(`${moment.localeData().longDateFormat('L')} ${moment.localeData().longDateFormat('LT').replace(/^([hH]):/, '$1$1:')}`)
        if(moment(value, 'HH:mm:ss').isValid() && format === 'localize_time')
          return moment(value, 'HH:mm:ss').format(moment.localeData().longDateFormat('LT').replace(/^([hH]):/, '$1$1:'))
        if(format === 'localize_date_scheme')
          return Array.from(value).map(letter => momentFormat.includes(letter) ? i18n.t(`validation:ajv.moment.${letter}`) : letter ).join('')
        return value;
      }
    },
    react: {
      bindI18n: 'languageChanged loaded languageChangedAsync'
    }
  });

  return i18n;
}

const createChangeRoutine = createRoutineCreator(['CHANGE']);
export const localeRoutine = createChangeRoutine('LOCALE')


function* changeLanguageSaga(action) {
  yield call([i18n, 'changeLanguage'], action.payload.locale);
}

export function* watchChangeLanguageSaga() {
  yield takeEvery([localeRoutine.CHANGE, ], changeLanguageSaga);
}

export const localeIsInitialized = (i18next) => {
  return new Promise(resolve => {
    if(i18next.isInitialized)
      resolve(i18next)
    
    i18next.on('initialized', function(options) {
      console.log('initialized', i18next)
      resolve(i18next)
    })
    i18next.store.on('added', function(lng, ns) {
      console.log('added', lng, ns)
    })
    i18next.on('loaded', function(loaded) {
      console.log('loaded', loaded)
    })
    
    
    //let namespaces = i18n.options && i18n.options.ns || i18n.options.defaultNS;
    //namespaces = typeof namespaces === 'string' ? [namespaces] : namespaces || ['translation'];
    
  })
} 

/*
{
type: 'LOCALE/CHANGE',
payload: { locale: 'fr' }
}
*/