export const objectKeysEqualityFn = (keys = []) => (a, b) => {
  return keys.every(key => {
    const splitKey = key.split('.')
    return splitKey.reduce((o,i)=>o?.[i], a) === splitKey.reduce((o,i)=>o?.[i], b)
    //return a[key] === b[key]
  });   
}


export const dictKeysEqualityFn = (keys = []) => (a, b) => {
  const aKeys = Object.keys(a)
  const bKeys = Object.keys(b)
  if(aKeys.length !== bKeys.length)
    return false;
  const eqFn = objectKeysEqualityFn(keys)
  return aKeys.every((value, index) => {
    return eqFn(a[value], b[value])
  });
}

export const collectionKeysEqualityFn = (keys = []) => (a, b) => {
  if(a.length !== b.length)
    return false;
  const eqFn = objectKeysEqualityFn(keys)
  return a.every((value, index) => {
    return eqFn(a[index], b[index])
  });
}