import { isArray, omitBy, mergeWith } from 'lodash'
import { logoutRoutine } from '../Auth/routines'
import { getPatientRoutine } from '../Patients/routines'
import { getPointsRoutine } from '../Cephalos/routines'
const intialState = {
}

function customizer(objValue, srcValue) {
  if (isArray(objValue)) {
    return srcValue;
  }
}

export const entitiesReducer = (state = intialState, action) => {
  //si un utilisateur supprime un document dans une autre fenetre on supprime les anciens documents avant d'ajouter les nouveaux
  if(action.type === getPatientRoutine.SUCCESS)
    state = {
      ...state,
      documents: omitBy(state.documents, (doc) => doc.parent_patient === action.payload.result),
      contacts: omitBy(state.contacts, (contact) => contact.parent_patient === action.payload.result),
    }
  if(action.type === getPointsRoutine.SUCCESS)
    state = {
      ...state,
      cephalo_points : {}
    }
  if(action.payload && action.payload.entities)
    return mergeWith({}, state, action.payload.entities, customizer)
  if(action.type === logoutRoutine.SUCCESS)
    return intialState
  return state
}