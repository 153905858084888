import { createRoutine, promisifyRoutine, createRoutineCreator } from 'redux-saga-routines';

export const getPatientRoutine = createRoutine('GET_PATIENT'); 

export const getPatientsRoutine = createRoutine('GET_PATIENTS'); 


export const postPatientRoutine = createRoutine('POST_PATIENT'); 
export const postPatientRoutinePromiseCreator = promisifyRoutine(postPatientRoutine);

export const deletePatientRoutine = createRoutine('DELETE_PATIENT'); 
export const deletePatientRoutinePromiseCreator = promisifyRoutine(deletePatientRoutine);

export const putPatientRoutine = createRoutine('PUT_PATIENT');
export const putPatientRoutinePromiseCreator = promisifyRoutine(putPatientRoutine);

const createTabsPatientRoutine = createRoutineCreator(['CLOSE', 'CLOSE_ALL', 'HISTORY'])
export const tabsPatientRoutine = createTabsPatientRoutine('TABS_PATIENTS')


const createSearchRoutine = createRoutineCreator(['TRIGGER', 'REQUEST', 'SUCCESS', 'FAILURE', 'FULFILL', 'CLEAR']);
export const searchPatientsRoutine = createSearchRoutine('SEARCH_PATIENTS')

export const getTagsPatientsRoutine = createRoutine('GET_TAGS_PATIENTS');

export const getExternalPatientRoutine = createRoutine('GET_EXTERNAL')
export const getExternalPatientRoutinePromiseCreator = promisifyRoutine(getExternalPatientRoutine);

const createAutoSavePatientRoutine = createRoutineCreator([
  'AUTOSAVE_START',
  'AUTOSAVE_END',
])

export const autoSavePatientRoutine = createAutoSavePatientRoutine('PATIENT_AUTO_SAVE')

export const searchAdvancedPatientsRoutine = createRoutine('SEARCH_ADVANCED_PATIENTS');

const createSearchAdvancedFilterRoutine = createRoutineCreator([
  'SET',
  'REMOVE',
  'RESET',
])
export const searchAdvancedFilterRoutine = createSearchAdvancedFilterRoutine('SEARCH_ADVANCED_FILTER')
