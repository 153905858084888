import { createSelector } from 'reselect'

export const paramsIsLoadingSelector = state => state.params.global.loading
export const paramsIsErrorSelector = state => state.params.global.error

export const paramsSelector = state => state.entities.params

export const makeParamsSelector = () => createSelector(
  paramsSelector,
  (_, props) => props,
  (params = {}, name = "") => {
    return Object.values(params).filter(param => !!param._id && param.name === name).sort((a,b) => a.createdAt < b.createdAt ? -1 : 1)
  }
)

export const localParamsSelector = state => state.params.local

export const makeLocalParamsSelector = () => createSelector(
  localParamsSelector,
  (_, props) => props,
  (params, name) => {
    return params[name] || {}
  }
)

