const schema = (mapName) => ({
  title: 'Patient',
  type: 'object',
  properties: {
    [mapName.firstname]: { 
      title: 'form:patient.firstname',
      type: 'string',
      maxLength: 50,
      isNotEmpty: true,
      uniforms: {
        variant: 'outlined',
        fullWidth: true,
      }
    },
    [mapName.lastname]: { 
      title: 'form:patient.lastname',
      type: 'string',
      maxLength: 50,
      isNotEmpty: true,
      uniforms: {
        variant: 'outlined',
        fullWidth: true,
      }
    },
    [mapName.dateofbirth]: {
      title: 'form:patient.dateofbirth',
      type: ['string', 'null'],
      isNotEmpty: true,
      isNotNull: true,
      format:'date',
      uniforms: {
        variant: 'outlined',
        fullWidth: true,
        margin: 'dense',
      }
    },
    [mapName.gender]: {
      title: 'form:patient.gender',
      type: 'string',
      uniforms: {
        fullWidth: true,
        variant: 'outlined',
        native: true,
      }
    },
    [mapName.tags]: {
      title: 'form:patient.tags',
      type: 'array',
      uniqueItems: true,
      items: {
        type: 'string'
      },
      uniforms: {
        fullWidth: true,
        variant: 'outlined',
      }
    },
    [mapName.collect_accept]: {
      type: 'boolean',
      uniforms: {
        fullWidth: true,
        variant: 'outlined',
        size: 'small',
      }
    },
    [mapName.external_ids]: {
      type: 'array',
      uniqueItems: true,
      items: {
        type: 'object',
        properties: {
          service: {
            type: 'string'
          },
          id: {
            type: 'string'
          }
        }
      },
    },
    [mapName.email]: { 
      title: 'form:patient.email',
      type: 'string',
      format: "email",
      // oneOf: [
      //   { enum: [ "" ] },
      //   { format: "email" }
      // ],
      maxLength: 100,
      uniforms: {
        variant: 'outlined',
        fullWidth: true,
      }
    },
    [mapName.phone]: { 
      title: 'form:patient.phone',
      type: 'string',
      maxLength: 25,
      uniforms: {
        variant: 'outlined',
        fullWidth: true,
      }
    },
    [mapName.adresse1]: { 
      title: 'form:patient.adresse1',
      type: 'string',
      maxLength: 100,
      uniforms: {
        variant: 'outlined',
        fullWidth: true,
      }
    },
    [mapName.adresse2]: { 
      title: 'form:patient.adresse2',
      type: 'string',
      maxLength: 100,
      uniforms: {
        variant: 'outlined',
        fullWidth: true,
      }
    },
    [mapName.postcode]: { 
      title: 'form:patient.postcode',
      type: 'string',
      maxLength: 100,
      uniforms: {
        variant: 'outlined',
        fullWidth: true,
      }
    },
    [mapName.city]: { 
      title: 'form:patient.city',
      type: 'string',
      maxLength: 100,
      uniforms: {
        variant: 'outlined',
        fullWidth: true,
      }
    },
  },
  required: [mapName.firstname, mapName.lastname, mapName.dateofbirth, mapName.gender, mapName.collect_accept]
});

export default schema