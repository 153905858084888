import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

//import Header from '../components/Header'
import PromptIfUpload from '../components/PromptIfUpload';

const Header = React.lazy(() => import('../components/Header'));

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
  },
  content: {
    display: 'flex',
    overflow: 'hidden',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
  }
}));


const AppLayout = (props) => {
  
  const classes = useStyles(); 

  return(
    <div className={classes.root} >
      <Header/>
      <div className={classes.content}>
        {props.children}
      </div>
      <PromptIfUpload />
    </div>
  )
}

export const withAppLayout = (Component) => {

  return (props) => {
    return (
      <AppLayout>
        <Component {...props}/>
      </AppLayout>
    )
  }
}

export default AppLayout