import _ from 'lodash'


export const capitalizeName = (string = "") => {
  let indexes = [];
  _.deburr(string).replace(/\b[a-zA-Z]/g, (x, index) => indexes.push(index));
  let newString = ''
  for (let i = 0; i < string.length; i++) {
    if(indexes.indexOf(i) !== -1)
      newString += string.charAt(i).toUpperCase();
    else
      newString += string.charAt(i).toLowerCase();
  }
  return newString;
}

export const capitalizeInitials = (string) => {
  const initials = _.deburr(string).match(/\b[a-zA-Z]/g) || [];
  return `${initials.join('-').toUpperCase()}.`;
}

export const capitalizeInitialsWithoutDot = (string) => {
  const initials = _.deburr(string).match(/\b[a-zA-Z]/g) || [];
  return `${initials.join('-').toUpperCase()}`;
}

export const capitalizeInitialsWithoutDotAndCaret = (string) => {
  const initials = _.deburr(string).match(/\b[a-zA-Z]/g) || [];
  return `${initials.join('').toUpperCase()}`;
}

export function stringToHslColor(str, s, l) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  var h = hash % 360;
  return 'hsl('+h+', '+s+'%, '+l+'%)';
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}