import { createRoutine, promisifyRoutine} from 'redux-saga-routines';
import { createAction } from 'redux-actions';
export const getUserRoutine = createRoutine('GET_USER'); 
export const getUserRoutinePromiseCreator = promisifyRoutine(getUserRoutine);

export const postUserRoutine = createRoutine('POST_USER'); 
export const postUserRoutinePromiseCreator = promisifyRoutine(postUserRoutine);

export const putUserRoutine = createRoutine('PUT_USER'); 
export const putUserRoutinePromiseCreator = promisifyRoutine(putUserRoutine);

export const putUserSubscriptionDeclineRoutine = createRoutine('PUT_USER_SUBSCRIPTION_DECLINE'); 
export const putUserSubscriptionDeclineRoutinePromiseCreator = promisifyRoutine(putUserSubscriptionDeclineRoutine);

export const putUserSubscriptionAcceptCGVRoutine = createRoutine('PUT_USER_SUBSCRIPTION_ACCEPT_CGV'); 
export const putUserSubscriptionAcceptCGVRoutinePromiseCreator = promisifyRoutine(putUserSubscriptionAcceptCGVRoutine);

export const redirectingAction = createAction('STRIPE_REDIRECTING')