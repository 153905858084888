import { createMuiTheme } from '@material-ui/core/styles';

const palette = {
  primary: { main: '#607D8B', contrastText: '#ffffff' },
  secondary: { main: '#EC407A', contrastText: '#ffffff' },
};

const darkPalette = {
  primary: { main: '#B0BEC5', contrastText: '#ffffff' },
  secondary: { main: '#F8BBD0', contrastText: '#ffffff' },
};

const themeName = 'Lynch French Rose Cow';

export default (locale, rtl, dark) => {

  return createMuiTheme({ 
    palette: {
      ...(dark ? darkPalette : palette),
      type: dark ? 'dark' : 'light'
    },
    mixins: {
      toolbar: {
        minHeight:56,
        "@media (min-width:0px) and (orientation: landscape)": {
          minHeight: 56
        },
        "@media (min-width:600px)":{
          minHeight: 64
        },
      }
    },
    themeName, 
    direction: rtl? 'rtl' : 'ltr'
  }, locale);

}