import React, { useState, useMemo, useRef, useEffect} from 'react';
import { BaseField } from 'uniforms';
import { Box, Avatar, Button, Dialog, DialogActions, DialogContent, IconButton} from '@material-ui/core';
import RotateRightIcon from '@material-ui/icons/RotateRight'; 
import { makeStyles } from "@material-ui/styles";
import { capitalizeInitialsWithoutDot } from "../../utils/string-helpers";
import { useTranslation } from 'react-i18next';
import { Cropper } from 'react-cropper2';
import clsx from 'clsx';

const useStyle = makeStyles(theme => ({
  dialog: {
    "& .cropper-view-box, & .cropper-face" : {
      borderRadius: '50%'
    }
  },
  cancelButton: {
    color: theme.palette.error.main
  }
}))

const CustomAvatarField = ({ name, classes = {}, className, ...rest}, {uniforms}) => {
  //console.log(name, rest, uniforms)
  const localClasses = useStyle()
  const {t} = useTranslation()
  const [img, setImg] = useState()
  //const [croppedImg, setCroppedImg] = useState(value.url)
  const inputRef = useRef()
  const cropperRef = useRef();

  const initials = useMemo(() => `${capitalizeInitialsWithoutDot(uniforms.model?.firstname)}${capitalizeInitialsWithoutDot(uniforms.model?.lastname)}`, [uniforms.model?.firstname, uniforms.model?.lastname])

  const handleOpenDialog = () => {
    handleCancel()
    //setCroppedImg()
    inputRef.current && inputRef.current.click()
  }

  const [rotation, setRotation] = useState(0)
  const handleRotate = () => {
    setRotation(rotation => (rotation+90)%360)
  }
  useEffect(() => {
    if(cropperRef?.current?.cropper)
    cropperRef.current.cropper.rotateTo(rotation)
  }, [rotation])

  const handleAcceptCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    // let canvas;
    // const cropperCanvas = cropper.getCroppedCanvas()
    // if(cropperCanvas.width > 400) {
    //   canvas = document.createElement('canvas');
    //   var ctx = canvas.getContext('2d');
    //   canvas.width = 400
    //   canvas.height = 400
    //   ctx.drawImage(cropperCanvas, 0, 0, 400, 400);
    // } else {
    //   canvas = cropperCanvas
    // }
    // const dataUrl = canvas.toDataURL('image/jpeg', 0.9)

    const dataUrl = cropper.getCroppedCanvas({maxWidth: 400, maxHeight: 400, fillColor: '#fff'}).toDataURL('image/jpeg', 0.9)
    handleCancel()
    uniforms.onChange(name, {url: dataUrl})
  }

  const handleCancel = () => {
    inputRef.current.value = ""
    setImg()
  }

  const handleDelete = () => {
    handleCancel()
    uniforms.onChange(name, undefined)
  }

  return (
    <Box padding={2} display={'flex'} flexDirection={'column'} alignItems={'center'} className={clsx(localClasses.root ,className)}>
      <Box padding={2} >
        {
          uniforms.model?.[name]?.url ? 
            <Avatar className={classes.colorAvatar} style={{backgroundColor: 'none'}} src={uniforms.model?.[name]?.url} /> :
              initials ? 
                <Avatar className={classes.colorAvatar}>{initials}</Avatar> :
                  <Avatar className={classes.colorAvatar}/>
        }
      </Box>
      <Box height={40} display={'flex'} alignItems={'center'} justifyContent={'center'}>
        {
          uniforms.model?.[name]?.url && 
          <Button onClick={handleDelete} className={localClasses.cancelButton}>
            {t('common:button.delete')}
          </Button>
        }
        <Button onClick={handleOpenDialog}>{t('common:interface.edit')}</Button>
      </Box>
      <input
        ref={inputRef}
        accept="image/*"
        id="file-input"
        onChange={({ target: { files } }) => {
          if (files && files[0]) {
            setImg(URL.createObjectURL(files[0]))
            //onChange(URL.createObjectURL(files[0]));
          }
        }}
        style={{ display: 'none' }}
        type="file"
      />
      <Dialog
        open={!!img}
        onClose={handleCancel}
        className={localClasses.dialog}
        scroll="body"
      >
        <DialogContent>
          <Cropper
            aspectRatio={1}
            src={img}
            style={{ height: 300, width: 300, borderRadius: '50%', overflow: 'hidden' }}
            // Cropper.js options
            crop={() => {

            }}
            ready={() => {
              cropperRef.current.cropper.cropped = true;
              cropperRef.current.cropper.cropBox.classList.remove('cropper-hidden')
              cropperRef.current.cropper.setCropBoxData({
                left: 0,
                top: 0,
                //...containerData
                width: 300,
                height: 300,
              });
            }}
            initialAspectRatio={1}
            autoCrop={false}
            checkOrientation={false}
            dragMode="move"
            viewMode={3}
            center={false}
            guides={false}
            highlight={false}
            background={false}
            modal={false}
            wheelZoomRatio={0.05}
            cropBoxMovable={false}
            cropBoxResizable={false}
            toggleDragModeOnDblclick={false}
            restore={false}
            responsive={false}
            minContainerWidth={1}
            minContainerHeight={1}
            minCanvasHeight={1}
            minCanvasWidth={1}
            ref={cropperRef}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} autoFocus className={localClasses.cancelButton}>
            {t('common:button.cancel')}
          </Button>
          <IconButton size="small" onClick={handleRotate}><RotateRightIcon/></IconButton>
          <Box flexGrow={1}/>
          <Button onClick={handleAcceptCrop} color="primary" autoFocus>
            {t('common:button.ok')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );

}

CustomAvatarField.contextTypes = BaseField.contextTypes

export default CustomAvatarField//connectField(CustomAvatarField);