import { Box, Button, Chip, Collapse, Link, ListSubheader, MenuItem, Paper, TableContainer, Table, TableBody, TableRow, TableCell,  TablePagination, makeStyles, useTheme, TableHead, Select, IconButton } from '@material-ui/core';
import React from 'react';
import { useCallback, useRef } from 'react';
import { useState } from 'react';
import { useMap, useToggle } from 'react-use';
import TextField from './Patient/fiche-clinique/styled/TextField';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeSearchAdvancedFiltersSelector, makeSearchAdvancedPatientsSelector } from '../redux/Patients/selectors';
import { useMemo } from 'react';
import { searchAdvancedFilterRoutine, searchAdvancedPatientsRoutine } from '../redux/Patients/routines';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { makeParamsSelector } from '../redux/Params/selectors';
import { collectionKeysEqualityFn, dictKeysEqualityFn } from '../utils/equality';
import PrintIcon from '@material-ui/icons/Print';
import { useReactToPrint } from 'react-to-print';
import { useHistory } from 'react-router';
import shallowCompare from 'react-addons-shallow-compare';
import { isEqual } from 'lodash';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: "flex",
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  filter_chip: {
    marginRight: theme.spacing(1)
  },
  print_table: {
    border: '1px solid black',
    borderCollapse: 'collapse',
    "& th, & td": {
      border: '1px solid black',
      borderCollapse: 'collapse',
    }
  },
  table_row: {
    cursor: "pointer",
    '&:nth-of-type(odd)': {
      //backgroundColor: theme.palette.action.hover,
    },
  }
}))

function getAge(dateofbirth, t) {
  const duration = moment.duration(moment().diff(moment(dateofbirth)))
  const years = duration.years()
  const months = duration.months()
  return  t('common:interface.date', {date: dateofbirth}) + ` (${`${years ? t('common:interface.year', {count: years}): ''} ${months ? t('common:interface.month', {count: months}) : ''}`.trim()})`
}

function NumberOperatorSelect(props) {
  const {t} = useTranslation()
  return (
    <TextField 
      select
      {...props}
    >
      <MenuItem value="eq">{t('advanced_search.Equal to')}</MenuItem>
      <MenuItem value="lt">{t('advanced_search.Less than')}</MenuItem>
      <MenuItem value="lte">{t('advanced_search.Less than or équal to')}</MenuItem>
      <MenuItem value="gt">{t('advanced_search.Greater than')}</MenuItem>
      <MenuItem value="gte">{t('advanced_search.Greater than or équal to')}</MenuItem>
    </TextField>
  )
}

const useFilterFormStyles = makeStyles(theme => ({
  input: {
    width: 250,
    marginRight: theme.spacing(2)
  },

}))

function FilterForm({
  selectedFilter,
  toogleShowAddFilterForm,
  showAddFilterForm,
  filters = {},
  set,
  ...props
}) {
  const classes = useFilterFormStyles()
  const theme = useTheme()
  const [filterForm, setFilterForm] = useState({
    filter: "",
    opetrator: undefined,
    value: undefined,
  })
  const [filter, setFilter] = useState("")
  const [operator, setOperator] = useState("")
  const [value, setValue] = useState("")
  const {t} = useTranslation()
  const paramsSelector = useMemo(makeParamsSelector, [])
  const  timelineParams = useSelector(state => paramsSelector(state, `fiche-clinique-timeline-list`), collectionKeysEqualityFn(['id', 'updatedAt']))
  const timelineElts = useMemo(() => timelineParams[0]?.value || [], [timelineParams])

  useEffect(() => {
    setFilter(filters[selectedFilter]?.filter || "")
    setOperator(filters[selectedFilter]?.operator || "")
    setValue(filters[selectedFilter]?.value || "")
  }, [selectedFilter, showAddFilterForm])

  const handleApplyFilter = () => {
    if(filter === "patient_age")
      set(selectedFilter || uuidv4(), {
        filter,
        operator,
        value,
        params: {
          age: value,
          age_op: operator,
        }
      })
    else if(filter === "patient_sexe")
      set(selectedFilter || uuidv4(), {
        filter,
        value,
        params: {
          gender: value,
        }
      })
    else if(["previous_periode", "current_periode", "next_periode"].includes(filter) && ['exists', 'not_exists'].includes(operator) )
      set(selectedFilter || uuidv4(), {
        filter,
        operator,
        params: {
          [filter+'_exists']: operator === 'exists',
        }
      })
    else if(["previous_periode", "current_periode", "next_periode"].includes(filter) && ['is'].includes(operator) )
      set(selectedFilter || uuidv4(), {
        filter,
        operator,
        value,
        params: {
          [filter+'_name']: value,
        }
      })
    else if(["previous_periode", "current_periode", "next_periode"].includes(filter) && ['eq', 'lt', 'gt'].includes(operator) )
      set(selectedFilter || uuidv4(), {
        filter,
        operator,
        value,
        params: {
          [filter+'_number']: value,
          [filter+'_number_op']: operator,
        }
      })
    else if(["first_contact", "last_contact"].includes(filter))
      set(selectedFilter || uuidv4(), {
        filter,
        operator,
        value,
        params: {
          [filter+'_date']: value,
          [filter+'_op']: operator,
        }
      })
    setFilter("")
    setOperator("")
    setValue("")
    toogleShowAddFilterForm()
  }

  const handleFilterChange = (e) => {
    setFilter(e.target.value)
    if(e.target.value === "patient_age") {
      setOperator("eq")
      setValue(12)
    }
    else if(e.target.value === "patient_sexe") {
      setOperator("eq")
      setValue("male")
    }
    else if(['previous_periode', 'current_periode', 'next_periode'].includes(e.target.value)) {
      setOperator("exists")
    }
    else if(['first_contact', 'last_contact'].includes(e.target.value)) {
      setOperator("eq")
      setValue(moment().format('YYYY-MM-DD'))
    }
  }

  const handlePeriodeOperatorChange = (e) => {
    setOperator(e.target.value)
    if(['eq', 'lt', 'gt'].includes(e.target.value)) {
      setValue(1)
    }
    else if(e.target.value === "is") {
      setValue(timelineElts[0].name)
    }
  }

  return (
    <Collapse in={showAddFilterForm}>
      <Box display="flex" paddingBottom={2}>
        <Box flex={1} display="flex">
          <TextField 
            className={classes.input} 
            select 
            size='small' 
            variant='outlined'  
            value={filter} 
            onChange={handleFilterChange}
            SelectProps={{
              displayEmpty: true,
            }}
          >
            <MenuItem value="" disabled>
              {t('advanced_search.Select filter')}
            </MenuItem>
            <ListSubheader>{t('advanced_search.Patient')}</ListSubheader>
            <MenuItem value="patient_age">{t('advanced_search.Age')}</MenuItem>
            <MenuItem value="patient_sexe">{t('advanced_search.Sex')}</MenuItem>
            <ListSubheader>{t('advanced_search.Périods')}</ListSubheader>
            <MenuItem value="previous_periode">{t('advanced_search.Previous period')}</MenuItem>
            <MenuItem value="current_periode">{t('advanced_search.Current period')}</MenuItem>
            <MenuItem value="next_periode">{t('advanced_search.Next period')}</MenuItem>
            <ListSubheader>{t('advanced_search.Contacts')}</ListSubheader>
            <MenuItem value="first_contact">{t('advanced_search.First contact')}</MenuItem>
            <MenuItem value="last_contact">{t('advanced_search.Last contact')}</MenuItem>
          </TextField>
          {
            filter === "patient_age" &&
            <>
              <NumberOperatorSelect className={classes.input} size='small' variant='outlined' value={operator} onChange={(e) => setOperator(e.target.value)} />
              <TextField className={classes.input} size='small' variant='outlined' type="number" inputProps={{min: "0", max: "99"}} value={value} onChange={(e) => setValue(e.target.value)} />
            </>
          }
          {
            filter === "patient_sexe" &&
            <>
              <TextField className={classes.input} select size='small' variant='outlined' value={value} onChange={(e) => setValue(e.target.value)} >
                <MenuItem value="male">{ t('form:patient.gender.male')}</MenuItem>
                <MenuItem value="female">{t('form:patient.gender.female')}</MenuItem>
              </TextField>
            </>
          }
          {
            ['previous_periode', 'current_periode', 'next_periode'].includes(filter) &&
            <>
              <TextField className={classes.input} select size='small' variant='outlined' value={operator} onChange={handlePeriodeOperatorChange} >
                <MenuItem value="exists">{t('advanced_search.Exist')}</MenuItem>
                <MenuItem value="not_exists">{t('advanced_search.Does not exist')}</MenuItem>
                <MenuItem value="is">{t('advanced_search.is of type')}</MenuItem>
                <MenuItem value="eq">{t('advanced_search.whose number is equal')}</MenuItem>
                <MenuItem value="gt">{t('advanced_search.whose number is greater than')}</MenuItem>
                <MenuItem value="lt">{t('advanced_search.whose number is less than')}</MenuItem>
              </TextField>
              {
                ['eq', 'gt', 'lt'].includes(operator) &&
                <TextField className={classes.input} size='small' variant='outlined' type="number" inputProps={{min: "0", max: "99"}} value={value} onChange={(e) => setValue(e.target.value)} />
              }
              {
               operator === 'is' &&
               <TextField className={classes.input} select size="small" variant='outlined' value={value} onChange={(e) => setValue(e.target.value)}>
                {
                  timelineElts.map(elt => (
                    <MenuItem key={elt.id} value={elt.name}>
                      {elt.name}
                    </MenuItem>
                  ))
                }
               </TextField>
              }
            </>
          }
          {
            ['first_contact', 'last_contact'].includes(filter) &&
            <>
              <NumberOperatorSelect className={classes.input} size='small' variant='outlined' value={operator} onChange={(e) => setOperator(e.target.value)} />
              <TextField className={classes.input} size='small' variant='outlined' type="date" style={{colorScheme: theme.palette.type}} value={value} onChange={(e) => setValue(e.target.value)} />
            </>
          }
        </Box>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <Box width={theme.spacing(2)}></Box>
          <Link component="button" underline="hover" color="inherit" variant='body2' onClick={toogleShowAddFilterForm}>{t('advanced_search.Cancel')}</Link>
          <Box width={theme.spacing(2)}></Box>
          <Button color="secondary" variant="outlined" size="small" onClick={handleApplyFilter}>{t('advanced_search.Apply filter')}</Button>
        </Box>
      </Box>
    </Collapse>
  )
}

const TableToPrint = React.forwardRef(function ({t, result, ...props}, ref) {

  return (
    <table ref={ref} {...props}>
      <style>
        {
          `@media print {
            @page { 
              size: landscape;
              margin: 13mm 13mm 13mm 13mm !important; 
            }
            table, th, td {
              border: 1px solid black;
              border-collapse: collapse;
              color: #000;
            }
          }`
        }
      </style>
      <thead>
        <tr>
          <th rowSpan={2}>
            {t('advanced_search.Lastname')}
          </th>
          <th rowSpan={2}>
            {t('advanced_search.Firstname')}
          </th>
          <th rowSpan={2}>
            {t('advanced_search.Date of birth')}
          </th>
          <th colSpan={2} align='center'>
            {t('advanced_search.Previous period')}
          </th>
          <th colSpan={2} align='center'>
            {t('advanced_search.Current period')}
          </th>
          <th colSpan={2} align='center'>
            {t('advanced_search.Next period')}
          </th>
          <th rowSpan={2}>
            {t('advanced_search.First contact date')}
          </th>
          <th rowSpan={2}>
            {t('advanced_search.Last contact date')}
          </th>
        </tr>
        <tr>
          {/* <th rowSpan={1}/> */}
          <th style={{top: 37}}>
            {t('advanced_search.Type')}
          </th>
          <th style={{top: 37}}>
            {t('advanced_search.Number')}
          </th>
          <th style={{top: 37}}>
            {t('advanced_search.Type')}
          </th>
          <th style={{top: 37}}>
            {t('advanced_search.Number')}
          </th>
          <th style={{top: 37}}>
            {t('advanced_search.Type')}
          </th>
          <th style={{top: 37}}>
            {t('advanced_search.Number')}
          </th>
        </tr>
      </thead>
      <tbody>
        {
          result.map((patient) => (
            <tr key={patient._id}>
              <td component="th" scope="row" >
                {patient.lastname}
              </td>
              <td component="th" scope="row" >
                {patient.firstname}
              </td>
              <td component="th" scope="row" >
                {getAge(patient.dateofbirth, t)}
              </td>
              <td component="th" scope="row" >
                {patient.previous_periode?.name}
              </td>
              <td component="th" scope="row" >
                {patient.previous_periode?.number}
              </td>
              <td component="th" scope="row" >
                {patient.current_periode?.name}
              </td>
              <td component="th" scope="row" >
                {patient.current_periode?.number}
              </td>
              <td component="th" scope="row" >
                {patient.next_periode?.name}
              </td>
              <td component="th" scope="row" >
                {patient.next_periode?.number}
              </td>
              <td component="th" scope="row" >
                {t('common:interface.date', {date: patient.first_contact})}
              </td>
              <td component="th" scope="row" >
                {t('common:interface.date', {date: patient.last_contact})}
              </td>
            </tr>
          ))
        }
      </tbody>
    </table>
  )
})

function AdvancedSearch () {
  const dispatch = useDispatch();
  //const [filters, {set, setAll, remove, reset}] = useMap()
  const theme = useTheme()
  const {t} = useTranslation()
  const classes = useStyles()
  const [showAddFilterForm, toogleShowAddFilterForm] = useToggle(false)
  const [selectedFilter, setSelectedFilter] = useState()
  const history = useHistory()

  const operatrion_map = useMemo(() => ({
    'eq': '=',
    'lt': '<',
    'lte': '<=',
    'gte': '>=',
    'gt': '>',
    'is': ': ',
    'exists': ': ' + t('advanced_search.Exist'),
    'not_exists': ': '+ t('advanced_search.Does not exist'),
    undefined: ':',
  }), [])

  const filter_map = useMemo(() => ({
    'patient_age': t('advanced_search.Age'),
    'patient_sexe': t('advanced_search.Sex'),
    'previous_periode': t('advanced_search.Previous period'),
    'current_periode': t('advanced_search.Current period'),
    'next_periode': t('advanced_search.Next period'),
    'first_contact': t('advanced_search.First contact'),
    'last_contact': t('advanced_search.Last contact'),
  }), [])

  const searchAdvancedPatientsSelector = useMemo(makeSearchAdvancedPatientsSelector, [])
  
  const {
    result = [],
    page = 0,
    size = 20,
    searchCount = 0,
    error = false,
    loading = false,
  } = useSelector(searchAdvancedPatientsSelector)

  const searchAdvancedFiltersSelector = useMemo(makeSearchAdvancedFiltersSelector, [])
  const filters = useSelector(searchAdvancedFiltersSelector, dictKeysEqualityFn(['filter', 'operator', 'value'])) || {}
  
  console.log('filters', filters)
  
  const set = useCallback((id, value) => {
    dispatch(searchAdvancedFilterRoutine.set({
      id,
      value
    }))
  }, [])

  const remove = useCallback((id) => {
    dispatch(searchAdvancedFilterRoutine.remove({id}))
  }, [])

  const reset = useCallback(() => {
    dispatch(searchAdvancedFilterRoutine.reset())
  }, [])

  const search_params = Object.values(filters).reduce((acc, {params}) => ({...acc, ...params}) , {})
  const search_params_str = new URLSearchParams(search_params).toString()
  console.log('search_params', search_params)
  
  useEffect(() => {
    if(!showAddFilterForm)
      setSelectedFilter()
  }, [showAddFilterForm])

  useEffect(() => {
    setSelectedFilter()
    dispatch(searchAdvancedPatientsRoutine.trigger({
      page: 0, 
      size, 
      ...search_params
    }))
  }, [search_params_str])

  const handleChangePage = (event, newPage) => {
    dispatch(searchAdvancedPatientsRoutine.trigger({
      page: newPage, 
      size,
      ...search_params
    }))
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(searchAdvancedPatientsRoutine.trigger({
      page: 0, 
      size: parseInt(event.target.value, 10),
      ...search_params
    }))
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    //onBeforeGetContent: handleOnBeforeGetContent,
  });

  return (
    <div className={classes.root}>
      <Box component={Paper}  display="flex"   flexDirection='column' padding={2} variant="outlined">
        <FilterForm
          filters={filters}
          selectedFilter={selectedFilter}
          set={set}
          showAddFilterForm={showAddFilterForm}
          toogleShowAddFilterForm={toogleShowAddFilterForm}
        />
        <Box  display="flex" >
          <Box flex={1}>
            {
              Object.entries(filters).map(([key,elt]) => (
                <Chip
                  key={key}
                  label={`${filter_map[elt.filter]} ${operatrion_map[elt.operator]} ${
                    elt.filter === 'patient_sexe' ? t('form:patient.gender.'+elt.value) :
                      ['first_contact', 'last_contact'].includes(elt.filter) ? t('common:interface.date', {date: elt.value}) :
                        elt.value ? elt.value : ""
                  }`}
                  variant='outlined'
                  size='small'
                  className={classes.filter_chip}
                  onDelete={() => remove(key)}
                  onClick={() => {
                    setSelectedFilter(key)
                    toogleShowAddFilterForm(true)
                  }}
                />
              ))
            }
          </Box>
          { 
            !showAddFilterForm &&
            <Box display="flex" justifyContent="flex-end">
              <Box width={theme.spacing(2)}></Box>
              { Object.keys(filters).length > 0 &&
                <Link component="button" underline="hover" color="inherit" variant='body2' onClick={reset}>{t('advanced_search.Reset')}</Link>
              }
              <Box width={theme.spacing(2)}></Box>
              <Button variant="outlined" size="small" onClick={toogleShowAddFilterForm}>{t('advanced_search.Add filter')}</Button>
              <Box marginLeft={1}>
                <IconButton size='small' onClick={handlePrint}>
                  <PrintIcon/>
                </IconButton>
              </Box>
            </Box>
          }
        </Box>
      </Box>
      <Box flex={1} display="flex" flexDirection="column" overflow="hidden">
        <Box component={TableContainer} flex={1}>
          <Table
            className={classes.table}
            stickyHeader
            size={'small'}
          >
            <TableHead>
              <TableRow>
                <TableCell rowSpan={2}>
                  {t('advanced_search.Lastname')}
                </TableCell>
                <TableCell rowSpan={2}>
                  {t('advanced_search.Firstname')}
                </TableCell>
                <TableCell rowSpan={2}>
                  {t('advanced_search.Date of birth')}
                </TableCell>
                <TableCell colSpan={2} align='center'>
                  {t('advanced_search.Previous period')}
                </TableCell>
                <TableCell colSpan={2} align='center'>
                  {t('advanced_search.Current period')}
                </TableCell>
                <TableCell colSpan={2} align='center'>
                  {t('advanced_search.Next period')}
                </TableCell>
                <TableCell rowSpan={2}>
                  {t('advanced_search.First contact date')}
                </TableCell>
                <TableCell rowSpan={2}>
                  {t('advanced_search.Last contact date')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{top: 37}}>
                  {t('advanced_search.Type')}
                </TableCell>
                <TableCell style={{top: 37}}>
                  {t('advanced_search.Number')}
                </TableCell>
                <TableCell style={{top: 37}}>
                  {t('advanced_search.Type')}
                </TableCell>
                <TableCell style={{top: 37}}>
                  {t('advanced_search.Number')}
                </TableCell>
                <TableCell style={{top: 37}}>
                  {t('advanced_search.Type')}
                </TableCell>
                <TableCell style={{top: 37}}>
                  {t('advanced_search.Number')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                result.map((patient) => (
                  <TableRow key={patient._id} hover  onClick={() => history.push(`/patient/${patient._id}`)} className={classes.table_row}>
                    <TableCell component="th" scope="row" >
                      {patient.lastname}
                    </TableCell>
                    <TableCell component="th" scope="row" >
                      {patient.firstname}
                    </TableCell>
                    <TableCell component="th" scope="row" >
                      {getAge(patient.dateofbirth, t)}
                    </TableCell>
                    <TableCell component="th" scope="row" >
                      {patient.previous_periode?.name}
                    </TableCell>
                    <TableCell component="th" scope="row" >
                      {patient.previous_periode?.number}
                    </TableCell>
                    <TableCell component="th" scope="row" >
                      {patient.current_periode?.name}
                    </TableCell>
                    <TableCell component="th" scope="row" >
                      {patient.current_periode?.number}
                    </TableCell>
                    <TableCell component="th" scope="row" >
                      {patient.next_periode?.name}
                    </TableCell>
                    <TableCell component="th" scope="row" >
                      {patient.next_periode?.number}
                    </TableCell>
                    <TableCell component="th" scope="row" >
                      {t('common:interface.date', {date: patient.first_contact})}
                    </TableCell>
                    <TableCell component="th" scope="row" >
                      {t('common:interface.date', {date: patient.last_contact})}
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </Box>
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={searchCount}
          rowsPerPage={size}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Box>
      <div style={{ display: "none" }}><TableToPrint ref={componentRef} result={result} t={t} /></div>
    </div>
  );
}

export default AdvancedSearch