import { put, select, call, all, fork, take, race, delay} from "@redux-saga/core/effects";
import { takeLatest, takeEvery } from "redux-saga/effects";
import { normalize } from "normalizr";
import { getToken, contacts } from "../../utils/api";
import { 
  getContactRoutine,
  getContactsRoutine,
  postContactRoutine,
  putContactRoutine,
  deleteContactRoutine,
  autoSaveContactRoutine,
} from "./routines";
import { contact } from "./../schemas";
import { getPatientRoutine } from "../Patients/routines";
import { makePendingContactSelector } from "./selectors";

const takeEveryContactAutoSave = () => fork(function*() {
  while (true) {
    const action = yield take(autoSaveContactRoutine.AUTOSAVE_START)
    yield fork(saveDebounce, 3000, action.type, putContactSaga, action)
  }
})

function* saveDebounce (ms, pattern, task, action) {
  const { debounced } = yield race({
    debounced: delay(ms),
    latestAction: take((newAction) => newAction.type === pattern && newAction.payload.id === action.payload.id),
  })

  if (debounced) {
    yield fork(task, action)
  }
  else {
    //yield put(diagnosticRoutine.autosaveEnd())
  }

}

function* getContactSaga(action) {
  yield put(getContactRoutine.request())

  try {
    const token = yield select(getToken);

    const { data } = yield call(contacts.get, token, action.payload.id);

    const normalizedData = yield call(normalize, data, contacts);

    yield put(getContactRoutine.success(normalizedData));
  }
  catch(error) {
    console.log(error)
    yield put(getContactRoutine.failure(error));
  }

  yield put(getContactRoutine.fulfill());
}

function* watchGetContact() {
  yield takeLatest(getContactRoutine.TRIGGER, getContactSaga)
}

function* getContactsSaga(action) {
  yield put(getContactsRoutine.request())

  try {
    const token = yield select(getToken);

    const { data } = yield call(contacts.gets, token, action.payload.ids);

    const normalizedData = yield call(normalize, data, [contact]);

    yield put(getContactsRoutine.success(normalizedData));
  }
  catch(error) {
    console.log(error)
    yield put(getContactsRoutine.failure(error));
  }

  yield put(getContactsRoutine.fulfill());
}

function* watchGetContacts() {
  yield takeLatest(getContactsRoutine.TRIGGER, getContactsSaga)
}

function* postContactSaga(action) {

  yield put(postContactRoutine.request())

  try {

    const token = yield select(getToken);

    const { data } = yield call(contacts.post, token, action.payload.values);

    const normalizedData = yield call(normalize, data, contact);

    yield put(postContactRoutine.success(normalizedData));

  }
  catch(error) {
    console.log(error)
    yield put(postContactRoutine.failure(error));
  } 

  yield put(postContactRoutine.fulfill());
  yield put(autoSaveContactRoutine.autosaveEnd())
}

function* watchPostContact() {
  yield takeLatest(postContactRoutine.TRIGGER, postContactSaga)
}

function* putContactSaga(action) {
  
  yield put(putContactRoutine.request())

  try {

    const token = yield select(getToken);

    const { data } = yield call(contacts.put, token, action.payload.id, action.payload.values);

    const normalizedData = yield call(normalize, data, contact);

    yield put(putContactRoutine.success(normalizedData));

    yield createPendingAfterContact(data)
  }
  catch(error) {
    console.log(error)
    yield put(putContactRoutine.failure(error));
  }

  yield put(putContactRoutine.fulfill());
  yield put(autoSaveContactRoutine.autosaveEnd())
}

function* watchPutContact() {
  yield takeLatest(putContactRoutine.TRIGGER, putContactSaga)
}

function* deleteContactSaga(action) {

  console.log(action.payload)
  
  yield put(deleteContactRoutine.request())

  try {

    const token = yield select(getToken);

    const { data } = yield call(contacts.delete, token, action.payload.id);

    const normalizedData = yield call(normalize, data, contact);

    Object.keys(normalizedData.entities).forEach(key => {
      if(normalizedData.entities[key][normalizedData.result])
        normalizedData.entities[key][normalizedData.result]._id = null
    })

    yield put(deleteContactRoutine.success(normalizedData));

    yield createPendingAfterContact(data)
  }
  catch(error) {
    console.log(error)
    yield put(deleteContactRoutine.failure(error));
  } 

  yield put(deleteContactRoutine.fulfill());
}

function* watchDeleteContact() {
  yield takeLatest(deleteContactRoutine.TRIGGER, deleteContactSaga)
}


const pendingContactSelector = makePendingContactSelector()
function* createPendingAfterContact(contact) {
  const pendingContact = yield select(pendingContactSelector, contact.parent_patient.id)
  if(!pendingContact)
    yield put(postContactRoutine.trigger({values: {
      parent_patient: contact.parent_patient.id
    }}));
}

function* createPendingContact(action) {
  const { contacts = {} } = action.payload.entities
  const pendingExist = Object.values(contacts).some(contact => !contact.date)
  if(!pendingExist)
    yield put(postContactRoutine.trigger({values: {
      parent_patient: action.payload.result
    }}));
}

function* watchCreatePendingContact() {
  yield takeEvery(getPatientRoutine.SUCCESS, createPendingContact)
}

function* watchContact() {
  yield all([
    watchGetContact(),
    watchGetContacts(),
    watchPostContact(),
    watchPutContact(),
    takeEveryContactAutoSave(),
    watchDeleteContact(),
    watchCreatePendingContact(),
  ])
}

export default watchContact;