import { put, select, call, all } from "@redux-saga/core/effects";
import { takeLatest } from "redux-saga/effects";
import { normalize } from "normalizr";
import { getToken, tasks } from "../../utils/api";
import { 
  getTaskRoutine,
  getTasksRoutine,
  postTaskRoutine,
  putTaskRoutine,
  deleteTaskRoutine,
  searchTasksRoutine,
  countTodayTasksRoutine,
  countEndedTasksRoutine,
  countActiveTasksRoutine,
} from "./routines";
import { task } from "./../schemas";
import { tasksResultSelector } from "./selectors";
import moment from "moment";

export const LIMIT_RESULT = 30

function* searchTasksSaga(action) {
  yield put(searchTasksRoutine.request())

  try {

    const token = yield select(getToken);
    console.log(action.payload)
    const [{ data }, {data: searchCount}] = yield all([
      call(tasks.search, token, {due_date: action.payload.due_date, filter: action.payload.filter , start: action.payload.start || 0, limit: LIMIT_RESULT}),
      call(tasks.search_count, token, {due_date: action.payload.due_date, filter: action.payload.filter})
    ])

    let normalizedData = yield call(normalize, data, [task]);
    normalizedData.searchCount = searchCount
    
    if(action.payload.start) {
      const previousResults = yield select(tasksResultSelector);
      normalizedData = {
        ...normalizedData,
        result: [...previousResults, ...normalizedData.result]
      }
    }
      

    yield put(searchTasksRoutine.success(normalizedData));

  }
  catch(error) {
    console.log(error)
    yield put(searchTasksRoutine.failure(error));
  } 

  yield put(searchTasksRoutine.fulfill());
}

function* watchSearchTasks() {
  yield takeLatest(searchTasksRoutine.TRIGGER, searchTasksSaga)
}

function* getTaskSaga(action) {
  yield put(getTaskRoutine.request())

  try {
    const token = yield select(getToken);

    const { data } = yield call(tasks.get, token, action.payload.id);

    const normalizedData = yield call(normalize, data, tasks);

    yield put(getTaskRoutine.success(normalizedData));
  }
  catch(error) {
    console.log(error)
    yield put(getTaskRoutine.failure(error));
  }

  yield put(getTaskRoutine.fulfill());
}

function* watchGetTask() {
  yield takeLatest(getTaskRoutine.TRIGGER, getTaskSaga)
}

function* getTasksSaga(action) {
  yield put(getTasksRoutine.request())

  try {
    const token = yield select(getToken);

    const { data } = yield call(tasks.gets, token, action.payload.ids);

    const normalizedData = yield call(normalize, data, [task]);

    yield put(getTasksRoutine.success(normalizedData));
  }
  catch(error) {
    console.log(error)
    yield put(getTasksRoutine.failure(error));
  }

  yield put(getTasksRoutine.fulfill());
}

function* watchGetTasks() {
  yield takeLatest(getTasksRoutine.TRIGGER, getTasksSaga)
}

function* postTaskSaga(action) {

  yield put(postTaskRoutine.request())

  try {

    const token = yield select(getToken);

    const { data } = yield call(tasks.post, token, action.payload.values);

    const normalizedData = yield call(normalize, data, task);

    yield put(postTaskRoutine.success(normalizedData));

  }
  catch(error) {
    console.log(error)
    yield put(postTaskRoutine.failure(error));
  } 

  yield put(postTaskRoutine.fulfill());
}

function* watchPostTask() {
  yield takeLatest(postTaskRoutine.TRIGGER, postTaskSaga)
}

function* putTaskSaga(action) {
  
  yield put(putTaskRoutine.request())

  try {

    const token = yield select(getToken);

    const { data } = yield call(tasks.put, token, action.payload.id, action.payload.values);

    const normalizedData = yield call(normalize, data, task);

    yield put(putTaskRoutine.success(normalizedData));

  }
  catch(error) {
    console.log(error)
    yield put(putTaskRoutine.failure(error));
  }

  yield put(putTaskRoutine.fulfill());
}

function* watchPutTask() {
  yield takeLatest(putTaskRoutine.TRIGGER, putTaskSaga)
}

function* deleteTaskSaga(action) {

  console.log(action.payload)
  
  yield put(deleteTaskRoutine.request())

  try {

    const token = yield select(getToken);

    const { data } = yield call(tasks.delete, token, action.payload.id);

    const normalizedData = yield call(normalize, data, task);

    Object.keys(normalizedData.entities).forEach(key => {
      if(normalizedData.entities[key][normalizedData.result])
        normalizedData.entities[key][normalizedData.result]._id = null
    })

    yield put(deleteTaskRoutine.success(normalizedData));

  }
  catch(error) {
    console.log(error)
    yield put(deleteTaskRoutine.failure(error));
  } 

  yield put(deleteTaskRoutine.fulfill());
}

function* watchDeleteTask() {
  yield takeLatest(deleteTaskRoutine.TRIGGER, deleteTaskSaga)
}


function* countTodayTask() {
  yield put(countTodayTasksRoutine.request())

  try {

    const token = yield select(getToken);

    const { data } = yield call(tasks.search_count, token, {due_date: moment().format('YYYY-MM-DD'), filter: 'ACTIVE'});

    yield put(countTodayTasksRoutine.success(data));

  }
  catch(error) {
    console.log(error)
    yield put(countTodayTasksRoutine.failure(error));
  } 

  yield put(countTodayTasksRoutine.fulfill());
}

function* watchCountTodayTask() {
  yield takeLatest(countTodayTasksRoutine.TRIGGER, countTodayTask)
}

function* countEndedTask(action) {
  yield put(countEndedTasksRoutine.request())

  try {

    const token = yield select(getToken);

    const { data } = yield call(tasks.search_count, token, {filter: 'ENDED' , due_date: action.payload.due_date});

    yield put(countEndedTasksRoutine.success(data));

  }
  catch(error) {
    console.log(error)
    yield put(countEndedTasksRoutine.failure(error));
  } 

  yield put(countEndedTasksRoutine.fulfill());
}

function* watchCountEndedTask() {
  yield takeLatest(countEndedTasksRoutine.TRIGGER, countEndedTask)
}

function* countActiveTask(action) {
  yield put(countActiveTasksRoutine.request())

  try {

    const token = yield select(getToken);

    const { data } = yield call(tasks.search_count, token, {filter: 'ACTIVE', due_date: action.payload.due_date});

    yield put(countActiveTasksRoutine.success(data));

  }
  catch(error) {
    console.log(error)
    yield put(countActiveTasksRoutine.failure(error));
  } 

  yield put(countActiveTasksRoutine.fulfill());
}

function* watchCountActiveTask() {
  yield takeLatest(countActiveTasksRoutine.TRIGGER, countActiveTask)
}

function* watchTask() {
  yield all([
    watchGetTask(),
    watchGetTasks(),
    watchPostTask(),
    watchPutTask(),
    watchDeleteTask(),
    watchSearchTasks(),
    watchCountActiveTask(),
    watchCountEndedTask(),
    watchCountTodayTask(),
  ])
}

export default watchTask;