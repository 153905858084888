import { combineReducers } from 'redux'
import { handleActions, combineActions } from 'redux-actions'
import { getParamRoutine, postParamRoutine, putParamRoutine, deleteParamRoutine, getParamsRoutine, localParamsRoutine } from './routines'


const initialState = {
  local: {},
  global: {
    result: [],
    error: null,
    loading: false,
  },
}

export const localParamsReducer = handleActions({
  
  [combineActions(localParamsRoutine.TRIGGER)]: (state, action) => ({
    ...state,
    ...action.payload
  }),

},
initialState.local
)

export const globalParamsReducer = handleActions({
  
  [combineActions(getParamRoutine.TRIGGER, postParamRoutine.TRIGGER, putParamRoutine.TRIGGER, deleteParamRoutine.TRIGGER, getParamsRoutine.TRIGGER)]: (state, action) => ({
    ...state,
    loading: true,
  }),
  
  //same state
  [combineActions(getParamRoutine.SUCCESS, postParamRoutine.SUCCESS, putParamRoutine.SUCCESS)]: (state, action) => ({
    ...state,
  }),

  //delete? same state see saga
  [deleteParamRoutine.SUCCESS]: (state, action) => ({
    ...state,
  }),

  [getParamsRoutine.SUCCESS] : (state, action) => ({
    ...state,
    result: action.payload.result
  }),

  [combineActions(getParamRoutine.FAILURE, postParamRoutine.FAILURE, putParamRoutine.FAILURE, deleteParamRoutine.FAILURE, getParamsRoutine.FAILURE)]: (state, action) => ({
    ...state,
    error: action.payload,
  }),
  
  [combineActions(getParamRoutine.FULFILL, postParamRoutine.FULFILL, putParamRoutine.FULFILL, deleteParamRoutine.FULFILL, getParamsRoutine.FULFILL)]: (state, action) => ({
    ...state,
    loading: false,
  }),
  
},
initialState.global
);

export const paramsReducer = combineReducers({ 
  local: localParamsReducer, 
  global: globalParamsReducer,
})