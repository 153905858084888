import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default ({containerClassName ,...props}) => {
  
  const classes = useStyles()

  return (
    <div className={`${classes.root} ${containerClassName}`} >
      <CircularProgress />
    </div>
  )
}