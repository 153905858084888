import React, { Suspense } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useTheme } from '@material-ui/core/styles';

import {Helmet} from "react-helmet";

import { Route, Switch } from 'react-router-dom'

import Search from './components/LazySearch'
//import Patient from './components/Patient/Patient'
import Home from './components/Home'
import Login from './components/Login'
import { withFullLayout } from './containers/FullLayout'
import { withAppLayout } from './containers/AppLayout'
import Loading from './components/Loading'
import AlertDialog from './components/AlertDialog'
import NotFound from './components/404'
//import Presentation from './components/Presentation'



import  { userIsNotAuthenticated, userIsAuthenticatedAndNotCompleted, userIsAuthenticatedAndCompleted, userIsAuthenticatedAndCompletedAndHasNoSubscription, userIsAuthenticatedAndCompletedAndHasNoSubscriptionOrDeclined, userIsAuthenticatedAndCompletedAndHasSubscription, userIsAuthenticatedAndCompletedAndHasNoGiftSubscription, userIsAuthenticatedAndCompletedAndNotAcceptedCGV, userIsAuthenticatedAndCompletedAndAcceptedCGV} from './utils/auth'

import Account from './components/Account/Account';
import Redirecting from './components/Redirecting';
import Offline from './components/Offline';
import CGV from './components/Account/CGV';
import CookieConsent from './components/CookieConsent';
import AdvancedSearch from './components/AdvancedSearch';

const Patient = React.lazy(() => import('./components/Patient/Patient'));
// const PatientMobile = React.lazy(() => import('./components/Patient/PatientMobile'));
// const Account = React.lazy(() => import('./components/Account/Account'));
// const CGV  = React.lazy(() => import('./components/Account/CGV'));
const Presentation = React.lazy(() => import('./components/Presentation'));
//const Login = React.lazy(() => import('./components/Login'));

function App() {
  
  const theme = useTheme()

  return (
    <div className="App">
      <CssBaseline />
      <Helmet>
        <meta name="theme-color" content={theme.palette.type === 'light' ? theme.palette.grey[100] : theme.palette.grey[900]} />
      </Helmet>
      <Suspense fallback={withFullLayout(Loading)()}>
        <Switch>                
          <Route exact path="/login" component={userIsNotAuthenticated(withFullLayout(Login))} />
          <Route exact path="/presentation" component={userIsAuthenticatedAndCompletedAndHasSubscription(withFullLayout(Presentation))} />
          <Route exact path="/callback" component={userIsNotAuthenticated(withAppLayout(Loading))} />
          <Route exact path="/account/user/create" component={userIsAuthenticatedAndNotCompleted(withFullLayout(Account))} />
          <Route exact path="/account/subscription/select" component={userIsAuthenticatedAndCompletedAndHasNoSubscriptionOrDeclined(withFullLayout(Account))} />
          <Route exact path="/account/subscription/select/modal" component={userIsAuthenticatedAndCompletedAndHasNoSubscription(withFullLayout(Account))} /> 
          <Route exact path="/account/subscription/thanks" component={userIsAuthenticatedAndCompletedAndHasNoGiftSubscription(withFullLayout(Account))} />
          <Route path="/account" component={userIsAuthenticatedAndCompleted(withFullLayout(Account))} /> 
          <Route exact path="/cgv" component={userIsAuthenticatedAndCompletedAndNotAcceptedCGV(withFullLayout(CGV))} />
          <Route exact path="/cgv/:version" component={userIsAuthenticatedAndCompletedAndAcceptedCGV(withFullLayout(CGV))} />
          <Route path="/"
            component={userIsAuthenticatedAndCompletedAndHasSubscription(withAppLayout((props) => (
              <Switch>
                <Route exact path="/" children={<Home/>} />
                <Route exact path="/search/:search"
                  component={Search} 
                />
                <Route exact path="/patient-list"
                  component={Search} 
                />
                <Route exact path="/advanced-search"
                  component={AdvancedSearch} 
                />
                 <Route path="/account"
                  component={Account} 
                />
                <Route exact path="/patient/:id/(.*)?">
                  <Route exact path="/patient/:id/:detail?/:folder?/:document?/(.*)?" component={Patient} />
                </Route>
                <Route component={NotFound} />
              </Switch>
            )))}
          />
        </Switch>
        <AlertDialog/>
        <Redirecting/>
        <Offline/>
        <CookieConsent  />
      </Suspense>
    </div>
  );
}

export default App;
