import { createSelector } from "reselect"
import { schema as userdataSchema } from '../../components/ProfileForm'
import Ajv from 'ajv';
import ajvKeywords from 'ajv-keywords'

import { latest } from "../../components/Account/cgv/index";
import { parsePhoneNumber } from 'libphonenumber-js';

const ajv = new Ajv()

ajvKeywords(ajv, ['formatMinimum', 'formatMaximum'])

ajv.addKeyword('isNotEmpty', {
  type: 'string',
  validate: function (schema, data) {
    return (typeof data === 'string' && data.trim() !== '') && data !== null
  },
  errors: false
})

ajv.addKeyword('isNotNull', {
  type: 'null',
  validate: function (schema, data) {
    return data !== null
  },
  errors: false
})

ajv.addFormat('tel', maybePhoneNumber => {
  try {
    const result = parsePhoneNumber(maybePhoneNumber);
    return result.isValid();
  } catch (err) {
    return false;
  }
});

ajv.addFormat('email',  (mail) => {
  if(mail === "") {
    return true;
  }
 if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
  {
    return (true)
  }
    return (false)
});

const validateUserData =  ajv.compile(userdataSchema)

export const tokenSelector = state => state.auth.token
export const loggedInUserSelector = state => state.auth.user

export const isLoggedInSelector = state => !!state.auth.token && !!state.auth.user
export const isNotLoggedInSelector = state => !state.auth.token || !state.auth.user

export const isAuthenticatingSelector = state => state.auth.loading

export const isCompletedSelector = state => !!state.auth.user && !!state.entities?.users?.[state.auth.user]?.infos

export const isCompletedSelector2 = createSelector(
  state => state.auth.user,
  state => state.entities?.users,
  state => state.entities?.infos,
  (userId, users = {}, infos = {}) => {
    //not connected
    if(!userId)
      return false
    //not created
    const infoId = users[userId]?.infos
    if(!infoId)
      return false
    //not validated against schema
    const userInfos = infos[infoId] 
    const validated = validateUserData(userInfos)
    if(!validated)
      return false
      
    //all is good here
    return true
  }
)



export const hasSubscriptionSelector = state => 
  !!state.auth.user && 
    !!state.entities?.users?.[state.auth.user]?.stripe_subscription && 
      Date.now() < (state.entities?.subscriptions?.[state.entities?.users?.[state.auth.user]?.stripe_subscription]?.subscription_data?.current_period_end + 60*60*24*2) * 1000

export const hasDeclinedSubscriptionSelector = state => 
!!state.auth.user && 
  !!state.entities?.users?.[state.auth.user]?.subscription_decline

export const hasSubscriptionOrDeclinedSubscriptionSelector = state => hasSubscriptionSelector(state) || hasDeclinedSubscriptionSelector(state)

export const hasGiftSelector = state => 
  !!state.auth.user && 
    !!state.entities?.users?.[state.auth.user]?.gift && 
      new Date() < new Date(state.entities?.gifts?.[state.entities?.users?.[state.auth.user]?.gift]?.end)

export const hasGiftSubscriptionOrDeclinedSubscriptionSelector = state => (hasGiftSelector(state) && hasDeclinedSubscriptionSelector(state)) || !hasGiftSelector(state)

export const hasAcceptedCGV = state =>
!!state.auth.user && 
  !!state.entities?.users?.[state.auth.user]?.cgv_accept &&
    !!state.entities?.users?.[state.auth.user]?.cgv_accept[latest]

const subscriptionSelector = state =>  state.entities?.subscriptions?.[state.entities?.users?.[state.auth?.user]?.stripe_subscription]
const giftSelector = state => state.entities?.gifts?.[state.entities?.users?.[state.auth?.user]?.gift]

export const makeSubcriptionSelector = () => createSelector(
  subscriptionSelector,
  giftSelector,
  (subscription, gift ) => {
    return {
      subscription: subscription && { ...subscription.subscription_data, ...subscription},
      gift: gift,
    }
  }
)
