export const latest = "1.0.0"
const cgvMap = {
  "1.0.0":  {
    url: require(`./1.0.0.md`),
    date: '2022-04-15',
  },
  "1.0.1": {
    url: require(`./1.0.1.md`),
    date: '2022-04-17',
  },
}

cgvMap.latest = cgvMap[latest]

export default cgvMap
