import React,  { createContext } from 'react'
import Ajv from 'ajv';
//import ajvErrors from 'ajv-errors'
import ajvKeywords from 'ajv-keywords'

import { filterDOMProps } from 'uniforms';
import { translate } from '../ajv-i18n'

import { appendErrors } from 'react-hook-form';
import { toNestError, validateFieldsNatively } from '@hookform/resolvers';

import { parsePhoneNumber } from 'libphonenumber-js';


const ajv = new Ajv({ allErrors: true, useDefaults: true, jsonPointers: true, verbose: true, $data: true});
//ajvErrors(ajv)
ajvKeywords(ajv, ['formatMinimum', 'formatMaximum'])

ajv.addKeyword('isNotEmpty', {
  type: 'string',
  validate: function (schema, data) {
    return (typeof data === 'string' && data.trim() !== '') && data !== null
  },
  errors: false
})

ajv.addKeyword('isNotNull', {
  type: 'null',
  validate: function (schema, data) {
    return data !== null
  },
  errors: false
})

ajv.addFormat('tel', maybePhoneNumber => {
  try {
    const result = parsePhoneNumber(maybePhoneNumber);
    return result.isValid();
  } catch (err) {
    return false;
  }
});

ajv.addFormat('email',  (mail) => {
  if(mail === "") {
    return true;
  }
 if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
  {
    return (true)
  }
    return (false)
});

filterDOMProps.register('isNotEmpty')
filterDOMProps.register('oneOf')

function createCreateValidator(i18next, moment) {
  return schema => {
    const validator = ajv.compile(schema);
    return model => {
      validator(model);
      if (validator.errors && validator.errors.length) {
        translate(validator.errors, i18next, moment, schema)
        throw { details: validator.errors };
      }
    };
  }
}

const parseErrorSchema = (
  ajvErrors,
  validateAllFieldCriteria,
) => {
  // Ajv will return empty instancePath when require error
  ajvErrors.forEach((error) => {
    if (error.keyword === 'required') {
      error.dataPath = '/' + error.params.missingProperty;
    }
  });

  return ajvErrors.reduce((previous, error) => {
    // `/deepObject/data` -> `deepObject.data`
    const path = error.dataPath.substring(1).replace(/\//g, '.');

    if (!previous[path]) {
      previous[path] = {
        message: error.message,
        type: error.keyword,
      };
    }

    if (validateAllFieldCriteria) {
      const types = previous[path].types;
      const messages = types && types[error.keyword];

      previous[path] = appendErrors(
        path,
        validateAllFieldCriteria,
        previous,
        error.keyword,
        messages
          ? ([]).concat(messages, error.message || '')
          : error.message,
      );
    }

    return previous;
  }, {});
};

const ajvResolver = (i18next, moment) => (schema, schemaOptions, resolverOptions = {}) =>
  async (values, _, options) => {

    const validate = ajv.compile(
      Object.assign({ $async: resolverOptions?.mode === 'async' }, schema),
    );
    const valid = validate(values);

    if (!valid) {
      translate(validate.errors, i18next, moment, schema)
      console.log(validate.errors)
      return {
        values: {},
        errors: toNestError(
          parseErrorSchema(
            validate.errors,
            !options.shouldUseNativeValidation &&
              options.criteriaMode === 'all',
          ),
          options,
        ),
      };
    }

    options.shouldUseNativeValidation && validateFieldsNatively({}, options);

    return {
      values,
      errors: {},
    };
  };

export const AjvValidatorContext = createContext();

const AjvValidatorContextProvider = ({ i18next, moment, children }) => {

  return (
    <AjvValidatorContext.Provider value={{
      createValidator: createCreateValidator(i18next, moment),
      ajvResolver: ajvResolver(i18next, moment),
    }}>
      {children}
    </AjvValidatorContext.Provider>
  )
}


export default AjvValidatorContextProvider