import { select, takeEvery, put, all, delay, fork, take, race } from 'redux-saga/effects'
import { diagnosticRoutine } from './routines'

import {postDocumentRoutine, putDocumentRoutine} from '../Documents/routines'

import { patientsIsLoadingSelector } from '../Patients/selectors'
import { getPatientRoutine } from '../Patients/routines'
import { makeStateDiagnosticSelector } from './selectors'
import { makeDiagnosticSelector } from '../Documents/selectors'

//const documentSelector = makeDocumentSelector()

const takeEveryDiagnosticSave = () => fork(function*() {
  while (true) {
    const action = yield take(diagnosticRoutine.SET)
    console.log('takeEveryDiagnosticSave', action)
    yield fork(saveDebounce, 3000, action.type, handleSave, action)
  }
})

function* saveDebounce (ms, pattern, task, action) {
  console.log('saveDebounce', action)
  yield put(diagnosticRoutine.autosaveStart())

  const { debounced } = yield race({
    debounced: delay(ms),
    latestAction: take((newAction) => newAction.type === pattern && newAction.payload.patientId === action.payload.patientId),
  })

  if (debounced) {
    yield fork(task, action)
  }
  else {
    //yield put(diagnosticRoutine.autosaveEnd())
  }

}

function* handleSave(action) {
  // debounce save by 5s
  const { patientId } = action.payload
  //yield put(diagnosticRoutine.autosaveStart())
  //yield delay(3000)
  
  //on mets à jour l'analyse ou on la crée
  const diagnosticState = yield select(stateDiagnosticSelector, { patientId: action.payload.patientId })
  
  const diagnosticDoc = yield select(diagnosticSelector, { patientId: action.payload.patientId })
  
  //const analyse = yield select(analyseSelector, { folderId: action.payload.folder, orientation: action.payload.orientation})

  //const parent_doc = yield select(documentSelector, { documentId: action.payload.folder })

  //const {points, paths, ruler, image_settings_xray, image_settings_photo, profil_transform } = analyseState

  if(diagnosticDoc?.id) {
    yield put(putDocumentRoutine.trigger({
      id: diagnosticDoc?.id,
      values: {
        data: {
          ...(diagnosticState?.diagnostic || {}),
          activeStepId: diagnosticState?.activeStepId,
          activeQuestionId: diagnosticState?.activeQuestionId,
        }
      }
    }))
  }
  else {
    yield put(postDocumentRoutine.trigger({
      type : `diagnostic`,
      data: {
        ...(diagnosticState?.diagnostic || {}),
        activeStepId: diagnosticState?.activeStepId,
        activeQuestionId: diagnosticState?.activeQuestionId,
      },
      //parent_doc: action.payload.folder,
      parent_patient: patientId,
    }))
  } 
    
  yield take([putDocumentRoutine.FULFILL, postDocumentRoutine.FULFILL])

  yield put(diagnosticRoutine.autosaveEnd())
}

const stateDiagnosticSelector = makeStateDiagnosticSelector()
const diagnosticSelector = makeDiagnosticSelector()

function* startDiagnostic(action) {
  //ici on load l'analyse si ce n'est pas fait on load une analyse par defaut
  
  const isLoading = yield select(patientsIsLoadingSelector)

  if(isLoading)
    yield take([getPatientRoutine.FULFILL])

  const diagnosticState = yield select(stateDiagnosticSelector, { patientId: action.payload.patientId })

  console.log('diagnostic dans le state', diagnosticState)
  //TODO load analyse state from saved analyse
  
  if(!diagnosticState) {
    //check if saved analyse
    const diagnostic = yield select(diagnosticSelector, { patientId: action.payload.patientId })
    

    //else load a fresh one
    yield put(diagnosticRoutine.load({
      patientId: action.payload.patientId,
      diagnostic: diagnostic?.data || {},
      activeStepId: diagnostic?.data?.activeStepId,
      activeQuestionId: diagnostic?.data?.activeQuestionId,
      loaded: true,
   }))

  }

}

function* watchStartDiagnostic() {
  yield takeEvery(diagnosticRoutine.INIT, startDiagnostic)
}

function* watchDiagnostic() {
  yield all([
    takeEveryDiagnosticSave(),
    watchStartDiagnostic(),
  ]);
}

export default watchDiagnostic;