import { Typography, Link, Box} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import CookieNotice from "./CookieNotice";

//import "./cookie-consent.css"

const useStyles = makeStyles((theme) => ({
  backgroundWrapperClasses: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: theme.zIndex.modal + 1
  },
  backgroundClasses: {
    backgroundColor: theme.palette.grey[200],
    borderColor: theme.palette.grey[400],
    padding: theme.spacing(2),
  },
  wrapperClasses: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    justifyContent: 'space-between'
  },
  buttonWrapperClasses: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  acceptButtonClasses: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(0),
      marginLeft: theme.spacing(2),
    },
  },
  declineButtonClasses: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(0),
      marginLeft: theme.spacing(2),
    },
  },
  personalizeValidationClasses: {
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      flexGrow: 0,
    }
  },
  personalizeValidationWrapperClasses: {
    display: 'flex',
    justifyContent: 'start'
  },
  cookieMapClasses: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  cookieListClasses: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
  }
}))

const CookieConsent = ({
  showCookieSettings,
}) => {

  const classes = useStyles()
  const {t} = useTranslation()
  return (
    <CookieNotice
      showCookieSettings={showCookieSettings}
      backgroundWrapperClasses={classes.backgroundWrapperClasses}
      backgroundClasses={classes.backgroundClasses}
      wrapperClasses={classes.wrapperClasses}
      buttonWrapperClasses={classes.buttonWrapperClasses}
      acceptButtonClasses={`
        MuiButton-textSecondary
        MuiButton-containedSecondary
        ${classes.acceptButtonClasses}
      `}
      declineButtonClasses={`
        MuiButton-outlined
        MuiButton-outlinedPrimary
        ${classes.declineButtonClasses}
      `}
      personalizeValidationWrapperClasses={classes.personalizeValidationWrapperClasses}
      personalizeButtonClasses={`
        MuiButton-outlined
        MuiButton-outlinedPrimary
      `}
      personalizeValidationClasses={`
        MuiButton-outlined
        MuiButton-outlinedPrimary
        ${classes.personalizeValidationClasses}
      `}
      cookieMapClasses={classes.cookieMapClasses}
      cookieListClasses={classes.cookieListClasses}
      cookies={[
        {
          name: 'necessary', 
          editable: false,
          default: true,
          title: t('cookieConsent.Essentiel'),
          text: t('cookieConsent.Les cookies essentiels sont nécessaires au fonctionnement du site'),
        },
        {
          name: 'gdpr-analytics',
          editable: true,
          default: true,
          title: t('cookieConsent.Analyses'),
          text: t('cookieConsent.Nous utilisons des cookies d\'analyse pour mesurer l\'audience du site'),
          cookies: [/^_ga/]
        },
        {
          name: 'gdpr-marketing',
          editable: true,
          default: true,
          title: t('cookieConsent.Marketing'),
          text: t('cookieConsent.Nous utilisons des cookies marketing pour vous montrer un contenu pertinent sur les produits, services ou sujets susceptibles de vous intéresser'),
          cookies: []
        }
      ]}
      acceptButtonText={t("cookieConsent.Accepter les cookies")}
      declineButtonText={t("cookieConsent.Tout refuser")}
      personalizeButtonText={t("cookieConsent.Personnaliser")}
      personalizeValidationText={t("cookieConsent.Enregistrer")}
    >
      <Trans t={t} i18nKey="cookieConsent.banner">
        <Typography variant="h5">La sécurité de vos données est notre priorité</Typography>
        <Box component={Typography} marginTop={2} marginBottom={2}>
          CephaloMax dépose des cookies et utilise des informations non sensibles pour assurer le bon fonctionnement du site, mesurer l'audience et pour vous montrer un contenu pertinent sur les produits, services ou sujets susceptibles de vous intéresser. Pour en savoir plus sur les cookies, les données utilisées et leur traitement, vous pouvez consulter notre <Link href="https://cephalomax.com/privacy-policy" target="_blank" color="secondary">politique de confidentialité</Link>. 
        </Box>
      </Trans>
    </CookieNotice>
  )
}

export default CookieConsent