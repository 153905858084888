import { createRoutineCreator } from 'redux-saga-routines';


const createImportsFilesRoutine = createRoutineCreator([
  'DROP_FILES', 
  'ADD_FILES', 
  'ADD_CANDIDATES',
  'ADD_CANDIDATE',
  'REMOVE_CANDIDATES',
  'SET_CANDIDATES', 
  'NO_SELECTION_CANDIDATES',
  'ALL_SELECTION_CANDIDATES', 
  'SELECTION_CANDIDATES', 
  'SHIFT_SELECTION_CANDIDATES', 
  'CTRL_SELECTION_CANDIDATES',
  'ADD_CHOSEN',
  'ADD_CHOSENS',
  'REMOVE_CHOSEN',
  'UPDATE_CHOSEN',
  'AUTO_SORT',
  'CROP_AND_UPLOAD',
  'ADD_UPLOAD',
  'PROGRESS_UPLOAD',
  'SUCCESS_UPLOAD',
  'ERROR_UPLOAD',
  'REMOVE_UPLOAD',
  'UPDATE_DOCUMENT',
  'REMOVE_UPDATE_DOCUMENT',
  'INCREASE_LOADING',
  'DECREASE_LOADING',
  'APPLY_IMAGE_SETTINGS',
])

export const importsFilesRoutine = createImportsFilesRoutine('IMPORTS_FILES')
