import { createRoutine, promisifyRoutine } from 'redux-saga-routines';

export const localParamsRoutine = createRoutine('PARAMS_LOCAL')

export const getParamRoutine = createRoutine('GET_PARAM'); 

export const postParamRoutine = createRoutine('POST_PARAM'); 
export const postParamRoutinePromiseCreator = promisifyRoutine(postParamRoutine);

export const putParamRoutine = createRoutine('PUT_PARAM');
export const putParamRoutinePromiseCreator = promisifyRoutine(putParamRoutine);

export const deleteParamRoutine = createRoutine('DELETE_PARAM');
export const deleteParamRoutinePromiseCreator = promisifyRoutine(deleteParamRoutine);

export const getParamsRoutine = createRoutine('GET_PARAMS');