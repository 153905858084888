import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  divider : {
    height: theme.spacing(6),
    width: 1,
    margin: theme.spacing(1),
    background: theme.palette.text.secondary
  }
}))
export default () => {
  const classes = useStyle()
  const {t} = useTranslation()

  return (
    <div className={classes.root}>
      <Typography variant="h4">404</Typography>
      <div className={classes.divider}/>
      <Typography variant="body1">{t('404.info')}</Typography>
    </div>
  )
}