import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';

import Logo from '../components/Logo'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
   // height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    flex: "1 1 100%"

  },
  logo: {
    width: '300px',
    maxWidth: '90%',
    height: 'auto',
    padding: '20px'
  }
}));


export default () => {
  
  const classes = useStyles()
  const { t } = useTranslation()
  return(
    <div className={classes.root}>
      <Logo className={classes.logo} animate></Logo>
      <Typography variant="subtitle1">
        {t('home.subtitle')}
      </Typography>
    </div> 
  )
}