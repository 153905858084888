import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'
import { diagnosticRoutine } from './routines'

const initialStates = {
  auto_save: {
    saving: false,
  },
  diagnostics: {

  }
}

export const autoSaveReducer = handleActions({
  [diagnosticRoutine.AUTOSAVE_START]: (state, action) => {
    return {
      saving: true
    }
  },
  [diagnosticRoutine.AUTOSAVE_END]: (state, action) => {
    return {
      saving: false,
    }
  },
},
initialStates.auto_save
)

export const diagnosticsReducer = handleActions({
  [diagnosticRoutine.LOAD]: (state, action) => {
    const { patientId, diagnostic, activeStepId, activeQuestionId, loaded} = action.payload
    return {
      ...state,
      [patientId]: {
        diagnostic,
        activeStepId,
        activeQuestionId,
        loaded,
      }
    }
  },
  [diagnosticRoutine.SET]: (state, action) => {
    const { patientId, stepId, questionId, value, activeQuestionId, activeStepId } = action.payload

    const newPatientState = {...(state[patientId] || { diagnostic : {}, activeStepId: undefined, activeQuestionId: undefined})}

    if(stepId && questionId)
      newPatientState.diagnostic = {
        ...newPatientState.diagnostic,
        [stepId] : {
          ...(newPatientState.diagnostic[stepId] || {}),
          [questionId]: value,
        }
      }

    if(activeQuestionId)
      newPatientState.activeQuestionId = activeQuestionId
    if(activeStepId)
      newPatientState.activeStepId = activeStepId

    return {
      ...state,
      [patientId]: newPatientState
    }
  },
},
initialStates.diagnostics
)

export const diagnosticReducer = combineReducers({
  auto_save: autoSaveReducer,
  diagnostics: diagnosticsReducer,
})