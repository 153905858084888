import { select, takeLatest, takeEvery, put, call, all, delay, fork, cancel } from 'redux-saga/effects'
import { putDocumentRoutine, postDocumentRoutine, getDocumentRoutine, getDocumentsRoutine, deleteDocumentRoutine, getFoldertitlesRoutine, documentCacheRoutine, getTemplatesRoutine, postTemplateRoutine, copyTemplateRoutine, googlePickerRoutine } from './routines'
import { makeFullCacheSelector } from './selectors'

import { documents, getToken } from '../../utils/api'

import { normalize } from 'normalizr';

import { doc, folder_title, templates } from '../schemas'

import _ from 'lodash'
import { i18n } from '../../index'

function* getDocumentSaga(action) {
  
  yield put(getDocumentRoutine.request())

  try {

    const token = yield select(getToken);

    const { data } = yield call(documents.get, token, action.payload.id);

    const normalizedData = yield call(normalize, data, doc); 

    yield put(getDocumentRoutine.success(normalizedData));

  }
  catch(error) {
    console.log(error)
    yield put(getDocumentRoutine.failure(error));
  } 

  yield put(getDocumentRoutine.fulfill());
}

let docIds = {};
let task;

function* fetchDocuments() {
  // combined with cancel(), this debounces the calls
  yield delay(50);
  const ids = Object.keys(docIds)
  if(ids.length === 1)
    yield fork(getDocumentSaga, {
      payload: { id: ids[0]}
    })
  else if(ids.length > 1){
    //sinon erreur 500 strapi car requete trop longue?
    yield all(_.chunk(ids, 20).map(chunck => fork(getDocumentsSaga, {
        payload: { ids: chunck }
      })));

    // yield fork(getDocumentsSaga, {
    //   payload: { ids }
    // })
  }
  docIds = {};
  task = null;
}

function* accumulateGetDocument({ payload }) {
  const { id } = payload;

  docIds[id] = true; // fast UNIQUE
  if (task) {
    yield cancel(task);
  }
  task = yield fork(fetchDocuments);
}

function* watchGetDocument() {
  yield takeEvery(getDocumentRoutine.TRIGGER, accumulateGetDocument)
}

function* postDocumentSaga(action) {
  console.log("postDocumentSaga", action)
  yield put(postDocumentRoutine.request())

  try {

    const token = yield select(getToken);

    const { data } = yield call(documents.post, token, action.payload);

    const normalizedData = yield call(normalize, data, doc);

    yield put(postDocumentRoutine.success(normalizedData));

  }
  catch(error) {
    console.log(error)
    yield put(postDocumentRoutine.failure(error));
  } 

  yield put(postDocumentRoutine.fulfill());
}

function* watchPostDocument() {
  yield takeEvery(postDocumentRoutine.TRIGGER, postDocumentSaga)
}

function* putDocumentSaga(action) {
  
  yield put(putDocumentRoutine.request())

  try {

    const token = yield select(getToken);

    const { data } = yield call(documents.put, token, action.payload.id, action.payload.values);

    const normalizedData = yield call(normalize, data, doc);

    yield put(putDocumentRoutine.success(normalizedData));

  }
  catch(error) {
    console.log(error)
    yield put(putDocumentRoutine.failure(error));
  } 

  yield put(putDocumentRoutine.fulfill());
}

function* watchPutDocument() {
  yield takeEvery(putDocumentRoutine.TRIGGER, putDocumentSaga)
}

function* deleteDocumentSaga(action) {
  
  yield put(deleteDocumentRoutine.request())

  try {

    const token = yield select(getToken);

    const { data } = yield call(documents.delete, token, action.payload.id);

    const normalizedData = yield call(normalize, data, doc);
    //a voir
    console.log('normalizedData delete', normalizedData)

    Object.keys(normalizedData.entities).forEach(key => {
      if(normalizedData.entities[key][normalizedData.result])
        normalizedData.entities[key][normalizedData.result]._id = null
    })

    yield put(deleteDocumentRoutine.success(normalizedData));

  }
  catch(error) {
    console.log(error)
    yield put(deleteDocumentRoutine.failure(error));
  } 

  yield put(deleteDocumentRoutine.fulfill());
}

function* watchDeleteDocument() {
  yield takeEvery(deleteDocumentRoutine.TRIGGER, deleteDocumentSaga)
}

function* getDocumentsSaga(action) {
  yield put(getDocumentsRoutine.request())

  try {

    const token = yield select(getToken);

    const { data } = yield call(documents.gets, token, action.payload.ids);

    const normalizedData = yield call(normalize, data, [doc]); 

    yield put(getDocumentsRoutine.success(normalizedData));

  }
  catch(error) {
    console.log(error)
    yield put(getDocumentsRoutine.failure(error));
  } 

  yield put(getDocumentsRoutine.fulfill());
}

function* watchGetDocuments() {
  yield takeEvery(getDocumentsRoutine.TRIGGER, getDocumentsSaga)
}

function* getFolderTitlesSaga(action) {
  yield put(getFoldertitlesRoutine.request())

  try {

    const token = yield select(getToken);

    const { data } = yield call(documents.getTitles, token);

    const normalizedData = yield call(normalize, data, [folder_title]);

    yield put(getFoldertitlesRoutine.success(normalizedData));

  }
  catch(error) {
    console.log(error)
    yield put(getFoldertitlesRoutine.failure(error));
  } 

  yield put(getFoldertitlesRoutine.fulfill());
}

function* watchGetFolderTitles() {
  yield takeLatest(getFoldertitlesRoutine.TRIGGER, getFolderTitlesSaga)
}

const loadImg = (url) => {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.referrerPolicy = "no-referrer";
    img.crossOrigin = "Anonymous";
    img.addEventListener('load', e => {
      img = null
      resolve(url)
    });
    img.addEventListener('error', e => {
      img = null
      console.log(e)
      reject(new Error(`Failed to load image's URL: ${url}`));
    });
    img.src = url;
  });
}

const fullCacheSelector = makeFullCacheSelector()

function* getCacheSaga(action) {
  const newUrl = action.payload.url
  const lastUpdateDate = action.payload.date

  for(let i = 0; i < 5; i++) {

    const fullCache = yield select(fullCacheSelector, action.payload.id)

    const {url: oldCachedUrl, date: cacheDate} = typeof fullCache === 'string' || !fullCache ? { url: fullCache, date: 0 } : fullCache;

    try {
      let cachedUrl;
      if(lastUpdateDate < cacheDate) {
        cachedUrl =  yield call (loadImg, oldCachedUrl || newUrl)
      }
      else{
        cachedUrl = yield call (loadImg, newUrl)
      }
      if(oldCachedUrl === cachedUrl)
        return;

      yield put(documentCacheRoutine.set({
        id: action.payload.id,
        url: cachedUrl
      }))

      return;
    }
    catch (e) {
      yield put(documentCacheRoutine.delete({
        id: action.payload.id
      }))
      yield put(getDocumentRoutine.trigger({id: action.payload.id.split('_')[0]}))
      if(i < 4) {
        yield delay(2^(i+1) * 100);
      }
    }
  }
}

function* watchGetCacheSaga() {
  yield takeEvery(documentCacheRoutine.GET, getCacheSaga)
}

function* getTemplatesSaga(action) {
  yield put(getTemplatesRoutine.request())

  try {

    const token = yield select(getToken);

    const { data } = yield call(documents.getTemplates, token);

    const normalizedData = yield call(normalize, data, [templates]); 

    yield put(getTemplatesRoutine.success(normalizedData));

  }
  catch(error) {
    console.log(error)
    yield put(getTemplatesRoutine.failure(error));
  } 

  yield put(getTemplatesRoutine.fulfill());
}

function* watchGetTemplates() {
  yield takeEvery(getTemplatesRoutine.TRIGGER, getTemplatesSaga)
}

function* postTemplateSaga(action) {
  yield put(postTemplateRoutine.request())

  try {

    const token = yield select(getToken);

    const { data } = yield call(documents.postTemplate, token, action.payload);

    const normalizedData = yield call(normalize, data, templates); 

    yield put(postTemplateRoutine.success(normalizedData));

  }
  catch(error) {
    console.log(error)
    yield put(postTemplateRoutine.failure(error));
  } 

  yield put(postTemplateRoutine.fulfill());
}

function* watchPostTemplate() {
  yield takeEvery(postTemplateRoutine.TRIGGER, postTemplateSaga)
}

function* copyTemplateSaga(action) {
  yield put(copyTemplateRoutine.request())

  try {

    const token = yield select(getToken);

    const { data } = yield call(documents.copyTemplate, token, {
      id: action.payload.id,
      name: action.payload.name,
      parentId: action.payload.parentId,
    });

    const normalizedData = yield call(normalize, data, templates); 

    yield put(copyTemplateRoutine.success(normalizedData));

  }
  catch(error) {
    console.log(error)
    yield put(copyTemplateRoutine.failure(error));
  } 

  yield put(copyTemplateRoutine.fulfill());
}

function* watchCopyTemplate() {
  yield takeEvery(copyTemplateRoutine.TRIGGER, copyTemplateSaga)
}

let google, gapi;

const loadScript = (url) => new Promise((resolve, reject) => {
  const script = document.createElement('script');
  script.src = url;
  script.async = true;
  script.defer = true;
  script.onload = resolve;
  script.onerror = reject;
  document.body.appendChild(script);
})

export const gapiLoadPromise = async () => {
  if(window.gapi)
    return window.gapi;

  await loadScript('https://apis.google.com/js/api.js')
  gapi =  window.gapi;
}

export const gisLoadPromise = async () => {
  if(window.google)
    return window.google;
  await loadScript('https://accounts.google.com/gsi/client')
  google =  window.google;
}

export const authorize = async (tokenClientOption) => {

  return await new Promise((resolve, reject) => {
    try {
      const tokenClient = google.accounts.oauth2.initTokenClient(tokenClientOption);
      
      tokenClient.callback = (resp) => {
        if (resp.error !== undefined) {
          reject(resp);
        }
        // GIS has automatically updated gapi.client with the newly issued access token.
        //console.log('gapi.client access token: ' + JSON.stringify(gapi.client.getToken()));
        resolve(resp.access_token);
      };
      tokenClient.requestAccessToken();

    } catch (err) {
      reject(err);
    }
  });

}

export const loadGooglePicker = () => new Promise(function(resolve, reject) {
  gapi.load('picker', {callback: resolve, onerror: reject});
});

const displayPicker = (appId, developerKey, oauthToken, locale = 'en') => {
  
  const locales = ['af', 'am', 'ar', 'bg', 'bn', 'ca', 'cs', 'da', 'de', 'el', 'en', 'en-GB', 'es', 'es-419', 'et', 'eu', 'fa', 'fi', 'fil', 'fr', 'fr-CA', 'gl', 'gu', 'hi', 'hr', 'hu', 'id', 'is', 'it', 'iw', 'ja', 'kn', 'ko', 'lt', 'lv', 'ml', 'mr', 'ms', 'nl', 'no', 'pl', 'pt-BR', 'pt-PT', 'ro', 'ru', 'sk', 'sl', 'sr', 'sv', 'sw', 'ta', 'te', 'th', 'tr', 'uk', 'ur', 'vi', 'zh-CN', 'zh-HK', 'zh-TW', 'zu']

  if(locales.includes(locale))
    locale = locale
  else if(locales.includes(locale.split('-')[0]))
    locale = locale.split('-')[0]
  else
    locale = 'en'

  return new Promise(function(resolve, reject) {
    const google = window.google
    const viewSlides = new google.picker.View(google.picker.ViewId.PRESENTATIONS);
    const viewDocs = new google.picker.View(google.picker.ViewId.DOCUMENTS);
    //view.setMimeTypes();  
    
    //const group = new google.picker.ViewGroup(viewSlides)
    //  .addView(viewDocs)

    const picker = new google.picker.PickerBuilder()
        //.enableFeature(google.picker.Feature.NAV_HIDDEN)
        //.enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
        .enableFeature(google.picker.Feature.SUPPORT_DRIVES)
        .hideTitleBar()
        .setOrigin(window.location.protocol + '//' + window.location.host)
        .setAppId(appId)
        .setLocale(locale)
        .setOAuthToken(oauthToken)
        .addView(viewSlides)
        .addView(viewDocs)
        //.addView(new google.picker.DocsUploadView())
        .setDeveloperKey(developerKey)
        .setCallback((result) => {
           console.log(result)
          if(result.action === "picked")
            resolve(result.docs[0])
          if(result.action === "cancel")
            reject()
          if(result.action === "picked" || result.action === "cancel")
            picker.dispose()
        })
        .build();
     picker.setVisible(true);
     // var elements= document.getElementsByClassName('picker-dialog');
     //  for(var i=0;i<elements.length;i++)
     //  {
     //      elements[i].style.zIndex = "1301";
     //  }
  });
}


function* presentGooglePicker() {
  
  yield put(googlePickerRoutine.request())

  try {
    //load gapi and gis scripts
    yield all([
      call(gapiLoadPromise),
      call(gisLoadPromise),
    ])
    //load picker from gapi
    yield call(loadGooglePicker)
    
    const access_token = yield call(authorize, {
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      prompt: 'select_account',
      scope: 'https://www.googleapis.com/auth/drive.file',
    })

    const result = yield call(displayPicker, process.env.REACT_APP_GOOGLE_PROJECT_ID, process.env.REACT_APP_GOOGLE_API_KEY, access_token, i18n.language)

    yield put(googlePickerRoutine.success(result))
    yield put(copyTemplateRoutine.trigger(result))
  }
  catch(error) {
    console.log(error)
    yield put(googlePickerRoutine.failure(error));
  } 
  

  yield put(googlePickerRoutine.fulfill())

}

function* watchGooglePicker() {
  yield takeEvery(googlePickerRoutine.TRIGGER, presentGooglePicker)
}

function* watchDocument() {
  yield all([
    watchGetDocument(),
    watchPostDocument(),
    watchPutDocument(),
    watchDeleteDocument(),
    watchGetDocuments(),
    watchGetFolderTitles(),
    watchGetCacheSaga(),
    watchGetTemplates(),
    watchPostTemplate(),
    watchCopyTemplate(),
    watchGooglePicker(),
  ]);
}

export default watchDocument;