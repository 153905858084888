import { createRoutine, createRoutineCreator, promisifyRoutine } from 'redux-saga-routines';

const createCephaloRoutine = createRoutineCreator([
  'START_ANALYSE',
  'LOAD_ANALYSE',
  'SET_POINT',
  'DELETE_POINT',
  //'SET_CURRENT_POINT',
  'SET_PATH',
  'DELETE_PATH',
  'SET_CURRENT_PATH',
  'SET_RULER',
  'SET_PROFIL_TRANSFORM',
  'SET_CURRENT_MESURE',
  'SET_IMAGE_SETTINGS',
  'AUTOSAVE_START',
  'AUTOSAVE_END',
  'RESET',
  'UNDO',
  'REDO',
])

export const cephaloRoutine = createCephaloRoutine('CEPHALO')

export const getPointsRoutine = createRoutine('GET_POINTS');
export const putPointsRoutine = createRoutine('PUT_POINTS');
export const putPointsRoutinePromiseCreator = promisifyRoutine(putPointsRoutine);
export const postPointsRoutine = createRoutine('POST_POINTS');
export const postPointsRoutinePromiseCreator = promisifyRoutine(postPointsRoutine);
export const deletePointsRoutine = createRoutine('DELETE_POINTS');
export const deletePointsRoutinePromiseCreator = promisifyRoutine(deletePointsRoutine);


export const getPlansRoutine = createRoutine('GET_PLANS');
export const putPlansRoutine = createRoutine('PUT_PLANS');
export const postPlansRoutine = createRoutine('POST_PLANS');
export const deletePlansRoutine = createRoutine('DELETE_PLANS');

export const getMesuresRoutine = createRoutine('GET_MESURES');
export const putMesuresRoutine = createRoutine('PUT_MESURES');
export const postMesuresRoutine = createRoutine('POST_MESURES');
export const deleteMesuresRoutine = createRoutine('DELETE_MESURES');

export const getAnalysesRoutine = createRoutine('GET_ANALYSES');
export const putAnalysesRoutine = createRoutine('PUT_ANALYSES');
export const postAnalysesRoutine = createRoutine('POST_ANALYSES');
export const deleteAnalysesRoutine = createRoutine('DELETE_ANALYSES');
