import React, { useState, useEffect, useCallback, useMemo, useRef} from 'react';
import ReactDOM from 'react-dom';
import { useSelector, useDispatch } from 'react-redux'

import CssBaseline from '@material-ui/core/CssBaseline';
import App from './App';
import Loading from './components/Loading'
import { withFullLayout } from './containers/FullLayout'
import * as serviceWorker from './serviceWorker';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { persistStore } from 'redux-persist'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import configureStore, { history } from './utils/configureStore'
import { ThemeProvider, StylesProvider, jssPreset } from '@material-ui/core/styles';
import rtl from 'jss-rtl';
import { create } from 'jss';
import * as themeLocale from '@material-ui/core/locale';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from "moment";

import AjvValidatorContextProvider from './utils/context/AjvContext'

import theme from './utils/theme'

import initializeI18n, { localeIsInitialized } from './i18n';

import { alertRoutine } from './redux/Alerts/routines'

import smoothscroll from 'smoothscroll-polyfill';

import link from './utils/link'

// replace console.* for disable log on production
if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

smoothscroll.polyfill();

export const store = configureStore(/* provide initial state if any */)

const persistor = persistStore(store, {})

export const i18n = initializeI18n(moment);

link(store, i18n)

const jssRTL = create({ plugins: [...jssPreset().plugins, rtl()] });

const rtlLanguages = ['ar', 'az', 'dv', 'he', 'ku', 'fa', 'ur'];

const RTL = ({isRTL, children}) => {

  useMemo(()=>{
    if(isRTL)
      document.body.setAttribute('dir', 'rtl');
    else
      document.body.setAttribute('dir', 'ltr');
    return () => {
      document.body.removeAttribute('dir');
    }
  }, [isRTL])

  return (
    isRTL ?
      <StylesProvider jss={jssRTL} >
        {children}
      </StylesProvider> :
      <React.Fragment>
        {children}
      </React.Fragment>
  );
}

const LocaleProvider = ({ children }) => {

  const [locale, setLocale] = useState(i18n.language);
  const intervalRef = useRef(null);;

  const dispatch = useDispatch()
  const { theme: themeParams = {
    nightMode: false
  }} = useSelector(state => state.params.local)
  
  //Ajouter un params automatic et dans ce cas on utilise useMediaQuery
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  
  const handleLaguageChange = useCallback(
    async locale => {

    try {
      await import(`moment/locale/${locale.toLowerCase()}`)
    }
    catch (e){
      try {
        await import(`moment/locale/${locale.toLowerCase().substring(0, 2)}`)
      }
      catch (e){
        locale = 'en'
      }
    }

    moment.locale(locale);
    setLocale(locale);

    i18n.emit('languageChangedAsync')
  },[])

  useMemo(() => {

    i18n.on('languageChanged', handleLaguageChange);

    return () => {
      // Nettoyage de l'abonnement
      i18n.off('languageChanged', handleLaguageChange);
    };
  }, [handleLaguageChange]);

  const isRTL = useMemo(
    () => 
      locale && i18n.dir() === 'rtl', //locale && rtlLanguages.indexOf(`${locale.toLowerCase().substring(0, 2)}`) !== -1;,
    [locale],
  );
  
  const nightMode = useMemo(() => {
    return themeParams.nightMode === undefined? prefersDarkMode : themeParams.nightMode;
  }, [prefersDarkMode, themeParams.nightMode])

  const localizedTheme = useMemo(
    () => {
      const splitedLocale = locale?.split('-') || [];
      if(splitedLocale.length === 2 && themeLocale[`${splitedLocale[0].toLowerCase()}${splitedLocale[1].toUpperCase()}`])
        return theme(themeLocale[`${splitedLocale[0].toLowerCase()}${splitedLocale[1].toUpperCase()}`], isRTL, nightMode)
      else if(splitedLocale.length === 1 && themeLocale[`${splitedLocale[0].toLowerCase()}${splitedLocale[0].toUpperCase()}`])
        return theme(themeLocale[`${splitedLocale[0].toLowerCase()}${splitedLocale[0].toUpperCase()}`], isRTL, nightMode)
      else
        return theme(null, isRTL, nightMode);
    },
    [nightMode, locale, isRTL],
  );

  // intervalRef.current = useMemo(
  //   () => {
  //     if(intervalRef.current)
  //       clearTimeout(intervalRef.current)
  //     if(prefersDarkMode !== themeParams.nightMode)
  //       return setTimeout(async () => {
  //         await localeIsInitialized(i18n)
  //         dispatch(alertRoutine.show({
  //           type: 'snack',
  //           title: `${prefersDarkMode ? i18n.t('common:interface.switch to night mode_question') : i18n.t('common:interface.switch off night mode_question')}`,
  //           action: localParamsRoutine.trigger({
  //             theme: {
  //               nightMode: prefersDarkMode
  //             }
  //           })
  //         }));
  //       }, 500)
  //   },
  //   [prefersDarkMode, themeParams.nightMode],
  // );

  if(!locale) 
    return (
      <ThemeProvider theme={localizedTheme}>
        <CssBaseline/>
        {withFullLayout(Loading)()}
      </ThemeProvider>
    )

  return (
    <RTL isRTL={isRTL}>
      <ThemeProvider theme={localizedTheme}>
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={locale}>
          <AjvValidatorContextProvider i18next={i18n} moment={moment}>
            {children}
          </AjvValidatorContextProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </RTL>
  )
}

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <LocaleProvider>
        <App/>
      </LocaleProvider>
    </ConnectedRouter>
  </Provider>, 
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();


const onSWUpdateAvailable = async (registration) => {
  await localeIsInitialized(i18n)
  store.dispatch(alertRoutine.show({
    type: 'snack',
    title: i18n.t('common:interface.A new version is available!'),
    persist: true,
    closeReasons: [],
    actionTitle: i18n.t('common:interface.reload'),
    action: () => {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      window.location.reload(true);
    }
  }))
}

serviceWorker.register({
  onUpdate: onSWUpdateAvailable
});

if(navigator.serviceWorker)
  navigator.serviceWorker.ready.then(registration => {
    if(registration.waiting && registration.waiting.state === 'installed')
      onSWUpdateAvailable(registration)
  })


