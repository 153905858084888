import React, { useMemo, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { makePatientAvatarPhotoSelector, makeCacheSelector } from '../redux/Documents/selectors'
import { documentCacheRoutine, getDocumentRoutine } from '../redux/Documents/routines'
import { useSelector, useDispatch } from 'react-redux'
import { objectKeysEqualityFn } from '../utils/equality';


export default React.memo(({className, patient = {}, avatarText }) => {

  if(!patient) {
    return <Avatar className={className} >{avatarText}</Avatar>
  }

  const dispatch = useDispatch()

  const memoizedProps = useMemo(() => ({patientId: patient.id}), [patient.id])
  const patientAvatarPhotoSelector = useMemo(makePatientAvatarPhotoSelector, [])
  const patientAvatarPhoto = useSelector(state => patientAvatarPhotoSelector(state, memoizedProps), objectKeysEqualityFn(['id', 'updatedAt', 'data.thumbnailLink'])) || {data: {}}

  const cacheSelector = useMemo(makeCacheSelector, [])
  let cachedUrl = useSelector(state => cacheSelector(state, `${patientAvatarPhoto.id}_avatar`))

  useEffect(() => {
    console.log('useEffect PatientAvatar', patientAvatarPhoto)
    if(patientAvatarPhoto && patientAvatarPhoto.data.thumbnailLink) {
      dispatch(documentCacheRoutine.get({
        id: `${patientAvatarPhoto.id}_avatar`,
        url: patientAvatarPhoto.data.thumbnailLink.replace('=s220', '=s100-e30'),
        date: new Date(patientAvatarPhoto.updatedAt).getTime(),
      }))
    }
    else if(patientAvatarPhoto.id && !patientAvatarPhoto.data?.error)
      dispatch(getDocumentRoutine.trigger({
        id: patientAvatarPhoto.id,
      }))
  }, [patientAvatarPhoto.id, patientAvatarPhoto.data.thumbnailLink])

  avatarText = avatarText || `${patient.firstname?.trim() ? patient.firstname.trim()[0].toUpperCase() : ''}${patient.lastname?.trim() ? patient.lastname.trim()[0].toUpperCase() : ''}` || null

  return cachedUrl? 
    <Avatar className={className} > 
      <img 
        className="MuiAvatar-img"
        src={cachedUrl}
        crossOrigin="Anonymous"
        referrerPolicy="no-referrer"
      />
    </Avatar> :
    <Avatar className={className} >{avatarText}</Avatar>
});