import { createSelector } from 'reselect'

export const patientsIsLoadingSelector = state => state.patients.patients.loading

export const patientsSelector = state => state.entities.patients
export const patientsResultSelector = state => state.patients.patients.result
export const patientsSearchCountSelector = state => state.patients.patients.searchCount

// export const patientsSelector = createSelector(
//   entitiesPatientsSelector,
//   (patients = {}) => _.filter(patients, (patient) => !!patient._id)
// )



export const searchAdvancedPatientsSelector = state => state.patients.searchAdvancedPatients

export const makeSearchAdvancedPatientsSelector = () => searchAdvancedPatientsSelector

export const searchAdvancedFiltersSelector = state => state.patients.searchAdvancedPatients.filters

export const makeSearchAdvancedFiltersSelector = () => searchAdvancedFiltersSelector

export const searchPatientsSelector = createSelector(
  patientsSelector,
  patientsResultSelector,
  (patients = {}, result = []) => {
    return result.map(id => patients[id])
    //return Object.values(_.filter(patients, (patient, key) => result.includes(key)))
  }
)

export const makeSearchPatientsSelector = () => searchPatientsSelector


export const patientSelector = createSelector(
  patientsSelector,
  (_, props) => props,
  (patients = {}, patientId) => {
    //console.log('patientSelector', patientId, patients[patientId])
    return patients[patientId]
  }
)

export const makePatientSelector = () => patientSelector

export const tabsSelector = state => state.patients.tabs
export const tabsHistorySelector = state => state.patients.tabsHistory

export const tagsIsLoadingSelector = state => state.patients.tags.loading
export const tagsIsErrorSelector = state => state.patients.tags.error

export const tagsSelector = createSelector(
  state => state.entities.tags,
  state => state.patients.tags.result,
  (tags = {}, result = []) => {
    return result.map(id => tags[id])
    //return Object.entries(tags).filter(([key,tag]) => result.includes(key)).map(([key,tag]) => tag)
    //return Object.values(_.filter(tags, (tag, key) => result.includes(key)))
  }
)

export const externalLinkLoading = state => state.patients.externalLink.loading
export const autoSaveStatutSelector = (state) => state.patients.auto_save.saving
