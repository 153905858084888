import { schema } from 'normalizr';

// Define a users schema
export const info =  new schema.Entity('infos');
export const subscription = new schema.Entity('subscriptions');
export const param =  new schema.Entity('params');
export const gift =  new schema.Entity('gifts', {}, {
  idAttribute: 'name'
});

export const user =  new schema.Entity('users', {
  infos: info,
  params: [param],
  stripe_subscription: subscription,
  gift: gift,
});


export const patient = new schema.Entity('patients', {
  owner: user,
});

export const contact = new schema.Entity('contacts', {
  owner: user,
  parent_patient: patient,
});

export const task = new schema.Entity('tasks', {
  owner: user,
  parent_patient: patient,
});

export const doc = new schema.Entity('documents', {
  owner: user,
  parent_patient: patient,
});

doc.define({ parent_doc: doc });
doc.define({ children: [doc] });

patient.define({ docs: [doc] });
patient.define({ contacts: [contact] });
patient.define({ tasks: [task] });

export const tag = new schema.Entity('tags', {}, {
  idAttribute: '_id'
})

export const folder_title = new schema.Entity('folder_titles',{}, {
  idAttribute: '_id'
})

export const docs = [doc]

export const templates = new schema.Entity('templates',{}, {
  idAttribute: 'id'
})

export const cephalo_point = new schema.Entity('cephalo_points', {
  owner: user,
});

cephalo_point.define({construction_point1 : cephalo_point, construction_point2 : cephalo_point})


