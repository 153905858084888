import React from 'react';

import { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state/hooks'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';

import SvgIcon from '@material-ui/core/SvgIcon';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';

import { ReactComponent as FrFlagIcon } from 'flag-icon-css/flags/4x3/fr.svg';
import { ReactComponent as EsFlagIcon } from 'flag-icon-css/flags/4x3/es.svg';
//import { ReactComponent as EnGBFlagIcon } from 'flag-icon-css/flags/4x3/gb.svg';
import { ReactComponent as USFlagIcon } from 'flag-icon-css/flags/4x3/us.svg';
//import { ReactComponent as MaFlagIcon } from 'flag-icon-css/flags/4x3/ma.svg';
//import { ReactComponent as AeFlagIcon } from 'flag-icon-css/flags/4x3/ae.svg';

import { localeRoutine } from '../i18n';
import { isLoggedInSelector } from '../redux/Auth/selectors';

export const flagsLoacale = {
  fr: {
    name: "Français",
    locale: "fr-fr",
    icon: <FrFlagIcon/>
  },
  en: {
    name: "English (US)",
    locale: "en-us",
    icon: <USFlagIcon/>
  },
  es: {
    name: "Español",
    locale: "es-es",
    icon: <EsFlagIcon/>
  },
  // ar: {
  //   name: "العربية الفصحى الحديثة",
  //   locale: "ar-ae",
  //   icon: <AeFlagIcon/>
  // },
}

export const flagsLoacaleRegion = {
  frfr: {
    name: "Français",
    locale: "fr-fr",
    icon: <FrFlagIcon/>
  },
  enus: {
    name: "English (US)",
    locale: "en-us",
    icon: <USFlagIcon/>
  },
  // engb: {
  //   name: "English (GB)",
  //   locale: "en-gb",
  //   icon: <EnGBFlagIcon/>
  // },
  eses: {
    name: "Español",
    locale: "es-es",
    icon: <EsFlagIcon/>
  },
  // arae: {
  //   name: "العربية الفصحى الحديثة",
  //   dir: 'ltr',
  //   locale: "ar-ae",
  //   icon: <AeFlagIcon/>
  // },
}

const LangMenuButton = React.memo(({ className }) => {

  const popupState = usePopupState({ variant: 'popover', popupId: 'langMenu' })
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const changeLanguage =  (locale) => {
    dispatch(localeRoutine.change({locale}))
    popupState.close()
  }
  console.log(i18n.language, i18n.language.replace('-', ''))
  return(
    <React.Fragment>
      <IconButton
        {...bindTrigger(popupState)}
        color="inherit"
        className={className}
      >
        <SvgIcon>
          {
            flagsLoacaleRegion[`${i18n.language.replace('-', '')}`] 
              && flagsLoacaleRegion[`${i18n.language.replace('-', '')}`].icon  || 
                flagsLoacale[`${i18n.language.split('-')[0]}`] 
                  && flagsLoacale[`${i18n.language.split('-')[0]}`].icon
          }
        </SvgIcon>
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        {...bindMenu(popupState)}
      >  
        {Object.keys(flagsLoacaleRegion).map((key, i) => (
          <MenuItem onClick={changeLanguage.bind(null, flagsLoacaleRegion[key].locale)} key={i}>
            <ListItemIcon>
              <SvgIcon>
                {flagsLoacaleRegion[key].icon}
              </SvgIcon>
            </ListItemIcon>
            <ListItemText primary={flagsLoacaleRegion[key].name} style={{direction: flagsLoacaleRegion[key].dir || 'ltr'}}/>
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  )
})

export default React.memo((props) => {
  
  const isLoggedIn = useSelector(isLoggedInSelector)

  return (
    <div>
      { isLoggedIn ? false : <LangMenuButton {...props}/>}
    </div>
  )
})